import { ErrorDescription } from 'types/handled_error';

export const labsHandledErrors: ErrorDescription[] = [
  {
    endpoint: 'labs/lab',
    method: 'patch',
    responseStatus: 409,
    error: 'lab_name_taken',
  },
  {
    endpoint: /labs\/pharmacies\/[\d]*\/_set_external_membership_id/,
    method: 'post',
    responseStatus: 409,
    error: 'code_already_taken',
  },
  {
    endpoint: 'labs/groups',
    method: 'get',
    responseStatus: 498,
    error: 'invalid_token',
  },
  {
    endpoint: 'labs/lab_trades',
    method: 'get',
    responseStatus: 498,
    error: 'invalid_token',
  },
  {
    endpoint: 'labs/undefined',
    method: 'get',
    responseStatus: 498,
    error: 'invalid_token',
  },
  {
    endpoint: 'labs/trades_instances',
    method: 'get',
    responseStatus: 498,
    error: 'invalid_token',
  },
  {
    endpoint: 'labs/trades_instances/statistics',
    method: 'get',
    responseStatus: 498,
    error: 'invalid_token',
  },
  {
    endpoint: 'labs/stripe/pay_instances',
    method: 'post',
    responseStatus: 500,
  },
  {
    endpoint: 'labs/stripe/pay_instances',
    method: 'post',
    responseStatus: 400,
    error: 'lab_payment_onboarding_incomplete',
  },
  {
    endpoint: 'labs/stripe/pay_instances',
    method: 'post',
    responseStatus: 400,
    error: 'no_trade_instance_to_pay',
  },
  {
    endpoint: 'labs/stripe/pay_instances',
    method: 'post',
    responseStatus: 400,
    error: 'cash_balance_lower_than_payment_amount',
  },
  {
    endpoint: 'shared/pharmacy_lists',
    method: 'delete',
    responseStatus: 400,
    error: 'pharmacy_list_used_in_trade',
  },
  {
    endpoint: 'labs/stripe/pay_instances',
    method: 'post',
    responseStatus: 403,
    error: 'access_denied_admin_cant_pay_instances',
  },
  {
    endpoint: 'secured/invoices',
    method: 'get',
    responseStatus: 403,
    error: 'access_token_incompatible_with_invoice',
  },
];
