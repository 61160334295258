import { t } from 'locales/i18n';
import { isEmailValid, isUrlValid } from 'utils/validations';
import * as Yup from 'yup';

export const urlValidator = (formFieldKey: string, isRequired?: boolean) =>
  Yup.string().test(
    formFieldKey,
    t('shared.invalidUrl'),
    (url) => (!isRequired || url !== undefined) && (!url || isUrlValid(url))
  );

export const emailValidator = (formFieldKey: string, isRequired: boolean = true) =>
  Yup.string().test(
    formFieldKey,
    t('shared.invalidEmail'),
    (email) => (!isRequired || email !== undefined) && (!email || isEmailValid(email))
  );

export const stringMaxLengthValidator = (formFieldKey: string, maxLength: number) =>
  Yup.string().test(
    formFieldKey,
    t('shared.invalidFieldMaxLength', { count: maxLength }),
    (value) => (value || '').length <= maxLength
  );

export const requiredString = (errorMessage = t('shared.requiredField')) =>
  Yup.string().trim().required(errorMessage);
