import numeral from 'numeral';

numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€',
  },
});

const fr = {
  reseller_pharmacy: {
    reseller: 'Pharmacie',
    resellers: 'Pharmacies',
    myReseller: 'Ma pharmacie',
    nameOfReseller: 'Nom de la pharmacie',
    addAResellersList: 'Ajouter une liste de pharmacies',
    resellersList: 'Liste de pharmacies',
    resellersLists: 'Listes de pharmacies',
    resellerClosed: 'Cette pharmacie a fermé',
    resellerOwner: 'Titulaire',
    findReseller: 'Trouver une pharmacie',
    findResellerList: 'Trouver une liste de pharmacies',
    noOwnerYet: 'Pas encore de titulaire',
    claimChat: {
      resellerInformation: 'Informations Pharmacie',
    },
    resellerRoleKind: {
      pharmacy_owner: 'Titulaire',
      pharmacist: 'Pharmacien',
      pharmacy_owner_unverified_email: 'À verifier par email',
      pharmacy_owner_unverified_phone: 'À rappeler',
      no_access: 'Non autorisé',
    },
    groupsLabs: {
      labEditPage: {
        firstSwitchTitle: 'Visible pour le pharmacien',
        firstSwitchInfoText:
          "- Les pharmaciens titulaires du groupement peuvent voir les détails du partenariat dans l'App, sur la page du laboratoire",
      },
    },
    labTradeInstances: {
      table: {
        filters: {
          selected_zero: 'Pharmacie',
          selected_one: '{{count}} pharmacie sélectionnée',
          selected_other: '{{count}} pharmacies sélectionnées',
        },
      },
      refuseModal: {
        subtitle: 'Indiquez au pharmacien une raison de refus de sa remontée',
      },
    },
    groupsPharmacies: {
      groupsPharmacyLists: {
        tableHead: {
          number: 'NOMBRE DE PHARMACIES',
        },
      },
    },
    newMasterTrade: {
      step3: {
        resellersNumber: 'Nombre de pharmacies',
        targeted: {
          number_one: '{{count}} pharmacie ciblée',
          number_other: '{{count}} pharmacies ciblées',
        },
      },
    },
    reseller_count: {
      number_zero: '{{count}} Pharmacie',
      number_one: '{{count}} Pharmacie',
      number_other: '{{count}} Pharmacies',
    },
    columnHeaders: {
      reseller: 'PHARMACIE',
      resellerList: 'LISTE DE PHARMACIES',
    },
    expired: {
      allow_reseller_to_create:
        'Autoriser la pharmacie à faire des remontées de périmés pendant 12h',
      warning_modal: {
        need_admin_pharmacist: {
          message:
            'Vous devez être pharmacien et administrateur de la pharmacie pour pouvoir signer le bon de destruction. (Votre numero RPPS vous sera demandé)',
        },
        need_pharmacy_email: {
          message:
            'Afin de recevoir et signer le certificat de destruction pour cette remontée de périmés, veuillez renseigner l’email de votre pharmacie.',
        },
      },
    },
    expiredLabSetting: {
      guidanceMessageInfo:
        'Ce message sera montré à la personne créant la remontée (délégué ou pharmacien) avant la création de sa remontée',
    },
    labGroups: {
      secondInfoText:
        'Chaque pharmacien a un seul groupement et voit uniquement le partenariat de son groupement.',
    },
    labRegions: {
      info: 'Les régions servent à définir des règles de routage régionalisées pour les nouvelles réclamations créées par les pharmacies.',
    },
    claims: {
      topics: {
        labTopicForm: {
          byPhoneInfo:
            'si cette option est activée, le pharmacien sera invité à appeler le numéro ci-dessous',
        },
        labTopicsPage: {
          info: "Les motifs de demande (ex: produit cassé, duplicata de facture, etc.) sont proposés au pharmacien lorsqu'il crée sa demande.\n\nSi plusieurs motifs sont visibles, le pharmacien sera invité à en sélectionner un. Sinon, il ne verra pas cette étape.",
        },
      },
    },
    trades: {
      allowResellerToParticipate: 'Rouvrir toutes les opérations pendant 72h pour cette pharmacie',
      allowResellerToParticipateToTradeModalTitle:
        "Rouvrir l'opération pour une pharmacie pendant 72h",
      noAccessToTrade: "Cette pharmacie n'a pas accès à cette opération",
      allowResellerToParticipateToTrade: 'Rouvrir cette opération pendant 72h pour cette pharmacie',
      labPublishConfirmInfo:
        'Pour information en publiant cette opération, elle deviendra visible par toutes les pharmacies ciblées.',
      visibleToReseller: 'Opération visible des pharmacies',
      destroyTradeConfirmMessage: {
        count_zero:
          "Êtes-vous sûr de vouloir supprimer cette opération ? Aucune pharmacie ne s'est encore inscrite.",
        count_one:
          "Êtes-vous sûr de vouloir supprimer cette opération ? {{count}} pharmacie s'est déjà inscrite.",
        count_other:
          'Êtes-vous sûr de vouloir supprimer cette opération ? {{count}} pharmacies se sont déjà inscrites.',
      },
      targetedResellers_plural: {
        count_zero: 'Aucune pharmacie ciblée',
        count_one: '{{count}} pharmacie ciblée',
        count_other: '{{count}} pharmacies ciblées',
      },
    },
    pharmaciesLists: {
      findList: 'Trouver une liste de pharmacies',
      newResellerList: 'Nouvelle liste de pharmacies',
      createListModal: {
        title: 'Créer une liste de pharmacies',
      },
      addBySearch: 'Ajouter des pharmacies par recherche',
      addFromGroup: "Ajouter les pharmacies d'un groupement",
      deleteFromGroup: "Supprimer les pharmacies d'un groupement",
      removeFromListAlert: {
        title: 'Supprimer une pharmacie',
        description: 'Êtes vous sûr(e) de vouloir supprimer cette pharmacie ?',
      },
      addModal: {
        title: 'Ajouter des pharmacies à la liste',
        noResults: 'Aucun résultat pour cette recherche',
      },
      editListMetaButton: 'Éditer le nom et la description',
      editListMetaModal: {
        title: 'Modifier la liste de pharmacies',
      },
      file: 'Fichier de pharmacies',
      alreadyInTargetingList: 'Cette pharmacie est déjà dans la liste de ciblage',
    },
    discussionMessages: {
      systemMessage: {
        closed_by_pharmacy: 'La requête a été fermée par le pharmacien',
        awaiting_signing: 'En attente de signature par le pharmacien',
        signed_by_pharmacy: 'Certificat signé par le pharmacien',
        claim_notify_lab_user_selected: 'Le pharmacien souhaite un suivi de la part du délégué',
      },
    },
    onboarding: {
      search: {
        title: 'Cherchez votre pharmacie',
        description: 'Nous allons chercher si votre pharmacie est référencée dans Faks.',
        landlinePhone: 'Téléphone fixe de votre pharmacie',
      },
      create: {
        title: 'Créez votre pharmacie',
        description:
          'Renseignez les informations de votre pharmacie afin d’être identifiable par les laboratoires.',
        landlinePhone: 'Téléphone fixe de votre pharmacie',
      },
      join: {
        title: 'Rejoignez la pharmacie',
        description:
          'Le titulaire de la pharmacie doit vous ajouter dans l’équipe de cette pharmacie pour finaliser votre inscription.',
        descriptionVerifyEmail:
          'Vous pourrez modifiez les informations de votre pharmacie lorsque vous aurez vérifié l’adresse email.',
        descriptionVerifyAccount:
          'Vous pourrez modifiez les informations de votre pharmacie lorsque l’équipe Faks aura vérifié vos informations.',
        askOwner:
          'Demandez au titulaire de la pharmacie de vous ajouter dans l’équipe de cette pharmacie dans l’application Faks.',
        join: 'Rejoindre la pharmacie',
        joinAndVerify: 'Rejoindre et vérifier par email',
        noEmailAccess: 'Je n’ai pas accès à cet email',
        verifyAccount: 'Vérifier votre compte',
        verifyYourAccount:
          'Afin de vérifier votre compte, vous pouvez choisir de recevoir un email de confirmation à l’adresse',
        ifNoAccessToMail:
          'Si vous n’avez pas accès à cet adresse mail, vous pouvez choisir de vérifier votre compte par téléphone.',
        verifyByEmail: 'Vérifier par email',
        verifyByPhone: 'Vérifier par téléphone',
        pharmacyCard: {
          phone: 'Tel. fixe',
          email: 'Email',
          owner: 'Titulaire',
        },
      },
    },
    stripe: {
      receivePaymentDescription:
        'Pour percevoir des paiements directement sur votre compte bancaire à la fin des opérations commerciales, un responsable de la pharmacie doit compléter les informations de paiement avec notre partenaire Stripe.',
      prepareDocuments:
        'Préparez vos documents d’identité et les informations légales de votre pharmacie.',
    },
  },
  reseller_point_of_sale: {
    reseller: 'Point de vente',
    resellers: 'Points de vente',
    myReseller: 'Mon point de vente',
    nameOfReseller: 'Nom du point de vente',
    addAResellersList: 'Ajouter une liste de points de vente',
    resellersList: 'Liste de points de vente',
    resellersLists: 'Listes de points de vente',
    resellerClosed: 'Ce point de vente a fermé',
    resellerOwner: 'Propriétaire',
    noOwnerYet: 'Pas encore de propriétaire',
    findReseller: 'Trouver un point de vente',
    findResellerList: 'Trouver une liste de points de vente',
    resellerRoleKind: {
      pharmacy_owner: 'Propriétaire',
      pharmacist: 'Membre',
      pharmacy_owner_unverified_email: 'À verifier par email',
      pharmacy_owner_unverified_phone: 'À rappeler',
      no_access: 'Non autorisé',
    },
    claimChat: {
      resellerInformation: 'Informations du point de vente',
    },
    groupsLabs: {
      labEditPage: {
        firstSwitchTitle: 'Visible pour le point de vente',
        firstSwitchInfoText:
          "- Les propriétaires de point de vente peuvent voir les détails du partenariat dans l'App, sur la page du laboratoire",
      },
    },
    labTradeInstances: {
      table: {
        filters: {
          selected_zero: 'Point de vente',
          selected_one: '{{count}} point de vente sélectionnée',
          selected_other: '{{count}} points de vente sélectionnées',
        },
      },
      refuseModal: {
        subtitle: 'Indiquez au point de vente une raison de refus de sa remontée',
      },
    },
    groupsPharmacies: {
      groupsPharmacyLists: {
        tableHead: {
          number: 'NOMBRE DE POINTS DE VENTE',
        },
      },
    },
    newMasterTrade: {
      step3: {
        resellersNumber: 'Nombre de points de vente',
        targeted: {
          number_one: '{{count}} point de vente ciblé',
          number_other: '{{count}} points de vente ciblés',
        },
      },
    },
    reseller_count: {
      number_zero: '{{count}} Points de vente',
      number_one: '{{count}} Point de vente',
      number_other: '{{count}} Points de vente',
    },
    columnHeaders: {
      reseller: 'POINT DE VENTE',
      resellerList: 'LISTE DE POINTS DE VENTE',
    },
    expired: {
      allow_reseller_to_create:
        'Autoriser le point de vente à faire des remontées de périmés pendant 12h',
      warning_modal: {
        need_admin_pharmacist: {
          message:
            'Vous devez être administrateur du point de vente pour pouvoir signer le bon de destruction.',
        },
        need_pharmacy_email: {
          message:
            'Afin de recevoir et signer le certificat de destruction pour cette remontée de périmés, veuillez renseigner l’email de votre point de vente.',
        },
      },
    },
    expiredLabSetting: {
      guidanceMessageInfo:
        'Ce message sera montré à la personne créant la remontée (délégué ou point de vente) avant la création de sa remontée',
    },
    labGroups: {
      secondInfoText:
        'Chaque point de vente a un seul groupement et voit uniquement le partenariat de son groupement.',
    },
    labRegions: {
      info: 'Les régions servent à définir des règles de routage régionalisées pour les nouvelles remontées de périmés créées par les points de vente.',
    },
    claims: {
      topics: {
        labTopicForm: {
          byPhoneInfo:
            'si cette option est activée, le point de vente sera invité à appeler le numéro ci-dessous',
        },
        labTopicsPage: {
          info: "Les motifs de demande (ex: produit cassé, duplicata de facture, etc.) sont proposés au point de vente lorsqu'il crée sa demande.\n\nSi plusieurs motifs sont visibles, le point de vente sera invité à en sélectionner un. Sinon, il ne verra pas cette étape.",
        },
      },
    },
    trades: {
      allowResellerToParticipate:
        'Rouvrir toutes les opérations pendant 72h pour ce point de vente',
      allowResellerToParticipateToTradeModalTitle:
        "Rouvrir l'opération pour un point de vente pendant 72h",
      noAccessToTrade: "Ce point de vente n'a pas accès à cette opération",
      allowResellerToParticipateToTrade:
        'Rouvrir cette opération pendant 72h pour ce point de vente',
      labPublishConfirmInfo:
        'Pour information en publiant cette opération, elle deviendra visible par tous les points de vente ciblés.',
      visibleToReseller: 'Opération visible des points de vente',
      destroyTradeConfirmMessage: {
        count_zero:
          "Êtes-vous sûr de vouloir supprimer cette opération ? Aucun point de vente ne s'est encore inscrit.",
        count_one:
          "Êtes-vous sûr de vouloir supprimer cette opération ? {{count}} point de vente s'est déjà inscrit.",
        count_other:
          'Êtes-vous sûr de vouloir supprimer cette opération ? {{count}} points de vente se sont déjà inscrits.',
      },
      targetedResellers_plural: {
        count_zero: 'Aucun point de vente ciblé',
        count_one: '{{count}} point de vente ciblé',
        count_other: '{{count}} points de vente ciblés',
      },
    },
    pharmaciesLists: {
      findList: 'Trouver une liste de points de vente',
      newResellerList: 'Nouvelle liste de points de vente',
      createListModal: {
        title: 'Créer une liste de points de vente',
      },
      addBySearch: 'Ajouter des points de vente par recherche',
      addFromGroup: "Ajouter les points de vente d'un groupement",
      deleteFromGroup: "Supprimer les points de vente d'un groupement",
      removeFromListAlert: {
        title: 'Supprimer un point de vente',
        description: 'Êtes vous sûr(e) de vouloir supprimer ce point de vente ?',
      },
      addModal: {
        title: 'Ajouter des points de vente à la liste',
        noResults: 'Aucun résultat pour cette recherche',
      },
      editListMetaButton: 'Éditer le nom et la description',
      editListMetaModal: {
        title: 'Modifier la liste de points de vente',
      },
      file: 'Fichier de points de vente',
      alreadyInTargetingList: 'Ce point de vente est déjà dans la liste de ciblage',
    },
    discussionMessages: {
      systemMessage: {
        closed_by_pharmacy: 'La requête a été fermée par le point de vente',
        awaiting_signing: 'En attente de signature par le point de vente',
        signed_by_pharmacy: 'Certificat signé par le point de vente',
        claim_notify_lab_user_selected: 'Le point de vente souhaite un suivi de la part du délégué',
      },
    },
    onboarding: {
      search: {
        title: 'Cherchez votre point de vente',
        description: 'Nous allons chercher si votre point de vente est référencé dans Faks.',
        landlinePhone: 'Téléphone fixe de votre point de vente',
      },
      create: {
        title: 'Créez votre point de vente',
        description:
          'Renseignez les informations de votre point de vente afin d’être identifiable par les laboratoires.',
        landlinePhone: 'Téléphone fixe de votre point de vente',
      },
      join: {
        title: 'Rejoignez le point de vente',
        description:
          'Le propriétaire du point de vente doit vous ajouter dans l’équipe de ce point de vente pour finaliser votre inscription.',
        descriptionVerifyEmail:
          'Vous pourrez modifiez les informations de votre point de vente lorsque vous aurez vérifié l’adresse email.',
        descriptionVerifyAccount:
          'Vous pourrez modifiez les informations de votre point de vente lorsque l’équipe Faks aura vérifié vos informations.',
        askOwner:
          'Demandez au propriétaire du point de vente de vous ajouter dans l’équipe de ce point de vente dans l’application Faks.',
        join: 'Rejoindre le point de vente',
        joinAndVerify: 'Rejoindre et vérifier par email',
        noEmailAccess: 'Je n’ai pas accès à cet email',
        verifyAccount: 'Vérifier votre compte',
        verifyYourAccount:
          'Afin de vérifier votre compte, vous pouvez choisir de recevoir un email de confirmation à l’adresse',
        ifNoAccessToMail:
          'Si vous n’avez pas accès à cet adresse mail, vous pouvez choisir de vérifier votre compte par téléphone.',
        verifyByEmail: 'Vérifier par email',
        verifyByPhone: 'Vérifier par téléphone',
        pharmacyCard: {
          phone: 'Tel. fixe',
          email: 'Email',
          owner: 'Propriétaire',
        },
      },
    },
    stripe: {
      receivePaymentDescription:
        'Pour percevoir des paiements directement sur votre compte bancaire à la fin des opérations commerciales, un responsable du point de vente doit compléter les informations de paiement avec notre partenaire Stripe.',
      prepareDocuments:
        'Préparez vos documents d’identité et les informations légales de votre point de vente.',
    },
  },
  date: {
    default: 'DD/MM/YYYY',
  },
  roleKind: {
    // See scoped roles for pharmacists in reseller
    // lab roles
    lab_admin: 'Administrateur du laboratoire',
    lab_sales_rep: 'Délégué',
    lab_sales_rep_no_access: 'Délégué sans accès',
    lab_regional_director: 'Directeur régional',
    //  Group roles
    group_admin: 'Administrateur du groupement',
    group_agent: 'Membre',
  },
  labUserPicker: {
    lab_sales_rep: {
      placeholder: {
        selected_zero: 'Délégué(s)',
        selected_one: '{{count}} délégué sélectionné',
        selected_other: '{{count}} délégués sélectionnés',
      },
    },
    lab_regional_director: {
      placeholder: {
        selected_zero: 'Directeur(s) régional(aux)',
        selected_one: '{{count}} directeur régional sélectionné',
        selected_other: '{{count}} directeurs régionaux sélectionnés',
      },
    },
  },
  shared: {
    logout: 'Se déconnecter',
    accept: 'Accepter',
    administrator: 'Administrateur',
    admin: 'Admin',
    actions: 'Actions',
    member: 'Membre',
    reader: 'Lecteur',
    connect: 'Se connecter',
    send: 'Envoyer',
    pharmacies: 'Pharmacies',
    register: 'Enregistrer',
    verify: 'Vérifier',
    validate: 'Valider',
    refuse: 'Refuser',
    save: 'Sauvegarder',
    modify: 'Modifier',
    pharmacy: 'Pharmacie',
    publish: 'Publier',
    warningWithEmoji: '⚠️ Attention',
    new: 'NEW',
    from: 'Du',
    until: 'au',
    invalidUrl: "Le format de l'url est incorrect",
    invalidEmail:
      "Le format de l'adresse e-mail est incorrect (pas d'espaces, pas de lettres majuscules, pas de caractères spéciaux).",
    invalidEmailinCC:
      "Le format de l'adresse e-mail {{email}} en copie est incorrect. Merci de ne rentrer que des adresses emails valides, séparées par des virgules.",
    invalidPassword: 'Le mot de passe doit contenir au moins 6 caractères',
    invalidPhoneNumber: 'Le format du numéro de téléphone est incorrect',
    tableNoData: 'Pas de données',
    tableRowsPerPage: 'Lignes par page :',
    pictureUploadFailed: 'Le chargement de la photo a échoué. Veuillez réessayer',
    errorFetchingData: 'Une erreur est survenue pendant le chargement des données',
    dashboard: 'Dashboard',
    resourceType: 'Type de ressource',
    lab: 'Laboratoire',
    labs: 'Laboratoires',
    group: 'Groupement',
    trades: 'Opérations',
    trade: 'Opération',
    requests: 'Demandes',
    request: 'Demande',
    groups: 'Groupements',
    invoices: 'Réclamations',
    invoice: 'Réclamation',
    guideItems: 'FAQ Labo',
    newsfeed: "Fil d'actualité",
    posts: 'Publications',
    newPost: 'Nouvelle publication',
    editPost: 'Modifier la publication',
    none: 'Aucun',
    none_female: 'Aucune',
    details: 'Détails',
    instructions: 'Consignes',
    edit: 'Editer',
    remove: 'Retirer',
    all: 'Toutes',
    selection: 'Sélection',
    add: 'Ajouter',
    applied: 'Appliqué',
    days: 'jours',
    hours: 'heures',
    download: 'Télécharger',
    ok: 'Ok',
    see: 'Voir',
    previous: 'Précédent',
    name: 'Nom',
    description: 'Description',
    text: 'Texte',
    changesDone: 'Vos modifications ont bien été prises en compte.',
    deleteSuccess: 'Opération effectuée avec succès.',
    typeMessage: 'Entrer votre message...',
    chatDisabled: 'Vous devez ajouter un délégué avant d’envoyer un message.',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    import: 'Importer',
    toFillIn: 'À renseigner',
    areYouSure: 'Êtes-vous sûr de vouloir continuer ?',
    partnership: 'Partenariat',
    delete: 'Supprimer',
    notification: 'Notification',
    registered: 'inscrits',
    toValidate: 'à valider',
    month: 'Mois',
    year: 'Année',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    labEmail: 'Email du laboratoire',
    newTrade: 'Nouvelle opération',
    removeTrade: `Supprimer l'opération`,
    modifyTrade: `Modifier l'opération`,
    viewConversation: 'Voir la conversation',
    responseTime: 'Temps de Réponse',
    averageSatisfaction: 'Satisfaction moyenne',
    resolutionTime: 'Temps de résolution',
    goToHomepage: "Revenir à l'accueil",
    exportFile: 'Obtenir le lien de téléchargement',
    unauthorized: 'Accès non autorisé',
    reopenTrade: "Rouvrir l'opération",
    settings: 'Paramètres',
    customerService: 'Service client',
    orderService: 'Service commande',
    sponsored: 'Sponsorisées',
    search: 'Rechercher',
    id: 'ID',
    optainLink: 'Obtenir le lien',
    copyLink: 'Copier le lien',
    linkCopied: 'Lien copié',
    file: 'Fichier',
    link: 'Lien',
    goBack: 'Retour',
    users: 'Utilisateurs',
    resources: 'Ressources',
    roleNoLongerExist: "Cet utilisateur a déjà été retiré de l'équipe",
    password: 'Mot de passe',
    deleteUser: 'Supprimer ce compte',
    invalidPasswordLength: 'Le mot de passe doit contenir au moins 6 caractères',
    wrongPassword: 'Mot de passe incorrect',
    logistician: 'Logisticien',
    wholesaler: 'Grossiste',
    other: 'Autre',
    back: 'Retour',
    phoneNumber: 'Numéro de téléphone',
    general: 'Général',
    logo: 'Logo',
    information: 'Informations',
    generalInformation: 'Informations générales',
    contact: 'Contact',
    contacts: 'Contacts',
    france: 'France',
    title: 'Titre',
    addDocument: 'Ajouter un document',
    regions: 'Régions',
    departments: 'Départements',
    user: 'Utilisateur',
    followUp: 'Suivi',
    expired: 'Périmés',
    expiredProductsRequests: 'Remontées de périmés',
    expiredLabSetting: 'Paramètres Remontées de périmés',
    activity: 'Activité',
    pilotage: 'Pilotage',
    rankings: 'Classements',
    expiredRequests: 'Remontées de périmés',
    expiredRequest: 'Remontée de périmés',
    the: 'Le',
    team: 'Équipe',
    writer: 'Membre',
    resourceOwner: 'Propriétaire',
    restore: 'Rouvrir',
    preValidate: 'Pré-valider',
    reassign: 'Réassigner',
    assign: 'Assigner',
    documents: 'Documents',
    status: 'Statut',
    mode: 'Mode',
    disconnection: 'Déconnexion',
    pharmacistSpace: 'Espace Pharmacien',
    partnerSpace: 'Espace Partenaire',
    cip: 'CIP',
    message: 'Message',
    note: 'Note interne',
    addNote: 'Ajouter une note interne',
    noteDescription: 'Cette note sera seulement visible par les membres de votre pharmacie',
    notePlaceholder: 'Ajoutez des note de rendez-vous, message internes ...',
    topics: 'Motifs',
    sendTo: 'Transmettre à',
    visible: 'Visible',
    keep: 'Garder',
    datamatrix: 'Générateur Datamatrix',
    selloutPanelistGenerator: 'Générateur remontées panélistes',
    faksValidationsGenerator: 'Générateur de validations Faks',
    prioritary: 'Prioritaires',
    priority: 'Priorité',
    internalId: 'ID interne',
    ingestionsHub: "Hub d'ingestions",
    lab_id: 'Id du laboratoire',
    selectFile: 'Choisir un fichier',
    close: 'Fermer',
    paymentAndInvoices: 'Paiement et facturation',
    of: 'de',
    group_id: 'Id du groupement',
    targetType: 'Type de cible',
    closedPharmacy: 'Cette pharmacie a fermé',
    draft: 'Brouillon',
    features: 'Fonctionnalités',
    selectedTrades: {
      selected_zero: 'Opération(s)',
      selected_one: '{{count}} opération sélectionnée',
      selected_other: '{{count}} opérations sélectionnées',
    },
    selectedStatus: {
      selected_zero: 'Status',
      selected_one: '{{count}} status sélectionné',
      selected_other: '{{count}} status sélectionnés',
    },
    selectedGroups: {
      selected_zero: 'Groupement(s)',
      selected_one: '{{count}} groupement sélectionné',
      selected_other: '{{count}} groupements sélectionnés',
    },
    upperUga: 'UGA',
    email: 'Email',
    billing: 'Facturation',
    payment: 'Paiement',
    find_lab: 'Trouver un laboratoire',
    no_pharmacy_list_matching: 'Aucune liste de pharmacies ne correspond',
    products: 'Produits',
    ean: 'EAN',
    vat: 'TVA',
    packing_quantity: 'Colisage',
    product_price: 'Prix',
    about: 'À propos',
    or: 'ou',
    firstName: 'Prénom',
    lastName: 'Nom',
    seeMore: 'Voir plus',
    seeLess: 'Voir moins',
    export: 'Exporter',
    orders: 'Commandes',
    order: 'Commande',
    ordersList: 'Liste des commandes',
    discussions: 'Discussions',
    productsList: 'Liste des produits',
    uga: 'UGA',
    verified: 'Vérifié(e)',
    address: 'Adresse',
    phone: 'Téléphone',
    undefined: 'Non définis',
    open: 'Ouvrir',
    yes: 'Oui',
    no: 'Non',
    reassignment: 'Réassignation',
    createdAt: 'Créé le',
    lastUpdatedAt: 'Dernière mise à jour le',
    topic: 'Motif',
    delegateRequested: 'Délégué sollicité',
    fixedPhone: 'Tel. fixe',
    mobilePhone: 'Tel. mobile',
    opinions: 'Avis',
    delegate: 'Délégué',
    updates: 'Mises à jour',
    system: 'Système',
    price: ' {{amount}}€',
    here: 'ici',
    skip: 'Passer',
    myGroup: 'Mon groupement',
    panelist: 'Panéliste',
    panelistConnection: 'Connexion extracteur',
    quantity: 'Quantité',
    total: 'Total',
    visibility: 'Visibilité',
    duplicate: 'Dupliquer',
    type: 'Type',
    generate: 'Générer',
    resign: 'Refaire signer',
    requiredField: 'Veuillez renseigner ce champ',
    impersonate: 'Impersonification',
    wrongCsvFormat: 'Erreur de format du fichier CSV',
    crmIntegration: 'Intégration CRM',
    claimRelaunchPlans: 'Plans de relance',
    labTrade: 'Opération laboratoire',
    next: 'Suivant',
    saveAndPublish: 'Enregistrer et publier',
    saveDraft: 'Enregistrer en tant que brouillon',
    targetingTypes: {
      pharmacy_owner: 'Pharmacie owner du trade',
      pharmacy_list: 'Liste de Pharmacies',
      group_all: 'Cibler tout le groupement',
      group_none: 'Retirer tout le groupement',
      master_pharmacy_list: 'Par défaut',
    },
    home: 'Accueil',
    source: 'Source',
    unauthorizedAdmin:
      'Vous ne pouvez pas effectuer cette action en tant qu’admin Faks impersonnifié.',
    errorTryAgainLater: 'Une erreur est survenue. Veuillez réessayer plus tard.',
    landline: 'Téléphone fixe',
    customerPromotion: 'Promotion consommateur',
    customerPromotionDetails: 'Conditions de la promotion consommateur',
    inboundMails: 'Mails entrants',
    claim: 'Réclamation',
    picture: 'Photo',
    process: 'Traiter',
    sentBy: 'Envoyé par',
    seeDiscussion: 'Voir la discussion',
    archive: 'Archiver',
    unarchive: 'Désarchiver',
    attachments: 'Pièces jointes',
    emailAddress: 'Adresse email',
    participations: 'Participations',
    participation: 'Participation',
    dates: 'Dates',
    files: 'Fichiers',
    kind: 'Type',
    reset: 'Reset',
    move: 'Déplacer',
    doNotUseInProduction: 'Ne pas utiliser en production',
    compensation: 'Compensation',
    invalidFieldMaxLength: 'Doit faire moins de {{count}} caractères',
    endsOn: 'Fin le ',
  },
  reliability: {
    reliabilityLevel: 'Niveau de Fiabilité',
    not_enough_data: 'Pas assez de données',
    not_reliable: 'Pas fiable',
    reliable: 'Fiable',
    automatic: 'Calcul automatique',
    overridden: 'Manuellement modifé',
  },
  components: {
    pharmacy: {
      updateSalesRepEmailModal: {
        title: 'Modifier le mail du délégué',
        description:
          "L'adresse email de ce délégué n'est pas valide. Merci de la renseigner ci dessous.",
        emailAlreadyTaken:
          "Un autre utilisateur existe déjà avec cette adresse email. Vous pouvez vous l'attribuer comme délégué sur la page du laboratoire.",
      },
    },
  },
  terms: {
    policy: 'Politique de confidentialité',
  },
  account: {
    logout: 'Se déconnecter',
    profile: 'Mon profil',
    switchTo: 'Espace',
    userForm: {
      firstName: 'Prénom',
      lastName: 'Nom',
      uploadPhoto: 'Choisir une photo',
      updatePhoto: 'Changer la photo',
      emptyValue: 'Cette valeur ne peut pas être vide',
      fileInvalidType: 'Le type de fichier est invalide, veuillez choisir une image PNG ou JPEG',
      fileTooLarge: 'Le fichier est trop grand, la taille maximum est de 3 MB',
    },
    pharmacyForm: {
      name: 'Nom de la pharmacie',
      addressLine: 'Adresse',
      addressCity: 'Ville',
      addressZipCode: 'Code postal',
      addressCountry: 'Pays',
      phoneNumber: 'Numéro de téléphone',
      email: 'Adresse email',
      mobileNumber: 'Téléphone mobile du titulaire',
      cip: 'CIP de la pharmacie',
      businessAddress: 'Adresse siège social',
      differentFiscalAddress: 'Adresse de facturation différente',
      fullFiscalAddress: 'Adresse de facturation complète',
      siret: 'SIRET',
      legalForm: 'Forme juridique',
      shareCapital: 'Capital social',
      rcs: 'Numéro RCS et ville d’immatriculation',
      tvaNumber: 'Numéro de TVA',
      resellerType: "Type d'établissement",
      businessName: 'Dénomination sociale',
      errors: {
        cipNumber: 'Doit être composé de chiffres',
        cipLength: 'Le CIP doit contenir 7 chiffres',
        minimumLength: 'Minimum de {{length}} caractères',
        postalCodeFormat: 'Le format du code postal est incorrect',
        phoneNumberTaken: 'Ce numéro de téléphone est déjà utilisé',
        wrongZipCodeFormat: 'Le format du code postal est incorrect',
        invalidZipCode: 'Veuillez indiquer un code postal valide',
        tvaNumber: 'Doit être composé de 2 lettres suivies de 11 chiffres',
        siret: 'Doit être composé de 14 chiffres',
        number: 'Doit être un nombre',
      },
      placeholder: {
        fullFiscalAddress: 'Adresse de facturation',
        businessAddress: 'Adresse du siège social',
        siret: '14 chiffres',
        legalForm: 'SAS, SARL...',
        shareCapital: 'En euros',
        rcs: 'Ex : 123 456 789 Paris',
        tvaNumber: 'Ex : FR01123456789',
      },
    },
    updateSuccess: 'Vos infos ont été mis à jour !',
  },
  login: {
    verify_phone_number: {
      verify_your_phone: 'Indiquez votre numéro de téléphone mobile',
      will_send_sms:
        'Nous allons vous envoyer un SMS (sans frais) pour vérifier votre numéro de téléphone.',
      form: {
        your_phone_number: 'Votre téléphone mobile',
        enter_your_phone_number: 'Saisissez votre numéro de téléphone mobile',
        phone_number_format_error: 'Veuillez respecter le format du pays sélectionné',
      },
      partner_access: 'Accès espace Partenaires',
      login_with_provider_error: 'Vous devez vous connecter avec votre email et mot de passe',
    },
    verify_code: {
      enter_activation_code: "Entrez le code d'activation",
      enter_sent_code:
        "Veuillez entrer le code d'activation que nous avons envoyé par SMS au {{phoneNumber}}",
      form: {
        code_not_valid_error: "Le code n'est pas valide",
      },
      noCodeReceived: "Vous n'avez pas reçu de code ?",
      resendCode: 'Renvoyer le code',
      resendCodeCountdown: 'Renvoyer le code dans {{timeLeft}} secondes',
      notYou: "Ce n'est pas vous ?",
      goBack: 'Revenir en arrière',
    },
    verify_email: {
      enter_credentials: 'Entrez votre adresse email et votre mot de passe',
      form: {
        your_email: 'Votre adresse email',
        enter_your_email: 'Entrez votre adresse email',
        your_password: 'Votre mot de passe',
        enter_your_password: 'Entrez votre mot de passe',
        enter_password_for: 'Entrez le mot de passe pour {{email}}',
        modify_email: "Modifier l'email",
      },
      pharmacist_login: 'Connexion Espace Pharmacien',
      incorrect_credentials: 'Vos identifiants sont incorrects',
    },
    userDeactivated: 'Utilisateur désactivé',
    youArePharmacist: 'Vous êtes pharmacien ?',
    errors: {
      identity_provider_response_invalid:
        'Une erreur est survenue lors de votre connexion, en cas de besoin, vous pouvez contacter le service client Faks',
    },
  },
  autologin: {
    successfullyConnectedAs: 'Connecté en tant que <strong>{{userName}}</strong>',
  },
  resetPassword: {
    forgotPassword: 'Mot de passe oublié ?',
    enterEmail: 'Entrez votre e-mail, vous recevrez un lien pour redéfinir votre mot de passe.',
    email: 'Adresse e-mail',
    send: 'Envoyer',
    passwordFound: 'Mot de passe retrouvé ?',
    enterNewPassword: 'Entrez votre nouveau mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmez votre nouveau mot de passe',
    minimumLength: '(6 characters minimum)',
    invalidPasswordConfirmation:
      'Le mot de passe doit contenir au moins 6 caractères ou les mots de passe ne sont pas les mêmes.',
    password_link_sent:
      "Un lien pour réinitialiser votre mot de passe a été envoyé par e-mail à l'adresse suivante {{email}}.",
    invalidToken: "Le lien de réinitialisation de mot de passe n'est pas valide.",
    idp_bound_domain:
      "Impossible de modifier le mot de passe pour cette adresse email, veuillez vous adresser à votre fournisseur d'identité",
  },
  navbar: {
    header: 'Général',
    admin: 'Admin',
    features: 'Fonctionnalités',
    dashboard: 'Dashboard',
    trades: 'Opérations',
    labs: 'Laboratoires',
    labTradeInstances: 'Validation',
    claims: 'Réclamations',
    pharmaciesMerge: 'Fusion de doublons',
    orders: 'Commandes',
    discussions: 'Discussions',
    labTrades: 'Opérations Labo',
    marketingTools: 'Outil Marketing',
    newsfeedPosts: 'Fil d’actualité groups',
    tools: 'Outils',
    groupsLabs: 'Partenariats Groupements / Laboratoires',
    checkNeedingPharmacies: 'Pharmacies à checker',
    referentialPharmacies: 'Pharmacies référentiel',
    compensations: 'Compensations',
    compensationsTooltip:
      'Le module de suivi des compensations est disponible uniquement aux utilisateurs ayant un rôle de titulaire sur Faks. Veuillez contacter votre titulaire pour changer de rôle.',
  },
  pharmacyGroup: {
    noGroupInFaks:
      "Vous ne faites pas partie d'un groupement dans Faks.<br/>Pour rejoindre votre groupement, demandez à votre interlocuteur groupement de vous ajouter à son groupement.",
    yourPharmacyIsAttached: 'Votre pharmacie est rattachée au groupement ci-dessous :',
    changeGroupInstruction:
      'Pour changer votre groupement sur Faks, vous pouvez quitter ce groupement en cliquant sur "Quitter le groupement" ci-dessous. Demandez ensuite à votre interlocuteur groupement de vous ajouter à son groupement.',
    areYouSure:
      'Êtes-vous sûr de vouloir quitter le groupement {{groupName}} ? Cette action est irréversible. Pour rejoindre un autre groupement, vous devrez être invité par un administrateur de ce groupement.',
    leaveGroup: 'Quitter le groupement',
  },
  columnHeaders: {
    id: '#',
    trade: 'OPÉRATION',
    status: 'STATUT',
    action: 'ACTION',
    lab: 'LABORATOIRE',
    pharmacist: 'PHARMACIEN',
    topic: 'MOTIF',
    name: 'NOM',
    date: 'DATE',
    dates: 'DATES',
    createdAtM: 'CRÉÉ LE',
    createdAtF: 'CRÉÉE LE',
    updatedAt: 'MIS À JOUR LE',
    files: 'JUSTIFICATIFS',
    telephone: 'TEL.',
    cip: 'CIP',
    verified: 'VÉRIFIÉE',
    delegate: 'DÉLÉGUÉ',
    claims: 'DEMANDES',
    group: 'GROUPEMENT',
    details: 'DÉTAILS',
    visible: 'VISIBLE',
    modifiedAt: 'MODIFIÉ LE',
    email: 'EMAIL',
    firstName: 'PRÉNOM',
    role: 'RÔLE',
    reason: 'MOTIF',
    responsible: 'RESPONSABLE',
    lastMessage: 'DERNIER MESSAGE',
    title: 'TITRE',
    departments: 'DÉPARTEMENTS',
    destination: 'DESTINATION',
    createdBy: 'CRÉÉE PAR',
    content: 'CONTENU',
    text: 'TEXTE',
    view: 'VUE',
    like: "J'AIME",
    click: 'CLIC',
    actions: 'ACTIONS',
    address: 'ADRESSE',
    phone: 'TÉLÉPHONE',
    user: 'UTILISATEUR',
    comments: 'COMMENTAIRES',
    automaticResponse: 'RÉPONSE AUTOMATIQUE',
    file: 'FICHIER',
    rating: 'NOTATION CLIENT',
    openInvoice: 'FAIRE UNE RÉCLAMATION',
    addPartnerLab: 'AJOUTER EN LABORATOIRE PARTENAIRE',
    kind: 'KIND',
    owner: 'OWNER',
    labTrade: 'LAB TRADE',
    tradeInstances: 'PARTICIPATIONS',
    startDate: 'DÉBUT LE',
    sellOutDemand: 'SELL OUT DEMANDÉ',
    tradeInstanceStatus: 'STATUT OPÉRATION',
    trades: 'TRADES',
    amount: 'MONTANT',
    type: 'TYPE',
    from: 'DE',
    to: 'À',
    subject: 'OBJECT',
    message: 'MESSAGE',
    attachment: 'PIÈCE JOINTE',
    delete: 'SUPPRIMER',
    userId: 'ID UTILISATEUR',
    pharmacy: 'PHARMACIE',
    pharmacies: 'PHARMACIES',
    mainUser: 'PROPRIÉTAIRE',
    customerService: 'SERVICE CLIENT',
    requirements: 'PREUVES',
    compensation: 'COMPENSATION',
    registrations: 'INSCRIPTIONS',
    ean: 'EAN',
    quantity: 'QUANTITÉ',
    turnoverTaxInCents: "CA TTC EN CENTIMES D'€",
    url: 'URL',
    mode: 'MODE',
    numberOfUsers: '# USERS',
    numberOfFailedUsers: '# USERS (AVEC ERREURS)',
    lastVisit: 'DERNIÈRE VISITE',
    views: 'VUES',
    likes: "J'AIMES",
    linkClicks: 'CLICS',
    comment: 'COMMENTAIRE',
    numberOfMails: '# MAX E-MAILS',
    pharmacyClaimRelaunchPlans: 'RELANCE MESSAGE PHARMA (J+)',
    labClaimRelaunchPlans: 'RELANCE MESSAGE LAB (J+)',
    resourceId: 'ID RESSOURCE',
    resource: 'RESSOURCE',
    pharmacistName: 'TITULAIRE',
    externalMembershipId: 'ID INTERNE',
    source: 'SOURCE',
    zipCode: 'ZIP CODE',
    city: 'CITY',
    uga: 'UGA',
    pharmacyDetails: 'FAKS PHARM DETAILS',
    cipMatches: 'REF DATA MATCHS',
    pharmacyId: 'ID PHARMACIE',
    roleId: 'ID (Role)',
    instanceId: 'ID PARTICIPATION',
  },
  admin: {
    home: {
      title: 'Console d’admin. - Faks',
      tools: 'Outils',
      features: 'Fonctionnalités',
      entities: 'Entités',
    },
    noAccessToImpersonification: "L'impersonnification n'est pas disponible avec ce role",
    impersonate: 'Impersonnifier',
    impersonateUser: 'Impersonifier un utilisateur',
    isImpersonating: '<strong>{{trueUser}}</strong> impersonifie <strong>{{currentUser}}</strong>',
    stopImpersonating: 'Arrêter',
    datamatrix: {
      generator: 'Générateur de datamatrix',
      createDatamatrix: 'Générer un code Datamatrix',
      cipProduct: 'CIP du produit',
      expiry: 'Expiration',
      expired: 'Expiré',
      notExpired: 'Pas expiré',
      lotNumber: 'Numéro du lot',
      serialNumber: 'Numéro de série',
      random: 'Aléatoire',
    },
    ingestions: {
      modes: {
        update: 'Mettre à jour',
        replace: 'Réinitialiser',
      },
      changesType: 'Type de changement',
      ingestionTypes: {
        replaceExpiredProducts: 'Remplacer les produits périmés',
      },
      newIngestion: 'Nouvelle ingestion',
      ingestion: 'Ingestion',
      title: 'Hub d’ingestions de données',
      subtitle: 'Utilisez cette page pour lancer vos différentes ingestions de données dans Faks',
      ingestionType: 'Type d’ingestion',
      columnHeaders: {
        id: 'ID',
        type: 'TYPE D’INGESTION',
        options: 'OPTIONS',
        createdBy: 'CRÉÉE PAR',
      },
      instructionsAlert: {
        effect: "Effet de l'ingestion",
        requiredFormat: 'Format requis',
        commonDescription:
          "Le fichier doit être au format csv, avec une ligne d'en-tête. Les colonnes doivent être séparées par des points virgules.\nLes colonnes requises sont les suivantes:",
        fileExample: 'Exemple de fichier',
      },
      ingestionStatus: {
        created: 'Créée',
        running: 'En cours',
        completed: 'Terminée',
        failed: 'Échouée',
      },
      tasks: {
        createSalesRepPharmacy: {
          title: 'Assignation des délégués par pharmacie',
          formatDescription:
            '- Prénom du délégué: texte\n- Nom du délégué: texte\n- Numéro de téléphone du délégué: nombre\n- Email du délégué: texte\n',
          effect:
            'Les comptes des utilisateurs présents dans le fichier seront créés et rattachés au laboratoire. La sectorisation des pharmacies sera mise à jour selon les information présentes dans le fichier.',
          fileExample:
            'sales_rep_first_name;sales_rep_last_name;sales_rep_phone_number;sales_rep_email\nJohn;Lee;+33123456789;john.doe@example.com;Smith;0000000000;jane.smith@example.com\nBob;Johnson;+33666666666;bob.johnson@example.com\nSara;Lee;+33777777777;sara.lee@example.com\nTom;Smith;0123456789;tom.smith@example.com',
        },
        createRegionalDirectorPharmacy: {
          title: 'Assignation des directeurs régionaux par Pharmacie',
          formatDescription:
            '- Prénom du directeur régional: texte\n- Nom du directeur régional: texte\n- Numéro de téléphone du directeur régional: nombre\n- Email du directeur régional: texte\n',
          effect:
            'Les comptes des utilisateurs présents dans le fichier seront créés et rattachés au laboratoire. La sectorisation des pharmacies sera mise à jour selon les information présentes dans le fichier.',
          fileExample:
            'regional_director_first_name;regional_director_last_name;regional_director_phone_number;regional_director_email\nJohn;Lee;+33123456789;john.doe@example.com;Smith;0000000000;jane.smith@example.com\nBob;Johnson;+33666666666;bob.johnson@example.com\nSara;Lee;+33777777777;sara.lee@example.com\nTom;Smith;0123456789;tom.smith@example.com',
        },
        replaceExpiredProducts: {
          title: 'Remplacement des produits périmés repris',
          formatDescription:
            "- ean13: Code EAN13: 13 chiffres, obligatoire\n- name: Nom du produit. Texte. 100 caractères max, obligatoire\n- price: Prix unitaire en euros (avec un point pour les centimes), obligatoire\n- abatement: Pourcentage d'abatement: entier entre 0 et 100, sans le signe %, obligatoire\n- datamatrix: Présence d'un datamatrix: true/false, obligatoire\n- is_lot_number_required: Numéro de lot: true/false, obligatoire\n- category: Nom de la catégorie du produit. Texte. 40 caractères max",
          effect:
            'Tous les produits présents dans le fichier, identifiés par leurs codes EAN, seront insérés ou mis à jour dans la liste des produits repris par le laboratoire. Les produits présents dans la base de données Faks mais pas dans le fichier seront désactivés.',
          fileExample:
            'ean13;name;price;abatement;datamatrix;is_lot_number_required;category\n3400000000001;product 1;3.40;50;true;true;G000051\n3400000000002;product 2;10;90;true;true;G000052\n3400000000003;product 3;1;0;false;true;G000053\n3400000000004;product 4;5;100;true;false;G000054',
        },
        createTrades: {
          title: "Création d'opérations",
          formatDescription:
            "- Titre de l'opération. Texte, 70 caractères max, obligatoire\n- Description de l'opération. Texte, obligatoire\n- Date de début: date au format DD/MM/YYYY, obligatoire\n- Date de fin: date au format DD/MM/YYYY, obligatoire\n- Id de la liste de pharmacies du groupement: nombre\n- Type d'opération: group_owned/platform/direct/prevalidate, obligatoire\n- Nom du laboratoire: texte, doit correspondre au nom exact présent en base de données, obligatoire\n- Email du laboratoire: texte au format email, obligatoire si le type est 'direct'\n- Instructions pharmacie: texte, obligatoire si le type est 'platform'\n- Preuve photo mise en avant requise: true/false, obligatoire\n- Nom preuve photo mise en avant: texte, 100 caractères max, obligatoire si Preuve photo mise en avant requise est 'true'\n- Description preuve photo mise en avant: texte\n- Date de début remontée de preuve photo mise en avant: date au format DD/MM/YYYY\n- Date de fin remontée de preuve photo mise en avant: date au format DD/MM/YYYY\n- Preuve sellout obligatoire: true/false, obligatoire\n- Nom preuve sellout: texte, 100 caractères max, obligatoire si Preuve sellout est 'true'\n- Description preuve sellout: texte\n- Sellout manuel demandé aux pharmaciens: true/false (true par défaut)\n- Date de début remontée de preuve sellout: date au format DD/MM/YYYY\n- Date de fin remontée de preuve sellout: date au format DD/MM/YYYY\n- Prioritaire: true/false, obligatoire\n- Catégories: texte, chaque catégorie séparée par un virgule\n- Eans des produits: 13 chiffres, chaque valeur séparée par le signe '|'\n- Noms des produits: texte, chaque valeur séparée par le signe '|'\n- TVAs des produits (en pourcent): nombre, parmi : 20.0, 10.0, 5.5, 2.1, 0.0, chaque valeur séparée par le signe '|'\n- Promotion consommateur: texte",
          effect:
            'Les opérations présentes dans le fichier seront créées pour le groupement choisi.',
          fileExample:
            'title;description;start_date;end_date;pharmacy_list_id;kind;lab_name;lab_email;platform_trade_message_guidance;is_picture_required;picture_name;picture_description;picture_start_date;picture_end_date;is_sellout_required;sellout_name;sellout_description;sellout_start_date;sellout_end_date;has_group_priority;tag_names;product_eans;product_names;product_vats;customer_promotion;customer_promotion_details\nTrade title 1;Trade description 1;01/03/2023;31/03/2023;;direct;Test lab;valid@email.com;;true;Name TG;;;;false;;;;;;;;;;;\nTrade title 1;Trade description 1;01/03/2023;31/03/2023;;prevalidate;Test lab;valid@email.com;;true;Name TG;;;;false;;;;;;;;;;;\nTrade title 2;Trade description 2;01/03/2023;31/03/2023;;group_owned;Test lab;;;true;Name TG;;;;false;;;;;;;;;;;\nTrade title 3;Trade description 3;01/03/2023;31/03/2023;;platform;Test lab;;message;false;;;;;false;;;;;;tag1,tag2;;;;;\nTrade title 4;Trade description 4;01/03/2023;31/03/2023;;group_owned;Test lab;;;false;;;;;true;Name sellout;;;;;;;;;;\nTrade title 5;Trade description 5;01/03/2023;31/03/2023;;group_owned;Test lab;;;false;;;;;true;Name sellout;;;;;;;;;;\nTrade title 6;Trade description 6;01/03/2023;31/03/2023;;group_owned;Test lab;;;false;;;;;true;Name sellout;;;;;;1234567656743 | 1234567656741;Name 1 | Name 2;20 | 5.5;;\n',
        },
        createPharmaciesLists: {
          fileExample: '',
          title: 'Création de listes de pharmacies',
          effect: '',
          formatDescription: '',
        },
        updateExternalMembershipIds: {
          title: 'Mise à jour des identifiants clients',
          effect:
            'Les identifiants clients seront mis à jour sur les pharmacies présentes dans le fichier',
          formatDescription: '',
          fileExample: '',
        },
        createSalesRepUga: {
          title: 'Assignation des délégués par UGA',
          formatDescription:
            "- Prénom du délégué: texte\n- Nom du délégué: texte\n- Numéro de téléphone du délégué: nombre (peut commencer par '+33')\n- Email du délégué: texte\n- UGA: texte\n",
          effect:
            'Les comptes des utilisateurs présents dans le fichier seront créés et rattachés au laboratoire. La sectorisation des pharmacies sera mise à jour selon les information présentes dans le fichier.',
          fileExample:
            'first_name;last_name;phone_number;email;uga\nJohn;Lee;+33123456789;john.doe@example.com;NCA00Jane;Smith;0000000000;jane.smith@example.com;95PON\nBob;Johnson;+33666666666;bob.johnson@example.com;GUA00Sara;Lee;+33777777777;sara.lee@example.com;MEP00\nTom;Smith;0123456789;tom.smith@example.com;MAR00Tom;Smith;0123456789;tom.smith@example.com;GUY00\nMike;Brown;0123456789;mike.brown@example.com;REU01\nKaren;Jones;+33696969696;karen.jones@example.com;REU02\nSteve;Davis;0123456789;steve.davis@example.com;REU00\n',
        },
        createRegionalDirectorUga: {
          title: 'Assignation des directeurs régionaux par UGA',
          formatDescription:
            "- Prénom du directeur régional: texte\n- Nom du directeur régional: texte\n- Numéro de téléphone du directeur régional: nombre (peut commencer par '+33')\n- Email du directeur régional: texte\n- UGA: texte\n",
          effect:
            'Les comptes des utilisateurs présents dans le fichier seront créés et rattachés au laboratoire. La sectorisation des pharmacies sera mise à jour selon les information présentes dans le fichier.',
          fileExample:
            'first_name;last_name;phone_number;email;uga\nJohn;Lee;+33123456789;john.doe@example.com;NCA00Jane;Smith;0000000000;jane.smith@example.com;95PON\nBob;Johnson;+33666666666;bob.johnson@example.com;GUA00Sara;Lee;+33777777777;sara.lee@example.com;MEP00\nTom;Smith;0123456789;tom.smith@example.com;MAR00Tom;Smith;0123456789;tom.smith@example.com;GUY00\nMike;Brown;0123456789;mike.brown@example.com;REU01\nKaren;Jones;+33696969696;karen.jones@example.com;REU02\nSteve;Davis;0123456789;steve.davis@example.com;REU00\n',
        },
        updateExpiredYearlyMax: {
          title: 'Mise à jour des montants de périmés maximums par an',
          formatDescription:
            "- Montant maximum: nombre, optionnel. L'absence de montant renseigné permet de retirer la limite annuelle de périmés",
          effect:
            'Les montants de périmés maximums par an seront mis à jour sur les pharmacies présentes dans le fichier',
          fileExample: 'maximum_yearly_expired_products_refund_euro\n150\n300\n200\n150',
        },
        replaceGroupPharmacies: {
          title: "Remplacement des pharmacies d'un groupement",
          formatDescription: '',
          effect:
            'Les pharmacies présentes dans le fichier seront ajoutées au groupement. Les pharmacies du groupement et non présentes dans le fichier seront enlevées du groupement.',
          fileExample: '',
        },
        analyseReferentialPharmacies: {
          title: "Analyse d'un fichier de référence client",
          formatDescription: '',
          effect:
            "Cette tâche génèrera un fichier récapitulatif d'analyse du fichier de référence, en le comparant avec les données de Faks. Elle ne modifiera pas les informations des pharmacies présentes dans Faks.",
          fileExample: '',
        },
        pharmacyBase: {
          formatDescription:
            "- Numéro de téléphone de la pharmacie\n- Cip de la pharmacie: nombre\n- Email de la pharmacie: texte\n- Nom de la pharmacie: texte\n- Adresse de la pharmacie: texte\n- Code postal de la pharmacie: 5 chiffres\n- Ville de la pharmacie: texte\n- Identifiant externe: texte\n- Uga: texte, doit être un uga existant\n- Type d'établissement: pharmacy / hospital_pharmacy / parapharmacy / medical_equipement_store / sports_store / organic_store / wholesaler / central_purchasing_office / physiotherapist / retirement_home / e_commerce_website / specialized_store / gym / beauty_sport_institute / warehouse / medical_profession_and_similar / other\n",
          fileExample:
            'phone_number;cip;email;name;address_line_1;address_zip_code;address_city;external_membership_id;uga;kind\n0912310101;1234567;valid@email.com;Pharmacie 1;1 rue valide;75017;Paris;401521;;pharmacy\n0912310102;1234568;;Pharmacie 2;2 rue valide;75018;Paris;;;pharmacy\n0912310201;1234569;valid@email.com;Pharmacie 3;3 rue valide;75019;Paris;411052;;pharmacy\n0912310202;1234579;valid@email.com;Pharmacie 4;4 rue valide;75020;Paris;412588;;parapharmacy',
        },
        createLabPharmaciesLists: {
          fileExample: '',
          title: "Création d'une liste de pharmacies par un laboratoire",
          effect: '',
          formatDescription: '',
        },
        createGroupPharmaciesLists: {
          fileExample: '',
          title: "Création d'une liste de pharmacies par un groupement",
          effect: '',
          formatDescription: '',
        },
        createTradeProducts: {
          title: 'Création de produits pour une opération',
          formatDescription:
            '- Ean du produit: 13 chiffres, obligatoire\n- Nom du produit: texte, obligatoire\n- TVA: nombre, parmi : 20.0, 10.0, 5.5, 2.1, 0.0',
          fileExample:
            'ean;name;vat\n1234567656787;Name 1;\n1231234325675;Name 2;10.0\n1265473456234;Name 3;\n8371627384738;Name 4;5.5\n',
          effect:
            "Les produits du fichier seront rattachés à l'opération sélectionnée. ⚠️ Attention : Si un produit était déjà présent sur l'opération (matché par son ean), il sera remplacé avec les nouvelles informations.",
        },
        createOrderTradeProducts: {
          title: 'Création de produits pour une opération de type commande',
          formatDescription:
            "- Ean du produit: obligatoire, 13 chiffres\n- Nom du produit: obligatoire, texte\n- Instruction pharmacien: facultatif, 13 chiffres\n- TVA: facultative, nombre, parmi : 20.0, 10.0, 5.5, 2.1, 0.0\n- Prix d'achat: facultatif, en centimes\n- Prix remisé: facultatif, en centimes\n- Colisage: facultatif",
          fileExample:
            'ean;name;information_for_pharmacist;vat;sell_in_purchase_price_in_euro_cents;sell_in_discount_price_in_euro_cents;packing_quantity\n1234567656787;Name 1;product delivered separately;10.0;1800;1600;10\n1231234325675;Name 2;;;;;\n',
          effect: "Les produits du fichier seront rattachés à l'opération sélectionnée.",
        },
        createStandardTradeProducts: {
          title: 'Création de produits',
          formatDescription:
            '- Ean du produit: obligatoire, 13 chiffres\n- Nom du produit: obligatoire, texte\n- TVA: facultative, nombre, parmi : 20.0, 10.0, 5.5, 2.1, 0.0',
          fileExample:
            'ean;name;vat\n1234567656787;Name 1;\n1231234325675;Name 2;10.0\n1265473456234;Name 3;\n8371627384738;Name 4;5.5\n',
          effect: "Les produits du fichier seront rattachés à l'opération.",
        },
        createStandardTradeProductsVatRequired: {
          title: 'Création de produits',
          formatDescription:
            '- Ean du produit: obligatoire, 13 chiffres\n- Nom du produit: obligatoire, texte\n- TVA: obligatoire, nombre, parmi : 20.0, 10.0, 5.5, 2.1, 0.0',
          fileExample:
            'ean;name;vat\n1234567656787;Name 1;0.0\n1231234325675;Name 2;10.0\n1265473456234;Name 3;10.0\n8371627384738;Name 4;5.5\n',
          effect: "Les produits du fichier seront rattachés à l'opération.",
        },
        updateTradesInstancesValidationStatus: {
          title: 'Valider / refuser des participations trades',
          formatDescription:
            '- ID du trade instance, obligatoire\n- Statut : validated_by_lab / refused_by_lab / awaiting_validation_by_lab, obligatoire\n- Compensation en centimes\n-Raison de refus du labo\n\nLes trades instances concernées doivent avoir comme statut courant une des valeurs possibles du champ "status".\nS\'il y a déjà une compensation calculée automatiquement sur une remontée, alors le champ compensation_in_cents sera ignoré.\nUne ligne contenant un "lab_refused_reason" doit avoir un statut "refused_by_lab".\nUne compensation en centimes sera uniquement valide si elle est actuellement vide dans la trade instance OU si son statut est en attente de validation labo.\nIl ne peut pas y avoir de transition entre un statut "validated_by_lab" et "refused_by_lab".',
          fileExample:
            'trade_instance_id;status;compensation_in_cents;lab_refused_reason\n1;validated_by_lab;30;\n2;awaiting_validation_by_lab;;\n3;refused_by_lab;;Mauvaise remontée\n4;refused_by_lab;;\n',
          effect: 'Les trades instances seront mises à jour avec les données du fichier.',
          modeOptions: {
            default: 'Par défaut',
            force_compensation_update: 'Forcer la mise à jour de la compensation',
          },
        },
        ImportTradeSelloutData: {
          title: 'Ajouter du sellout provenant des panélistes',
          effect:
            "Chaque ligne de sellout du fichier sera rattaché à la remontée d'opération de la pharmacie sur le trade sélectionné.",
          formatDescription:
            '- Cip de la pharmacie: nombre, obligatoire\n- Ean du produit: 13 chiffres, obligatoire\n- Quantité: nombre, obligatoire\n- CA TTC: nombre, en centimes\n- CA HT: nombre, en centimes',
          fileExample:
            'cip;ean;quantities;turnover_including_tax_in_cents;turnover_excluding_tax_in_cents\n2227838;1234567898765;5;3400;\n2227838;7639928881723;20;;2145\n4729338;7639928881723;10;7000;\n4729338;8273001823374;45;;3899\n',
        },
        importTradeSelloutDataFromPdf: {
          title: 'Ajouter du sellout provenant des PDF',
          effect: "Chaque ligne de sellout du fichier sera rattaché à la remontée d'opération.",
          formatDescription:
            "- Id de la remontée d'opération: nombre, obligatoire\n- Ean du produit: 13 chiffres, obligatoire\n- Quantité: nombre, obligatoire\n- CA TTC: nombre, en centimes\n- CA HT: nombre, en centimes",
          fileExample:
            'trade_instance_id;ean;quantities;turnover_including_tax_in_cents;turnover_excluding_tax_in_cents\n1;1234567898765;5;3400;\n98;7639928881723;20;;2145\n32;7639928881723;10;7000;\n43;8273001823374;45;;3899\n',
        },
        createAdCampaign: {
          title: 'Créer une campagne de messages délégué',
          effect:
            'Chaque ligne du fichier créera un message avec un CTA (Call to Action) dans la discussion entre la pharmacie et son délégué',
          formatDescription:
            '- ID Faks de la pharmacie: nombre, obligatoire\n - Texte du message: texte, obligatoire\n - Texte du CTA: texte, optionel (doit être co-défini ou non avec le champ URL du CTA)\n - URL du CTA: texte, optionel (doit être co-défini ou non avec le champ Texte du CTA)',
          fileExample:
            'pharmacy_id;message;cta_text;cta_url\n121;Bonjour, voici un message;Cliquez ici;https://faks.co\n122;Bonjour, voici un message;Cliquez ici;https://faks.co\n123;Bonjour, voici un message;Cliquez ici;https://faks.co',
          sendDate: "Date d'envoi de la campagne",
          salesRepMessage: 'Message pour le Délégué',
          standardPharmacistMessage: 'Message type envoyé aux pharmaciens (visible par le délégué)',
          selectImageOrDocument: 'Choisir une image ou un document',
        },
        markParticipationsAsCompensatedOutsideFaks: {
          title: 'Marquer des participations comme compensées hors Faks',
          effect: 'Chaque participation sera marquée comme compensée hors Faks',
          formatDescription:
            '- ID participation: nombre, obligatoire, unique\n - ID opération laboratoire: nombre, obligatoire',
          fileExample: 'participation_id;master_trade_id\n123;456',
        },
      },
      launchIngestion: "Lancer l'ingestion",
      loadingModal: {
        loadingMessage:
          'Veuillez patienter pendant le chargement et la vérification de votre fichier',
        validationSucceeded:
          'La validation de votre fichier a été effectuée avec succès. La tâche associée va maintenant pouvoir être déclenchée. Vous recevrez un email récapitulatif de la tâche une fois celle-ci terminée.',
        validationFailed:
          'La validation de votre fichier a échoué. Vous trouverez ci-dessous la liste des erreurs trouvées dans le fichier. Vous pouvez les corriger et essayer à nouveau',
        validationFailedMainReason: {
          title: 'Raison principale:',
          invalid_file: 'Formattage du fichier invalide',
          invalid_encoding: 'Type de fichier non supporté',
          invalid_data: 'Les informations fournies ne sont pas correctes',
          invalid_option: 'Les options ne sont pas valides',
        },
        line: 'Ligne',
        column: 'Colonne',
        validationFailedErrors: {
          // COMMON
          malformed_header: 'En-tête invalide',
          invalid_type: 'Type invalide',
          missing_value: 'Valeur manquante',
          pattern: 'Format incorrect',
          extra_column: 'Colonne en trop',
          missing_column: 'Colonne manquante',
          unique: 'Donnée dupliquée',
          below_minimum: 'Valeur trop basse',
          above_maximum: 'Valeur trop haute',
          blank_rows: 'Ligne vide',
          max_length: 'Texte trop long',
          check_options:
            "Le fichier a l'air de ne contenir qu'une seule colonne (Avez-vous utilisé le bon séparateur ?)",
          min_length: 'Texte trop court',
          ambiguous_match: 'Plusieurs pharmacies trouvées dans Faks pour cette ligne',
          // CREATE TRADES
          pharmacy_list_id_not_found: 'Liste de pharmacies non trouvée',
          lab_email_required: 'Email du labo obligatoire',
          platform_trade_message_guidance_required: 'Instructions pharmacie obligatoire',
          officine_picture_name_required: 'Nom Preuve photo officine obligatoire',
          lgo_picture_name_required: 'Nom Preuve photo lgo obligatoire',
          sellout_name_required: 'Nom Preuve sellout obligatoire',
          lab_trade_id_not_found: 'Trade labo non trouvé',
          cant_ask_requirement_on_platform: "Demande de preuves sur un trade de type 'platform'",
          at_least_one_requirement_required:
            "Au moins une preuve est requise pour ce type d'opération",
          details: 'Détails : ',
          tag_doesnt_exist: "Les tags suivants n'existent pas.",
          lab_not_found: "Le laboratoire n'a pas été trouvé",
          invalid_trade_params:
            'Laboratoire premium sur les opérations, impossible de créer un trade direct labo ou par le groupement.',
          cant_ask_sellout_with_sellout_requirement:
            'Le sellout manuel ne peut pas être demandé en même temps que la preuve sellout.',
          cant_ask_lgo_and_sellout_requirements:
            'Vous ne pouvez pas demander une preuve LGO et sellout en même temps. Un pdf est déjà demandé dans la preuve sellout.',
          product_fields_not_filled: 'Les colonnes des produits ne sont pas toutes remplies',
          product_fields_not_complete:
            'Les valeurs des produits ne sont pas complètes (ean/nom/tva manquant)',
          product_eans_length_or_type: "L'ean d'un des produits n'est pas au bon format.",
          product_vats_not_found: "La TVA d'un des produits n'est pas valide.",
          product_eans_duplicate: 'Certains eans des produits sont en double',
          picture_and_sellout_name_should_be_uniq:
            'Le nom de la preuve sellout et photo doivent être différents',
          // CREATE TRADE PRODUTS
          vat_not_found: "La TVA n'est pas valide",
          // IMPORT TRADE SELLOUT DATA
          instance_already_have_validated_sellout:
            'Le sellout de cette remontée a déjà été validé par la pharmacie.',
          cant_add_sellout_if_pdf_present:
            'La remontée a déjà un PDF. Il n’est pas possible d’ajouter du sellout provenant des panélistes dans ces cas là.',
          pharmacy_not_found: "La pharmacie n'a pas été trouvée",
          trades_instance_not_found: "La pharmacie n'a pas de remontée d'opération",
          product_not_found_in_trade: "Le produit n'a pas été trouvé sur le trade",
          product_vat_not_found_in_trade: "Le produit n'a pas de taux de TVA renseigné",
          sellout_trade_requirement_not_found: "L'opération ne demande pas de preuve sellout",
          trade_doesnt_enable_lgo_sellout:
            "L'opération ne demande pas la remontée de sellout via le lgo",
          no_trades_on_master_trade: "Pas d'opération trouvée sur l'oprération laboratoire",
          no_turnover_data: "Le chiffre d'affaire n'est pas remplis",
          pharmacy_not_connected_on_choosen_panelist:
            "La pharmacie n'est pas connecté au paneliste choisi",
          cip_ean_couple_duplicate: 'Plusieurs lignes ont le même couple CIP<>EAN',
          // IMPORTE TRADE SELLOUT DATA FROM PDF
          cant_add_sellout_if_pdf_not_present: "La remontée n'a pas de PDF.",
          trade_instance_doesnt_have_awaiting_validation_status:
            "La remontée d'opération n'est pas en attente de validation",
          trade_instance_not_found: "La remontée d'opération n'a pas été trouvée",
          trade_instance_ean_couple_duplicate:
            "Plusieurs lignes ont le même couple ID de remontée d'opération<>EAN",
          pharmacy_refused_to_participate: "La pharmacie a archivé l'opération",
          // CREATE PHARMACIES LISTS
          pharmacy_not_in_group: "La pharmacie n'appartient pas au groupement.",
          cant_find_pharmacy: "La pharmacie n'a pas pu être trouvée. Vérifiez les informations.",
          // UPDATE EXTERNAL MEMBERSHIP IDS
          external_membership_id_already_used:
            "L'identifiant client est déjà utilisé sur la pharmacie suivante.",
          // SALES REP / REGIONAL DIRECTOR ERRORS
          many_duplicates_for_user:
            "L'adresse email et le numéro de téléphone matchent deux utilisateurs différents",
          no_match_on_phone:
            "L'email est déjà utilisé par un utilisateur avec un numéro de téléphone différent de celui renseigné",
          no_match_on_email:
            'Le numéro de téléphone est déjà utilisé par un utilisateur avec une adresse mail différente de celle renseignée',
          same_first_last_name: 'Même nom et prénom',
          inconsistent_user_data: 'Information délégués incohérentes',
          uga_not_found: 'Uga introuvable',
          incompatible_with_user_current_roles:
            "L'utilisateur trouvé à déjà un rôle incompatible avec ce type d'ingestion",
          trades_instance_does_not_exist: "Aucun trade instance n'existe avec cet ID",
          wrong_status_for_refusal:
            'Le statut doit être "refused_by_lab" si la ligne contient un message de refus du laboratoire',
          wrong_status_for_compensation:
            'La compensation peut être mise à jour uniquement si le trade instance est en attente de validation labo.',
          wrong_trades_instance_status:
            "Le statut actuel de la trade instance n'autorise pas sa mise à jour.",
          wrong_trades_instance_status_transition:
            "Le statut actuel de la trade instance n'autorise pas sa mise à jour vers le statut demandé.",
          cant_add_admin_user: "Cet utilisateur est un admin Faks, vous ne pouvez pas l'ajouter",
          // CREATE ADS CAMPAIGN
          pharmacy_does_not_exist: "Aucune pharmacie n'existe pour cet ID",
          pharmacy_does_not_have_discussion_enable:
            "La discussion n'est pas activée pour cette pharmacie",
          pharmacy_does_not_have_lab_user: "La pharmacie n'a pas de délégué configuré pour ce labo",
          row_is_not_valid: "Cette ligne du fichier n'est pas complète",
          cta_url_is_not_valid: "Le cta_url de cette ligne n'a pas le bon format",
          cta_text_too_long: 'Le cta_text de cette ligne dépasse les 40 caractères',
          cta_text_and_url_inconsitency:
            'Les champs cta_url et cta_text devraient être tous les deux vides ou tous les deux definis',
          end_date_must_be_after_start_date:
            "La date de fin de l'opération doit être après la date de début",
          // MARK PARTICIPATION AS COMPENSATED OUTSIDE FAKS
          master_trade_not_found_for_lab:
            "L'opération laboratoire n'a pas été trouvée pour le laboratoire renseigné",
          participation_not_found_for_master_trade:
            "La participation n'a pas été trouvée pour l'opération laboratoire renseignée",
          invalid_status_to_compensate_outside_faks:
            "La participation n'est pas au status 'validée par le laboratoire'",
        },
        validationFailedErrorsMoreInfo: {
          inconsistent_user_data:
            "Les données de l'utilisateur ne sont pas identiques entre toutes les lignes où il est présent",
          ambiguous_match:
            'Le téléphone et le CIP de la ligne correspondent à deux pharmacies différentes dans Faks',
        },
      },
    },
    orders: {
      title: 'Commandes',
      newButton: 'Nouvelle commande',
      editCta: 'Modifier',
      searchForOrders: 'Trouver une commande',
      tabs: {
        draft: 'Brouillon',
        blocked: 'Bloquées',
        created: 'Créées',
        transmitted: 'Transmises',
        receivedByLab: 'Reçu',
        opened: 'Ouvertes',
        responded: 'Répondues',
        confirmed: 'Confirmées',
        all: 'Toutes',
      },
      listHeaders: {
        id: 'ID',
        lab: 'LABORATOIRE',
        pharmacy: 'PHARMACIE',
        createdAt: 'CRÉÉE LE',
        status: 'STATUT',
        action: 'ACTION',
      },
      show: {
        title: 'Commande {{labName}} #{{id}}',
      },
      edit: {
        title: 'Modifier la commande #{{id}}',
      },
      form: {
        receiver_option: 'Option de réception',
        receiver_email: 'Email du destinataire',
        transmit: 'Transmettre directement',
        status: 'Status',
        total_price: 'Prix TTC (en €)',
        faks_note: 'Note de Faks (privée)',
        confirmed_at: 'Date de confirmation',
      },
      labCard: {
        defaultLabEmail: 'Email par défaut du laboratoire',
        defaultLabPhone: 'Téléphone par défaut du laboratoire',
        orderLabEmail: 'Email du service des commandes',
        orderLabPhone: 'Téléphone du service des commandes',
        cooperationLevel: 'Niveau de coopération',
      },
      noteCard: {
        title: 'Note de Faks',
      },
      orderCard: {
        title: 'Informations sur la commande',
        total_price: 'Prix TTC en euros',
        origin: 'Origine de la commande',
        info: 'Commentaire du pharmacien',
        documents: 'Documents',
        receiver_option: 'Option de réception',
      },
      discussionCard: {
        title: 'Discussion',
      },
      messageModal: {
        title: 'Mail #{{id}}',
      },
      deleteMessageModal: {
        title: 'Supprimer le message ?',
        description: 'Ce message sera supprimé de l’historique de discussion. Confirmer ?',
      },
    },
    newsfeedPosts: {
      adminNewsfeedPostEdit: {
        title: 'Éditer le post #{{id}}',
      },
    },
    users: {
      kind: {
        regular_user: 'Utilisateur',
        admin: 'Administrateur',
        onboarding: 'Onboarding',
      },
      adminUsers: {
        title: 'Utilisateurs',
        newUser: 'Nouvel utilisateur',
        findAUser: 'Trouver un utilisateur',
        newUserWithData: 'Nouvel utilisateur admin avec de la data',
      },
      adminUser: {
        id: 'id',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        phone: 'Téléphone',
        lastTimeConnected: 'Date de dernière connexion',
        confirmEmail: 'Confirmer l’email',
        roles: 'Rôles',
      },
      adminUserNew: {
        title: 'Nouvel utilisateur',
        errors: {
          email_already_taken: 'Cet email est déjà utilisé',
          phone_already_taken: 'Ce numéro de téléphone est déjà utilisé',
        },
      },
      adminUserForm: {
        profilePicture: 'Photo de profil',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        emailHelperText:
          "À la création du compte l'utilisateur reçoit un mail de validation. Il doit cliquer sur le lien sans quoi il perd l'accès après quelques jours",
        phoneNumber: 'Numéro de téléphone',
        stopSms: 'Stop SMS',
        chatByEmail: 'Chat uniquement par email',
        generatePassword: 'Générateur de mot de passe',
        password: 'Nouveau mot de passe',
        passwordConfirmation: 'Confirmation du mot de passe',
        userKind: "Type d'utilisateur",
        isDeactivated: 'Utilisateur Désactivé',
        userKindOptions: {
          admin: 'Admin (Faks)',
          onboarding: 'Onboarding (Nexus)',
          regular_user: 'Utilisateur final',
        },
        rppsId: 'Numéro RPPS',
        passwordError: 'Les mots de passe ne correspondent pas',
        passwordConfirmationError: 'Les mots de passe ne correspondent pas',
      },
      adminUserEdit: {
        title: "Éditer l'utilisateur",
        errors: {
          email_already_taken: 'Cet email est déjà utilisé',
          phone_already_taken: 'Le numéro de téléphone est déjà pris',
          user_cannot_be_deleted: 'Cet utilisateur ne peut être supprimé',
        },
        alert: {
          is_identity_provider_bound:
            "Il n'est pas possible de modifier l'adresse email de cet utilisateur car il dépend d'un fournisseur d'identité tiers.",
        },
      },
      newUserWithDataForm: {
        addNewUserWithData: 'Ajouter un nouvel utilisateur admin avec de la data',
        recommendedGroup: 'Conseillé : GROUPEMENT XAV',
        recommendedLab: 'Conseillé : Labo DEMO Eleonore',
        recommendedSalesRep: 'Conseillé : Eleonore Déléguée Labo Démo',
        defaultGroup: 'Groupement par défaut',
        firstLab: 'Laboratoire du premier délégué',
        firstSalesRep: 'Premier délégué',
      },
    },
    groupsLabs: {
      adminGroupsLabs: {
        title: 'Partenariats Groupements / Laboratoires',
      },
      adminGroupsLabEdit: {
        title: 'Éditer le partenariat',
        invoicesVisible: 'Demandes visibles',
        invoicesVisibleInfoText:
          ' - Le laboratoire autorise le groupement à voir les conversations et y publier des messages',
        invoicesVisibleFromGroup: 'Demandes visibles pour le groupement',
        invoicesVisibleFromGroupInfoText:
          ' - le groupement peut voir les conversations et y publier des messages',
        isTradesPartner: 'Partenaires Trades',
        isTradesPartnerInfoText: ' - Le laboratoire a un partenariat Trade avec le groupement',
      },
    },
    checkNeedingPharmacies: {
      adminCheckNeedingPharmacies: {
        title: 'Pharmacies A checker -> correspondance avec les données de ref',
        deleteCNP: 'Retirer',
        deleteCNPDescription: 'Retirer cette pharmacie de la liste ?',
        cipMatches: 'Cip Matches:',
      },
      infoBox: {
        title: 'Qu’est-ce que cette page ?',
        description_1:
          'Cette page contient la liste des pharmacies ayant fait leur première remontée de périmées et qui n’ont pas encore été vérifiées par Faks.',
        description_2:
          'On montre la pharmacie et ses informations, et en face les informations de référence de nos laboratoires clients, <bold>pour le meme CIP</bold>',
        description_3:
          "Il faut vérifier que les autres informations (adresse, téléphone, etc) correspondent et donc <bold>qu'on parle bien de la même pharmacie</bold>",
        description_4:
          "Dans ce cas, on peut cliquer sur 'Retirer' pour valider la pharmacie et l'enlever de la liste",
        description_5:
          'Si les informations ne correspondent pas, il faut trouver le bon cip de la pharmacie et la mettre à jour, ou corriger son addresse, etc. Si on a deja le bon cip, ne rien faire',
      },
    },
    referentialPharmacies: {
      adminReferentialPharmacies: {
        title: 'Pharmacies - Données de référence des laboratoires',
        searchForReferentialPharmacies: 'Trouver une pharmacie',
      },
    },
    inboundMail: {
      inboundMailsPage: {
        tabs: {
          received: 'Non traités',
          processed: 'Traités',
          archived: 'Archivés',
          all: 'Tous',
        },
        item: {
          archiveModalDescription:
            'Archivez ce mail si il ne correspond pas à une discussion Faks (Commandes, Récla, etc)',
          unarchiveModalDescription:
            "Désarchivez ce mail pour le traiter si vous l'avez archivé par erreur",
        },
      },
      inboundMailsDetailsModal: {
        title: 'Mail entrant {{id}} - Traitement',
        from: 'De:',
        to: 'Vers:',
        subject: 'Sujet:',
        at: 'À:',
        body: 'Corps du mail',
        attachments: 'Pièces jointes',
        noAttachments: 'Pas de pièces jointes',
        noPreview: 'Pas de preview',
        makeAChoiceForEachAttachment: 'Faire un choix pour chaque PJ',
        closeClaim: 'Marquer la réclammation commme résolue',
        confirmOrder: 'Marquer la commande commme confirmée',
      },
    },
    expiredProductsRequest: {
      reassignPharmacy: 'Réassigner la pharmacie',
      reassignExpiredProductsRequestPharmacyModal: {
        linkedUser: 'Utilisateur assigné à la remontée',
        reassignSuccess: 'La pharmacie a bien été assignée à la remontée',
      },
    },
    pharmacies: {
      resetStripeSettings: 'Réinitialiser la configuration Stripe',
      resetStripeSettingsDescription:
        'Êtes-vous sûr de vouloir réinitialiser la configuration Stripe pour cette pharmacie ? Cette action est irréversible et demandera au titulaire de la pharmacie de suivre les étapes de configuration de Stripe afin de créer un nouveau compte.',
    },
    tradesInstances: {
      externalForm: {
        title: 'Création de participations trades externes',
        warning:
          'Ce formulaire permet d’intégrer des participations trades provenant de sources externes. Ces participations ne seront pas modifiables par le pharmacien, et seront visibles par le laboratoire. Attention ce formulaire doit être exclusivement utilisé pour intégrer des participations externes ! Ne pas utiliser ce formulaire pour créer des remontées trades pour d’autres cas d’usage.',
        source: 'Source',
        status: 'Status',
        pharmacyInGroup: 'Cette pharmacie appartient bien à ce groupement',
        pharmacyNotInGroup: 'Attention cette pharmacie appartient à {{groupName}}',
        pharmacyWithoutGroup: "Attention cette pharmacie n'appartient à aucun groupement",
        success: 'La participation a bien été créée',
        saveAndContinue: 'Sauvegarder et continuer avec la même opération',
        warningMessage: {
          pharmacy_not_targeted: "Attention : La pharmacie n'est pas ciblée par l'opération",
          trade_not_accessible: "Attention : La pharmacie n'a pas accès à l'opération",
          participation_with_requirements_instance_exists:
            'Attention : Cette pharmacie a déjà ajouté des preuves sur cette opération, êtes-vous sûr de vouloir supprimer et remplacer sa participation ?',
          participation_exists: "Attention : Cette pharmacie s'est déjà inscrite à cette opération",
        },
      },
    },
  },
  discussions: {
    title: 'Discussions',
    newDiscussion: 'Nouvelle discussion',
    table: {
      search: 'Trouver labo ou délégué',
    },
  },
  discussion: {
    seeLabPage: 'Voir la page laboratoire',
    setLabUser: 'Renseigner mon délégué',
    cannotSetLabUser:
      'Délégué renseigné par le laboratoire, Merci de contacter Faks pour le changer.',
    editLabUser: 'Editer mon délégué',
    labUserOptions: {
      email: 'Renseigner<br />mon délégué',
      lab_user: 'Trouver<br />mon délégué',
    },
    messageOptions: {
      noteInfo:
        'Partagez les informations avec votre équipe. Exemple : compte rendus de rendez-vous, informations générales telles que le franco, instructions, etc.',
      messageInfo:
        'Pour envoyer un message à votre délégué vous devez le renseigner. Vous pouvez aussi demander à Faks de le trouver pour vous',
      ordersDisabled: 'Ce laboratoire ne traite pas les commandes sur Faks',
      claimsDisabled: 'Ce laboratoire ne traitre pas les réclamations sur Faks',
    },
  },
  orders: {
    pageTitle: 'Commandes',
    newOrder: 'Nouvelle commande',
    completedOrders: 'Commandes terminées',
    alreadyUpdated: 'Déjà mis à jour',
    table: {
      search: 'Trouver une commande',
    },
    tabs: {
      pending: 'En cours',
      completed: 'Terminées',
    },
    upload: 'Déposer ici votre bon de commande',
    uploadStep1: '1. Créer la commande dans mon logiciel de Gestion d’Officine',
    uploadStep2: '2. Extraire le bon de commande au format pdf',
    uploadStep3: '3. Déposer ici le bon de commande',
    orderNumber: 'Commande #{{id}}',
    transmitted_to_at: 'Transmise le {{at}} à {{to}}',
    opened_by_at: 'Lue le {{at}} par {{by}}',
  },
  order: {
    statuses: {
      none: 'Aucun',
      created: 'Créée',
      transmitted: 'Transmise',
      opened: 'Lue',
      responded: 'Répondue',
      received_by_lab: 'Reçue',
      confirmed: 'Clôturée',
      confirmed_by_lab: 'Cloturée par le labo',
      refused_by_lab: 'Refusée',
      draft: 'Brouillon',
      cancelled: 'Annulée',
      blocked: 'Bloquée',
      transferred_only: 'Transfert uniquement',
    },
    labStatuses: {
      none: 'Aucun',
      created: 'À traiter',
      transmitted: 'Transmise',
      opened: 'Lue',
      responded: 'Répondue',
      confirmed_by_lab: 'Validée',
      refused_by_lab: 'Refusée',
      received_by_lab: 'Reçue',
      confirmed: 'Clôturée',
      cancelled: 'Annulée',
      blocked: 'Bloquée',
      draft: 'Brouillon',
    },
    receiver_options: {
      receiver_is_customer_service: 'Service client laboratoire',
      receiver_is_lab_user: 'Délégué',
    },
    actions: {
      cancel: 'Annuler la commande',
      confirm: 'Clôturer la commande',
      disable: 'Non disponible',
      disableKindAndStatus: 'Non disponible pour ce type et ce statut de commande',
      disableKind: 'Non disponible pour ce type de commande',
      disableStatus: 'Non disponible pour ce statut de commande',
      edit: 'Modifier la commande',
      unread: 'Marquer comme non lu',
      transfer: 'Changer de destinataire',
    },
    suggested_actions: {
      resend_message:
        'Bonjour,\n\nPouvez-vous me confirmer la prise en compte de ma commande ?\n\nMerci d’avance.',
      resend_x_days_message:
        'Bonjour,\n\nPouvez-vous me dire où en est cette commande ?\n\nMerci d’avance.',
      resend: 'Pas de réponse. Relancer ?',
      resend_x_days: 'Pas de réponse depuis {{x}} jours. Relancer ?',
      respond_x_days: 'Reçu il y a {{x}} jours. Répondre ?',
      transfer: 'Pas de réponse. Transmettre au service commande ?',
      confirm: 'Commande reçue ? Clôturer ?',
      blocked: 'Commande bloquée. Changer le destinataire.',
    },
    transferModal: {
      toCustomerService: 'Transférer au service commande',
      title: 'Changer de destinataire',
      to: 'À :',
      cc: 'Cc :',
      message: {
        label: 'Message :',
        default:
          'Bonjour\n\nEn l’absence de réponse à mes messages précédent, je vous transmets cette commande.\n\nPouvez-vous me confirmer l’avoir prise en compte ?\n\nMerci d’avance',
        customer_service:
          '[À destination du service commande]\n\nEn l’absence de réponse de mon délégué je vous transmets cette commande.\n\nPouvez-vous me confirmer l’avoir prise en compte ?\n\nMerci d’avance',
      },
    },
    chooseMyDelegateModal: {
      title: 'Mon Délégué',
      activeInMyDepartment: 'Actifs dans mon département',
    },
    confirmModal: {
      title: 'Clôturer la commande',
      description: 'Vous allez clôturer (archiver) cette commande',
      cancel: 'Non, annuler cette commande',
      confirm: 'Clôturer',
    },
    cancelModal: {
      title: 'Annuler la commande',
      description: 'Vous allez annuler cette commande, un message sera envoyé au laboratoire',
      cancel: 'Non, seulement la clôturer (archiver)',
      confirm: 'Annuler et envoyer',
      message: {
        label: 'Message :',
        default: 'J’annule cette commande, merci de ne pas en tenir compte',
      },
    },
    origins: {
      faks_mobile: 'Mobile',
      faks_web: 'Web',
      lgo_order: 'Commande LGO',
      lgo_transfer: 'Transfert LGO',
      other: 'Autre',
    },
  },
  newDiscussion: {
    pageTitle: 'Nouvelle discussion',
    lab: {
      label: 'Laboratoire',
    },
    message: {
      label: 'Message',
    },
    labUser: {
      missingInfo:
        'Votre délégué n’est pas renseigné, vos messages seront enregistrés en note interne',
    },
  },
  newOrder: {
    pageTitle: 'Nouvelle commande',
    sendOrder: 'Transmettre ma commande',
    receiver: {
      label: 'Destinataire',
    },
    restockingTopSuggestions: {
      title: 'Suggestions de réassort',
      link: 'Voir toutes les suggestions',
      noSuggestions: 'Aucune suggestion de réassort pour le moment',
      noMoreStock: 'Stock épuisé, ',
      only: 'plus que',
      left: ',',
      order: 'commander',
    },
    receiver_email: {
      placeholder: 'example@laboratoire.com',
      required: 'L’email du destinataire est requis',
    },
    find_by_faks: {
      title: 'Vous ne le connaissez pas ?',
      label: 'Faks le trouve pour moi',
      info: 'J’accepte que Faks contacte le laboratoire en mon nom pour obtenir les coordonnées de mon délégué',
    },
    receiver_options: {
      receiver_is_customer_service: 'Service<br />client',
      receiver_is_lab_user: 'Mon délégué',
    },
    labInfo: 'Informations générales',
    stockInfo: 'Stock',
    discountInfo: 'Promotions',
    outOfStock: 'Ruptures connues',
    drugsShortage: {
      unavailableTitleShort: 'Ruptures',
      unavailableTitle: 'Ruptures fournisseur',
      recentlyAvailableTitle: 'Retours en stock',
      since: 'depuis le',
      unavailableTooltip:
        'Estimation basée sur l’indice de tension <a href="https://www.vigirupture.fr/" target="_blank">Vigirupture</a>',
    },
    createOrder: 'Créer un bon de commande',
    continueOrder: 'Reprendre ma commande',
    or: 'ou',
    lab: {
      label: 'Laboratoire',
      placeholder: 'Trouver un laboratoire',
    },
    additionalInformation: {
      label: 'Message',
      default:
        'Bonjour,\n\nJ’espère que vous allez bien.\nCi jointe une commande urgente.\nJ’attends votre confirmation.\n\nBien cordialement,\n',
    },
    documents: {
      label: 'Bon de commande',
    },
    composeOrder: {
      myOrder: 'Ma commande',
      searchPlaceholder: 'Rechercher un produit',
      confirm: 'Valider mon bon de commande',
      pageTitle: 'Bon de commande',
      productOnShortage: 'Produit en rupture de stock',
      draftExists: 'Une commande a déjà été créée pour ce laboratoire',
      unavailableStock: 'Le stock de ce médicament n’est pas disponible',
      needsSync: 'Pour connaître votre stock en temps réel, activez la synchronisation Offisante.',
      seeCart: 'Voir uniquement les produits de mon panier',
      seeAll: 'Voir tous les produits du laboratoire',
      continueDraftModal: {
        title: 'Reprendre la commande en brouillon',
        text: 'Voulez-vous ajouter les lignes suivantes au panier ?',
        yes: 'Oui, ajouter',
        no: 'Non, voir mon panier',
      },
      tableHeaders: {
        product: 'Produit',
        information_for_pharmacist: 'Information complémentaire',
        quantity: 'Quantité',
        stock: 'Stock',
        sell_in_purchase_price_in_euro: 'Prix d’achat (€)',
        sell_in_discount_price_in_euro: 'Prix remisé (€)',
      },
      suggestions: "Suggestions d'ajout",
      suggestionsTableHeaders: {
        ean: 'Code EAN',
        name: 'Nom',
        suggestion: 'Suggestion',
        reason: 'Motif',
        actions: 'Actions',
      },
    },
    offisanteStocksSync: {
      orderTitle: 'Connectez vos stocks pour pré-remplir votre bon de commande',
      restockingTitle: 'Connectez vos stocks pour simplifier votre réassort',
      text: "J'accepte qu'Offisante transmette à Faks mes données de stock",
      button: 'Activer la synchronisation',
      ongoing: 'Synchronisation en cours (cela peut prendre quelques minutes)',
      signupMessage:
        'Pour pouvoir synchroniser vos stocks, vous devez avoir un compte sur Offisante. Une fois celui-ci créé, contactez-nous pour que nous activions la synchronisation de vos stocks.',
      signupButton: 'Créer un compte Offisante',
      success: 'Synchronisation activée',
      error: 'Une erreur est survenue. Veuillez réessayer plus tard.',
    },
    seeRestockingSuggestions: 'Voir les suggestions de réassort',
    errors: {
      receiver_cannot_be_admin:
        "L'adresse email du destinataire de la commande ne doit pas être celle d'un administrateur de Faks",
    },
  },
  restocking: {
    pageTitle: 'Suggestions de réassort',
    seeRestocking: 'Voir ma commande',
    lowStockIn: 'Stock bas en',
  },
  trades: {
    pageTitle: 'Opérations commerciales',
    trade: 'Opération',
    mainInstruction: 'Instruction principale',
    additionalDescription: 'Description additionnelle',
    table: {
      search: 'Chercher une opération',
    },
    publish: "Publier l'opération",
    filesNeeded: 'Pièces justificatives',
    filesAttached: 'Pièces jointes',
    prioritary: 'Cette opération est marquée comme prioritaire par votre groupement',
    partner_lab: 'Cette opération est proposée par un laboratoire partenaire de Faks',
    labMessage: 'Information du laboratoire',
    mandateContract: 'Contrat de mandat',
    ugCompensation: 'Compensation en UG',
    accept_contract_1:
      'En cochant cette case, je confirme l’inscription à l’opération promotionnelle désignée dans les conditions particulières et accepte ',
    contract: 'le mandat',
    accept_contract_2: ' confié par le laboratoire.',
    emailSuccess: 'Votre adresse email a bien été modifiée',
    modal_email_title: 'Adresse email',
    modal_email_message:
      "Veuillez renseigner l'adresse email de la pharmacie afin de recevoir le contrat de mandat par email.",
    participate: 'Je participe',
    subscribeSuccess:
      'Bravo pour votre participation ! Suivez les instructions de votre groupement ou laboratoire.',
    unsubscribe: 'Se désinscrire',
    modal_unsubscribe_title: 'Me désinscrire',
    modal_unsubscribe_message: 'Les données remontées seront supprimées. Voulez-vous continuer ?',
    modal_unsubscribe_confirm: 'Me désinscrire',
    subscribed: 'Inscrit',
    uploads: 'Remontées',
    addFile: 'Ajouter un justificatif',
    addPdf: 'Ajouter un pdf',
    markAsDone: 'Marquer comme terminé',
    registrationSuccess: 'Les remontées ont été enregistrées',
    sendSuccess: 'Bravo, votre remontée a été envoyée avec succès',
    shouldFillSelloutWarning:
      'Votre remontée a été enregistrée. Attention il faut ajouter les informations de sell-out pour qu’elle soit envoyée.',
    shouldFillStripeWarning:
      'Votre remontée a été enregistrée. Veuillez remplir vos paramètres de paiement pour envoyer votre remontée au laboratoire et être payé.',
    registrationError: "Erreur d'enregistrement, veuillez réessayer",
    unsubscribeError: 'Une erreur est survenue, veuillez réessayer',
    productQuantity: 'Quantité de produits vendus',
    realisedSales: 'Chiffre d’affaires réalisé après remise (€ TTC)',
    ordersNotOpened: 'Les commandes pour cette opération ne sont pas ouvertes',
    requirement_dates: {
      between: 'À remplir entre le {{startDate}} et le {{endDate}}',
      from: 'À remplir à partir du {{startDate}}',
      before: "À remplir jusqu'au {{endDate}}",
      form: {
        between: 'Du {{startDate}}\nAu {{endDate}}',
        from: 'À partir du {{startDate}}',
        before: "Jusqu'au {{endDate}}",
      },
      extendedUntil: "Prolongé jusqu'au {{extendedUntil}}",
      suggestedDates:
        'Les dates suggérées pour ajouter cette preuve sont entre le {{startDate}} et le {{endDate}}',
    },
    requirementsKinds: {
      picture: 'MEA',
      sellout: 'Sellout',
    },
    compensation: {
      requirementCompensationMethod: {
        credit_note: 'en avoir',
        bank_transfer: 'par virement bancaire',
        gift_voucher: 'en chèque cadeau',
        free_units: 'en UG',
        faks_transfer: 'par virement Faks',
      },
      requirementCompensatedBy: {
        lab: 'par le laboratoire',
        group: 'par le groupement',
        sales_rep: 'par le délégué',
      },
      paid: 'payé',
    },
    productsWithCount: {
      count_one: '{{count}} Produit',
      count_other: '{{count}} Produits',
    },
    importProductsCsvModal: {
      title: 'Importer des produits',
    },
    seeOrEditItems: 'Voir/Modifier les données de vente',
    editMySellout: 'Modifier mes données de ventes',
    editMySelloutDescription:
      'Pour modifier vos données de ventes. Il faut ajouter un listing de vente au format PDF. En cliquant sur "Confirmer" vous allez supprimer les données de ventes de votre extracteur.',
    seeItems: 'Voir toutes les données de vente',
    turnoverWithTax: 'CA TTC',
    selloutErrors: {
      quantityMustBeFilledAndPositive: 'La quantité doit être spécifiée et positive',
      turnoverMustBeFilledAndPositive: 'Le CA doit être spécifié et positif',
    },
    selloutDataMustBeExact:
      'Veuillez renseigner les données de vente véridiques. Vos modifications peuvent être soumises à un contrôle par les équipes de Faks.',
    freemium_banner: {
      you_have_freemium:
        'Vous bénéficiez de la version gratuite permettant de voir et valider les remontées d’opérations.',
      you_have_none: 'Vous n’avez pas accès à la fonctionnalité des Opérations commerciales',
      premium_explanation: 'Ce que vous manquez dans la version Premium ?',
      premium_reason_1:
        'Boostez vos opérations commerciales en les diffusant à toutes nos pharmacies !',
      premium_reason_2:
        'Centralisez vos opérations sur un seul dashboard quelle que soit la source !',
      premium_reason_3: 'Pilotez le ROI de vos opérations !',
      appointment: 'Prendre RDV pour en savoir plus',
    },
    group: {
      trade_instance_refused_lab:
        'Le laboratoire a refusé cette remontée d’opération.\n<strong>Commentaire du laboratoire :</strong>\n{{message}}',
      trade_instance_refused:
        'Le groupement a refusé cette remontée d’opération.\n<strong>Commentaire du groupement :</strong>\n{{message}}',
      trade_instance_refused_pharmacy:
        'La pharmacy a refusé cette remontée d’opération.\n<strong>Commentaire de la pharmacie :</strong>\n{{message}}',
    },
    trade_instance_refused_lab: 'Le laboratoire a refusé votre remontée d’opération.',
    retry_possible: 'Vous pouvez recommencer.',
    trade_instance_refused_comment_lab: 'Commentaire du laboratoire :',
    trade_instance_refused: 'Le groupement a refusé votre remontée d’opération.',
    trade_instance_refused_comment: 'Commentaire du groupement :',
    sent_to_lab:
      'Les justificatifs de cette opération ont été envoyés au laboratoire. En attente de la validation du laboratoire.',
    validated_by_lab: "Le laboratoire a validé votre participation à l'opération.",
    compensation_message: 'Le montant attendu de paiement est de {{compensation}}€ TTC.',
    ug_compensation_message:
      'Le montant attendu de compensation est de <strong>{{compensation}} UG.</strong>',
    ug_compensated_outside_faks:
      'Le laboratoire a validé et compensé (hors Faks) votre participation à l’opération. Le montant de compensation est de <strong>{{compensation}} UG</strong>.',
    euros_compensated_outside_faks:
      'Le laboratoire a validé et compensé (hors Faks) votre participation à l’opération. Le montant de compensation est de <strong>{{compensation}} € TTC</strong>.',
    premiumVersion: 'Version premium',
    requirementOnlyDuringWindow:
      "Vous ne pouvez envoyer les justificatifs que pendant la durée de l'opération",
    refuseReasonModal: {
      chooseReason: 'Choisir une raison',
      reason: 'Raison',
      specifyReason: 'Précisez votre raison',
      pharmacyRefuseGroupTrade:
        'Précisez pourquoi cette opération ne vous intéresse pas, afin de permettre à votre groupement de mieux cibler les offres qui peuvent vous correspondre.',
      pharmacyRefuseLabTrade:
        'Précisez pourquoi cette opération ne vous intéresse pas, afin de permettre au laboratoire de mieux cibler les offres qui peuvent vous correspondre.',
    },
    tradeRefusedSuccess: "L'opération a bien été archivée",
    refuseReason: 'Raison de refus',
    notSpecified: 'Non spécifiée',
    notInterested: 'Je ne suis pas intéressé(e)',
    unarchived: 'Désarchiver',
    pharmacyRefusedReasons: {
      lab_or_range_not_referenced: 'Laboratoire ou gamme non référencé(e)',
      not_interested_in_products: 'Ces produits ne m’intéressent pas',
      order_not_received: 'Commande non reçue',
      plv_not_received: 'PLV non reçue',
      products_not_orderable:
        'Les produits de cette opération ne sont pas disponibles à la commande',
      other: 'Autre',
    },
    filtering: {
      byTags: {
        selected_zero: 'Catégorie(s)',
        selected_one: '{{count}} catégorie sélectionnée',
        selected_other: '{{count}} catégories sélectionnées',
      },
      byStatus: {
        selected_zero: 'Statut(s)',
        selected_one: '{{count}} statut sélectionné',
        selected_other: '{{count}} statuts sélectionnés',
        notFound: "Il n'y a pas de statut correspondant",
      },
    },
    comment: {
      groupOwned: 'Laissez un commentaire à votre groupement (facultatif)',
      yourMessage: 'Votre message',
    },
    payment: {
      seeInvoice: 'Voir la facture',
      noInvoice:
        'Faks ne génère pas de facture pour cette opération. Le laboratoire se charge de générer la pièce comptable',
      paymentMessage:
        'Bonne nouvelle ! Cette opération est <strong>payée directement via Faks</strong>.',
      getMyMoney: 'Recevoir mon argent',
      stripeWellSetup:
        'Cette opération est payée directement via Faks. Vos informations de paiement sont bien paramétrées.',
      paidMessage:
        'Le laboratoire a validé votre participation à l’opération et vous a payé via Faks.<br/><br/> Le montant du paiement est de <strong>{{compensationAmount}} TTC</strong>. Le paiement devrait arriver sur votre compte bancaire quelques jours après le <strong>{{date}}<strong>.',
    },
    knowMoreAboutPaymentByFaks: 'En savoir plus sur le  paiement via Faks',
    paymentMessage:
      'Bonne nouvelle ! Cette opération sera <strong>payée directement via Faks</strong>. Si votre remontée est validée, vous devrez remplir un <strong>formulaire (5min)</strong> avec vos informations de paiement et facturation afin de percevoir les prochains paiements via Faks.',
    lgoConnectionMessage:
      'Nouveau ! Remontez vos données de vente en 2 clics pour cette opération ✅',
    selloutRequirementValidated: '✅ Vos données de vente ont été vérifiées par Faks',
    activateAutomaticUpload: 'Activer la remontée automatique',
    usePanelistData: 'Utiliser les données de mon extracteur',
    panelistDataInformation:
      'Ces données de vente nous ont été partagées par votre extracteur. Pour envoyer ces données au laboratoire, cliquez sur "Valider".',
    lgoDataReceptionDate: 'Données récupérées de votre extracteur le',
    panelistSelloutRequestExpired:
      'Nous n’avons pas réussi à récupérer les données de vente via {{panelist}}. Merci d’ajouter votre listing de vente en format PDF.',
    panelistSelloutRequestErrorRetryableBanner: {
      summary:
        'Nous avons reçu une <strong>erreur</strong> lors de la récupération de vos données de vente. <strong>Vérifiez la procédure à suivre.</strong>',
      details:
        'Voici la dernière erreur reçue par votre extracteur: <strong>{{error}}</strong><br><br>Cliquez sur <strong>"Vérifier la connexion extracteur"</strong> pour suivre la procédure de connexion de votre extracteur à Faks.<br><br>Nous allons réessayer quotidiennement de récupérer vos données de vente jusqu’au <strong>{{date}}</strong>.<br>Passée cette date, vous devrez ajouter vous même un <strong>PDF</strong>.',
      cta: 'Vérifier la connexion extracteur',
    },
    article: 'cet article',
    toDoFromDate: 'À faire du',
    toDate: 'au',
    order: 'Commander',
    seeMyOrder: 'Voir ma commande',
    ordersDisabledModal: {
      title: 'Module commande désactivé',
      content: 'Merci de contacter Faks pour demander l’accès au module commande',
    },
    unarchiveSuccess: "L'opération a bien été désarchivée",
    createPosters: 'Créer des affiches',
    targeting: {
      group_all: 'Toutes les pharmacies du groupement',
      group_none: 'Aucune pharmacie du groupement',
      pharmacy_owner: 'Uniquement la pharmacie propriétaire de cette opération',
    },
    lgoDataUpload: {
      title:
        'Vos données de ventes sur cette opération vont être récupérées <strong>automatiquement</strong> depuis votre extracteur !',
      message:
        "Vos données de ventes sur cette opération vont être récupérées <strong>automatiquement</strong> depuis votre extracteur <strong>à partir du {{fromDate}}</strong>.<br/><br/>Une fois reçues sur Faks, vous devrez <strong>valider</strong> vos données de ventes avant de les <strong>transmettre</strong> au {{labOrGroupText}}.<br/><br/>Sans réponse de votre extracteur d'ici le <strong>{{untilDate}}</strong>, vous devrez ajouter vous-même un <strong>PDF</strong>.",
    },
  },
  labTrade: {
    percentage: 'Pourcentage %',
    euros: 'Euros €',
    compensationAmountInEuros: 'Compensation en euros',
    compensationPercentage: 'Compensation en pourcentage',
    ugThreshold: 'Palier (unités vendues)',
    ugQuantity: 'UG par palier atteint',
    legalDocumentToGenerateTypes: {
      none: 'Aucun',
      invoice: 'Facture',
    },
  },
  labTradeInstances: {
    exportSelloutItemFileName: 'donnees_de_vente_{{date}}.xlsx',
    table: {
      tabs: {
        all: 'Toutes',
        subscribed: 'En cours',
        toBeTreated: 'À traiter',
        validationByFaks: 'En validation par Faks',
        validated: 'Validées',
        refused: 'Refusées',
        compensated: 'Compensées',
      },
      filters: {
        allGroups: 'Tous',
        allTrades: 'Toutes',
      },
      toolbar: {
        group: 'Groupement',
        trade: 'Opération',
        search: 'Rechercher par pharmacie, CIP, etc...',
        selected: 'selectionné(s)',
        startDate: 'Date de début',
        endDate: 'Date de fin',
        inputTitle: 'Date des remontées',
        selectDateRange: 'Choisissez les dates de début et de fin des remontées',
        minimalCompensation: 'Compensation minimum',
      },
      sellout: 'SELL-OUT TTC',
      compensation: 'COMPENSATION',
      date: 'DATE DE REMONTÉE',
    },
    refuseModal: {
      title: 'Raison de refus',
      cancelButton: 'Annuler',
      refuseButton: 'Refuser',
    },
    participations: 'Exporter les participations',
    selloutData: 'Exporter les données de vente',
    selloutDataFromPanelist: 'Données de vente provenant du LGO.',
    selloutDataModifiable:
      'En cas de modification des données par la pharmacie, Faks effectue des contrôles des changements effectués.',
    acceptSuccess: 'Les remontées selectionnées ont bien été validées',
    refuseSuccess: 'La remontée a bien été refusée',
    updateSuccess: 'La remontée a bien été modifiée',
    markAsCompensatedOutsideFaksSuccess:
      "La remontée a bien été marquée comme 'Compensée hors Faks'",
    alreadyUpdated: 'A déjà été traitée',
    sales: 'ventes',
    revenue: 'CA',
    cancelRefusal: 'Annuler le refus',
    cancelValidation: 'Annuler la validation',
    markAsCompensatedOutsideFaks: 'Marquer comme "Compensé" (hors Faks)',
    to_receive: 'À recevoir',
    turnover_to_receive: 'CA: À recevoir',
    selected: 'sélectionnées',
    countSelected:
      'Les <strong>{{selectedCount}}</strong> remontées à traiter de cette page sont sélectionnées. ',
    selectAllPages: 'Sélectionner les {{totalCount}} remontées',
    filteredCountSelected:
      'Les <strong>{{totalCount}}</strong> remontées à traiter de la recherche sont sélectionnées.',
    unselectAllPages: 'Effacer la sélection',
    pharmacyStripeConnected: 'Le compte Stripe de la pharmacie est connecté',
    pharmacyStripeNotConnected: "Le compte Stripe de la pharmacie n'est pas connecté",
    validatedAt: 'Validé le {{date}}',
    paidAt: 'Payé via Faks le {{date}}',
    compensatedOutsideFaksAt: 'Compensé hors Faks le {{date}}',
    actionBy: 'par {{userInfo}}',
    connected: 'Connectés',
    exportWillBeSentByEmail:
      "Votre demande d’export sera envoyée à l'adresse email {{email}} dans les prochaines minutes.",
    emailModalForm: {
      emailNotStored:
        "L'adresse email ne sera pas enregistrée. Vous pouvez contacter le service client si vous souhaitez créer un compte.",
      fillEmail: "Renseignez l'adresse email à laquelle l'export sera envoyé",
    },
    cancelCompensatedOutsideFaksSuccess: 'La remontée est bien repassée au statut "Validée"',
    goBackToValidatedStatus: 'Repasser au statut "Validé"',
    compensateOutsideFaks: 'Compenser hors Faks',
    compensateOutsideFaksConfirm:
      'Êtes-vous sûr de vouloir passer les remontées filtrées ci-dessous au statut “Compensée hors Faks” ?\nPensez à bien vérifier les filtres utilisés.',
    externalSourceTooltip:
      'Cette participation a été intégrée dans Faks depuis la source externe {{sourceName}} le {{date}}.',
  },
  labDashboard: {
    newTrades: 'Nouvelles remontées à traîter',
    validatedTrades: 'Remontées validées',
    refusedTrades: 'Remontées refusées',
    viewTrades: 'Voir les remontées',
    newTradesPerWeek: 'Nouvelles remontées par semaine',
    trades: 'Remontées',
    selloutQuantities: 'Sell-out - Quantités',
    selloutSales: 'Sell-out - CA',
    enrollmentRate: 'Taux d’inscription',
    conversationRate: 'Taux de transformation',
    conversationRateDetails:
      'Le taux de transformation est calculé à partir des pharmacies inscrites',
    filters: {
      group: 'Groupement',
      trade: 'Opération',
      startDate: 'Date de début (remontées)',
      endDate: 'Date de fin (remontées)',
    },
  },
  labGroups: {
    firstInfoText: 'Vos partenariats groupement sont affichés sur votre page laboratoire.',
    searchforGroup: 'Trouver un groupement',
  },
  labPharmacy: {
    info: {
      title: 'Informations',
      noGroupYet: 'Pas encore de groupement',
      noUgaYet: "Pas encore d'UGA",
      noExternalMembership: "Pas d'ID interne",
      noCipYet: 'Pas encore de CIP',
      noAddress: "Pas d'adresse",
      noPhoneNumber: 'Pas de numéro de téléphone',
      stripe_ok: 'Compte Stripe bien connecté',
      stripe_ko: 'Compte Stripe non connecté',
    },
    actions: {
      title: 'Actions',
      seeClaims: 'Voir les réclamations',
      seeEprs: 'Voir les périmés',
      endAt: 'Fin à ',
      maxYearlyEpr: 'Maximum annuel de reprise de périmés : ',
    },
    discussionSettings: {
      noDelegate: 'Aucun délégué renseigné',
      noCustomerService: 'Non renseigné',
      franco: 'Franco',
      addFranco: 'Ajouter le franco',
      mainNote: 'Note Principale',
      return: 'Retour',
      save: 'Enregistrer',
      addDocument: 'Ajouter un document',
      francoUpdated: 'Le Franco a bien été mis à jour',
      mainNoteUpdated: 'La note principale a bien été mise à jour',
      documentImported: 'Le document a bien été enregistré',
      confirmDocumentDeletion: 'Êtes-vous sûr de vouloir supprimer ce document ?',
      documentSuccessfulyDeleted: 'Le document a bien été supprimé',
    },
  },
  participationStatus: {
    subscribed: 'Inscrit',
    validated_by_group: 'Validé par le groupement',
    awaiting_validation_by_group: 'En attente de validation par le groupement',
    refused_by_group: 'Refusé par le groupement',
    validated_by_lab: 'Validé par le laboratoire',
    refused_by_lab: 'Refusé par le laboratoire',
    awaiting_validation_by_lab: 'En attente de validation par le laboratoire',
    closed: 'Cloturé',
    refused_by_pharmacy: 'Archivé',
    paid: 'Payé',
    payment_processing: 'En cours de paiement',
    compensated_outside_faks: 'Compensé hors Faks',
  },
  tradeStatus: {
    closed: 'Clôturé',
    subscribed: 'Inscrit',
    awaiting_validation_by_group: 'A valider (groupement)',
    awaiting_validation_by_lab: 'A valider (labo)',
    awaiting_validation_by_faks: 'En validation par Faks',
    validated_by_group: 'Validé (groupement)',
    validated_by_lab: 'Validé (labo)',
    refused_by_group: 'Refusé (groupement)',
    refused_by_lab: 'Refusé (labo)',
    refused_by_pharmacy: 'Archivée',
    refused_by_pharmacy_from_group: 'Refusé (pharmacien)',
    compensated_outside_faks: 'Compensé hors Faks',
    paid: 'Payé via Faks 💰',
    payment_processing: 'Paiement en cours ⏳',
    not_accessible: 'Non visible',
  },
  tradeExtendedStatus: {
    not_subscribed: 'Non inscrit',
    ongoing: 'En cours',
    expired: 'Expiré',
    to_change: 'À refaire',
  },
  labTradeStatus: {
    subscribed: 'Inscrit',
    awaiting_validation_by_lab: 'À traiter',
    awaiting_validation_by_group: 'En validation group',
    awaiting_validation_by_faks: 'En validation par Faks',
    refused_by_lab: 'Refusé',
    validated_by_lab: 'Validé',
    paid: 'Payé',
  },
  tradeRequirementStatus: {
    actionToDo: 'Action à faire',
  },
  tradeGroupAgreement: {
    awaiting_group_decision: 'À traiter',
    refused: 'Hors accord groupement',
  },
  groupDashboard: {
    newRequestsPerWeek: 'Nouvelles demandes cette semaine',
    newInvoicesToday: 'Nouvelles demandes ce jour',
    pendingInvoices: '{{pendingInvoices}} réclamations visible en cours',
  },
  groupEditPage: {
    groupName: 'Nom de votre groupement',
    general: 'Général',
    team: 'Équipe',
    tags: 'Opérations',
    errors: {
      group_name_taken: 'Ce nom de groupement existe déjà',
    },
  },
  teamEditPage: {
    addAMember: 'Ajouter un membre',
    nameOwner: 'Nommer propriétaire',
    makeRegionalDirector: 'Rendre directeur régional',
    makeSaleseRep: 'Rendre délégué',
    makePharmacyOwner: 'Rendre titulaire',
    makePharmacist: 'Rendre pharmacien',
    makePharmacyOwnerUnverifiedEmail: 'Rendre à verifier par email',
    makePharmacyOwnerUnverifiedPhone: 'Rendre à rappeler',
    deleteConfirmation: `Attention ! Vous êtes sur le point de supprimer les droits d'accès de cet utilisateur. Continuer ?`,
    promoteConfirmation:
      'Attention ! Vous êtes sur le point de nommer cet utilisateur propriétaire de {{name}}. Vous ne serez plus le propriétaire et ne pourrez pas annuler cette action. Continuer ?',
    updateConfirmation: 'Changer le rôle de ce collaborateur ?',
    newMember: {
      createNewMember: 'Ajouter un collaborateur',
      email: 'Adresse email',
      name: 'Prénom',
      lastName: 'Nom',
      phoneNumber: 'Numéro de téléphone',
      salesRep: 'Utilisateur délégué',
      errors: {
        cantAddSalesRep: 'Vous ne pouvez pas inviter de délégué sur un laboratoire privé.',
        role_already_exists: 'Ce membre existe déjà',
        multiple_users_exist:
          "Ce numéro de téléphone et cet email correspondent à deux utilisateurs différents dans le sytème Faks. Veuillez contacter le support si vous pensez que c'est une erreur",
        admins_cant_have_roles: 'Un Administrateur Faks ne peut pas avoir de rôles',
      },
    },
    makeAdmin: 'Rendre administrateur',
    makeMember: 'Rendre membre',
    makeMemberWithNoAccess: 'Rendre délégué sans accès',
  },
  passwordChangePage: {
    newPassword: 'Nouveau mot de passe',
    confirmationPassword: 'Confirmation du mot de passe',
    actualPassword: 'Mot de passe actuel',
    newPasswordHelperText: 'laissez vide si vous ne voulez pas le changer',
    passwordMinimumCharacters: '6 caractères minimum',
    passwordHelperText:
      'Nous avons besoin de votre mot de passe actuel pour confirmer vos changements',
  },
  groupsNewsfeed: {
    addPublication: 'Ajouter une publication',
    likeButton: "Bouton J'aime",
    commentSection: 'Espace commentaire',
    sendNotification: 'Envoyer une notification',
    addDocument: 'Ajouter un document',
    deleteConfirmationMessage: 'Êtes-vous sûr de vouloir supprimer cette publication',
    sureToDelete: 'Êtes-vous sûr de vouloir supprimer cette publication ?',
    deleteSuccess: 'Le post a bien été supprimé',
    details: 'Détails',
    tabs: {
      all: 'Tous',
      views: 'Vues',
      likes: "J'aimes",
      clicks: 'Clics',
      comments: 'Commentaires',
    },
    comment: 'Commentaire',
    addComment: 'Ajouter un commentaire',
    commentSuccess: 'Le commentaire a bien été posté',
    commentAction: 'Commenter',
  },
  groupsPharmacies: {
    viewRequests: 'Voir les demandes',
    addPharmacy: 'Ajouter une pharmacie',
    manageLists: 'Gérer les listes',
    findAPharmacy: 'Trouver une pharmacie',
    confirmDelete: 'Retirer cette pharmacie de votre groupement ?',
    pharmacyRemoved: 'La pharmacie a bien été retirée du groupement',
    tableHead: {
      adh: 'Nº ADH.',
      address: 'ADRESSE',
    },
    addNewPharmacy: {
      pharmacyAdded: 'La pharmacie a bien été ajoutée',
      searchPharmacy: 'Chercher une pharmacie',
    },
    editPage: {
      addToList: 'Ajouter à la liste',
      updateSuccess: 'Pharmacie modifiée avec succès',
      pharmacyIdentifier: 'Identifiant pharmacie',
      codeAlreadyTaken: 'Ce code est déjà utilisé',
      posterSetting: {
        title: 'Activer les affiches',
        tooltipNonAdmin:
          'Veuillez contacter votre CSM Faks pour activer ou désactiver les Affiches pour vos adhérents',
        tooltipNone:
          'Le module Affiches n’est pas activé pour votre groupement. Contactez votre CSM Faks pour en savoir plus.',
        tooltipAll: 'Le module Affiches est activé pour tous les adhérents de votre groupement.',
      },
    },
    groupsPharmacyLists: {
      addNewPharmaciesLists: 'Ajouter une liste de pharmacies',
      findAPharmacyList: 'Trouver une liste de pharmacies',
      mainPageTitle: 'Les listes de pharmacies vous servent pour les fonctionnalités suivantes:',
      mainPageSubtitle1: `- Vous pouvez filter les pharmacies par liste dans l'onglet "Pharmacies"`,
      mainPageSubtitle2: `- Si vous avez la fonctionnalité "Opérations", vous pouvez définir des opérations à
      destination de certaines pharmacies`,
      tableHead: {
        description: 'DESCRIPTION',
      },
      pharmacyListPage: {
        newPharmacyList: 'Nouvelle liste de pharmacies',
        modifyPharmacyList: 'Modifier la liste de pharmacies',
        tableHead: {
          pharmacistName: 'TITULAIRE',
        },
      },
      deletePharmacyList: 'Supprimer la liste de pharmacies',
      confirmationToDelete: 'Êtes vous sûr de vouloir supprimer la liste de pharmacies ?',
      importList: 'Importer une liste',
      listUsedInTradeError:
        "Vous ne pouvez pas supprimer cette liste car elle est utilisée pour le ciblage d'une opération. Veuillez d'abord modifier vos opérations.",
    },
  },
  groupsClaims: {
    pageTitle: 'Réclamations',
    tabs: {
      all: 'Toutes',
      closed_by_pharmacy: 'Clôturées',
      closed_by_lab: 'Résolues',
      pending: 'En cours',
      transmitted: 'Transmises',
      published: 'Publiées',
    },
    search: 'Chercher une réclamation',
  },
  groupsLabs: {
    editPartnership: 'Éditer le partenariat',
    seeInvoices: 'Voir les demandes',
    findALab: 'Trouver un laboratoire',
    tableHeader: {
      resolutionTime: 'TEMPS DE RÉSOLUTION',
    },
    labEditPage: {
      pageSubtitle: 'Éditer le partenariat',
      tableHeadTitle: 'TITRE DU DOCUMENT',
      tableHeadFile: 'FICHIER',
      secondSwitchTitle: 'Demandes visibles',
      secondSwitchInfoText: `- Le laboratoire autorise le groupement a voir les conversations et y publier des messages`,
      thirdSwitchTitle: 'Demandes visibles pour le groupement',
      thirdSwitchInfoText: `- Le groupement peut voir les conversations et y publier des messages`,
      updateSuccessful: 'Vos modifications ont bien été prises en compte',
    },
  },
  labs: {
    orders: {
      editPage: {
        seeDiscussion: 'Voir la discussion',
        submit: 'Enregistrer les modifications',
      },
      discussionPage: {
        editOrder: 'Modifier la commande',
      },
      tableHeaders: {
        ean: 'Code EAN',
        product: 'Produit',
        createdAt: 'Date de création',
        quantity: 'Quantité',
        normalPrice: "Prix d'achat",
        discountedPrice: 'Prix remisé',
      },
      tableFilters: {
        origin: 'Source',
        createdAt: 'Date de création',
        refusedAt: 'Date de refus',
        confirmedAt: 'Date de validation',
      },
      createProduct: 'Ajouter un produit',
      edit: 'Modifier',
      editProduct: 'Modifier un produit',
      productForm: {
        ean: 'Code EAN',
        name: 'Nom du produit',
        packing_quantity: 'Colisage',
        normalPrice: "Prix d'achat",
        discountedPrice: 'Prix remisé',
      },
      refusalModal: {
        title: 'Ajoutez un commentaire au pharmacien',
        subTitle: 'Expliquez au pharmacien la raison pour laquelle vous refusez sa commande.',
        placeholder: 'Explications au pharmacien',
      },
      list: {
        tabs: {
          to_handle: 'À traiter',
          confirmed: 'Validées',
          refused: 'Refusées',
          cancelled: 'Annulées',
          all: 'Toutes',
        },
        columns: {
          id: 'Id',
          pharmacy: 'Pharmacie',
          createdAt: 'Créée le',
          confirmedAt: 'Validée le',
          refusedAt: 'Refusée le',
          document: 'Bon de commande',
          total: 'Total',
          status: 'Statut',
        },
        actions: {
          success: 'Votre action a bien été prise en compte',
          error: 'Une erreur est survenue',
        },
      },
    },
  },
  groupsTrades: {
    exportSelloutItemsFileName: 'donnees_de_vente_{{date}}_trade_{{trade_id}}.xlsx',
    selloutData: 'Données de vente',
    totalTradeData: 'Données totales de l’opération',
    exportFile: 'Exporter les informations',
    validatedRequirementInstances: 'Pièces justificatives validées',
    createNewTrade: 'Ajouter une opération',
    messageOnEditPageIfTradeLinkedToLabTrade: {
      title: "Cette opération est liée à une opération d'un laboratoire",
      description:
        'Ce laboratoire gère son plan trade directement sur Faks. Vous pouvez contacter le laboratoire ou l’équipe Faks pour modifier ou mettre en place une opération avec ce laboratoire.',
    },
    endTrade: "Terminer l'opération",
    confirmModal: {
      title: 'Valider toutes les remontées',
      description: {
        instance_one: 'Voulez-vous valider la remontée ?',
        instance_other: 'Voulez-vous valider les {{count}} remontées ?',
      },
    },
    toastSuccessValidateAllTrades: 'Toutes les remontées ont été validées ! 👍🏼',
    toastSuccessValidateTradeInstance: 'Remontée validée',
    toastSuccessRefuseTradeInstance: 'Remontée refusée',
    errors: {
      productNameIsRequired: 'Un nom de produit doit être renseigné',
      eanIsRequired: "L'EAN doit être renseigné",
      not_13_numbers_in_ean: "L'EAN doit comporter 13 chiffres !",
      atLeastOneRequirementIsRequired:
        'Vous devez ajouter au moins une demande de preuve pour ce mode de transmission.',
    },
    findATrade: 'Trouver une opération',
    statusOptions: {
      over: 'Passées',
      ongoing: 'En cours',
      upcoming: 'À venir',
      awaiting_group_decision: 'Nouvelles opérations à inclure',
    },
    tableHead: {
      lab: 'LAB',
      trade: 'OPERATION',
      kind: 'TRANSMISSION',
      visible: 'VISIBILITÉ',
      fullDate: 'DATES',
      participations: 'PARTICIPATIONS',
      action: 'ACTION',
      product: 'PRODUIT',
      information_for_pharmacist: 'INFORMATION COMPLEMENTAIRE',
      ean: 'EAN',
      vat: 'TVA',
      sell_in_purchase_price_in_euro: 'PRIX D’ACHAT',
      sell_in_discount_price_in_euro: 'PRIX REMISÉ',
      sell_in_purchase_price_in_euro_cents: 'PRIX D’ACHAT EN CENTIMES',
      sell_in_discount_price_in_euro_cents: 'PRIX REMISÉ EN CENTIMES',
      packing_quantity: 'COLISAGE',
    },
    tradeKinds: {
      group_owned: 'Par le groupement',
      direct: 'Direct labo',
      platform: `Sur l'espace pro`,
      generic: 'Generic',
      order: 'Via commande',
      prevalidate: 'Pré-validation groupement avant transmission au labo dans Faks',
    },
    labInfo: {
      transmission: 'Transmission',
      visibility: 'Visibilité',
      hasGroupPriority: 'Opération prioritaire',
    },
    subscriptions: 'Participations',
    participationTabs: {
      all: 'Toutes',
      subscribed: 'Inscrits',
      not_subscribed: 'Non inscrits',
      awaiting_validation: 'En attente de validation (group)',
      awaiting_validation_by_lab: 'En attente de validation (labo)',
      validated: 'Validés',
      compensated: 'Compensés',
      refused_by_group_or_lab: 'Refusés',
      refused_by_pharmacy: 'Refusés par le pharmacien',
    },
    refusalModal: {
      title: 'Ajoutez un commentaire au pharmacien',
      subtitle:
        "Expliquez au pharmacien la raison pour laquelle vous refusez sa remontée d'opération, ainsi qu'une piste afin qu'il puisse recommencer si vous le souhaitez. Vous pourrez clôturer l'opération sans attendre sa nouvelle remontée.",
    },
    addProductsWarning:
      'Veuillez ajouter des produits afin que la remontée automatique des données de vente fonctionne sur cette opération.',
    selloutDataNotAutomaticallyUploaded:
      'La remontée automatique des données de vente ne sera pas activée sur cette opération car vous n’avez pas ajouté de produits. Êtes-vous sûr de vouloir continuer ?',
    productModal: {
      modifyProduct: 'Modifier un produit',
      productName: 'Nom du produit',
    },
    markAsOutOfGroupAgreement: 'Marquer comme "Hors accord groupement"',
    markAsOutOfGroupAgreementSuccess:
      'L\'opération a bien été marquée en "Hors accord groupement".',
    removeOutOfGroupAgreementTag: 'Retirer le tag "Hors accord groupement"',
    removeOutOfGroupAgreementTagSuccess: 'Le tag "Hors accord groupement" a bien été retiré.',
    markAsRefused: 'Exclure',
    markAsRefusedSuccess:
      "L'opération a bien été exclue. Elle ne sera pas visible de vos pharmacies dans l'onglet opérations.",
    markAsAgreed: 'Inclure',
    markAsAgreedSuccess:
      "L'opération a bien été inclue. Elle sera visible de vos pharmacies dans l'onglet opérations.",
    newTrade: {
      kind: 'Mode de transmission de la remontée',
      pharmacistInstructions: 'Instructions pharmacien',
      tradeVisible: 'Opération visible',
      tradeHasGroupPriority: 'Opération prioritaire',
      tradeHasGroupPriorityInfo:
        'Permet d’afficher l’opération en haut de la liste des opérations dans le calendrier des pharmaciens.',
      deleteJustification: 'Supprimer la pièce justificative',
      tradeRequirement: 'Pièce justificative',
      tags: 'Catégorie(s)',
      importCsv: 'Importer un CSV',
      selectedTags: {
        selected_zero: 'Catégorie(s)',
        selected_one: '{{count}} catégorie sélectionnée',
        selected_other: '{{count}} catégories sélectionnées',
      },
      noTagFound: 'Aucune catégorie trouvée pour cette recherche',
      messageIfLabTradeClient: {
        title: 'Le laboratoire sélectionné est client chez Faks',
        description:
          'Ce laboratoire gère son plan trade directement sur Faks. Vous ne pouvez pas choisir le mode de transmission. Contactez le laboratoire ou l’équipe Faks pour mettre en place, si nécessaire, un autre mode de transmission.',
      },
      addProduct: 'Ajouter un produit',
      justifications: 'Justificatifs',
      enableOrder: 'Activer une commande liée à cette opération commerciale',
      enableOrderInfo:
        'Les pharmacies devront effectuer une commande pour participer à l’opération',
      compensation: 'Compensation',
      compensationInfo:
        'La mécanique de compensation est modifiable tant qu’aucune participation n’a été validée. Si au moins une participation a été validée, la mécanique de compensation n’est plus modifiable.',
      requirements: {
        kind: {
          picture: 'MISE EN AVANT',
          sellout: 'SELL-OUT',
          confirmation: 'CONFIRMATION',
          order: 'COMMANDE',
        },
        tableHead: {
          kind: 'TYPE',
          title: 'TITRE',
          limitDates: 'DATES LIMITES',
          compensationType: 'MECANIQUE',
          additionalInformation: 'INFORMATIONS ADDITIONNELLES',
        },
      },
      canChooseOtherKindIfAdmin:
        "Un administrateur Faks peut forcer le mode de transmission à l'aide de ce toggle :",
      addProductsErrors: {
        existingEans: 'Plusieurs produits ont les même codes EAN : {{eans}}',
        existingEan: 'Un produit a déjà été ajouté avec le code EAN {{ean}}',
        wrongEanFormat: 'Un ou plusieurs produits ont un code EAN invalide : {{eans}}',
        missingProductName: 'Le nom du produit est obligatoire',
        missingVat: 'La TVA est obligatoire',
        wrongVatRateValues: 'Erreur dans les taux de TVA',
      },
      errors: {
        titleLength: 'Le titre de l’opération doit contenir entre 3 et 70 caractères',
      },
    },
    pharmacistComment: 'Commentaire du Pharmacien :',
    deleteTradeConfirmation:
      'Êtes-vous sûr de vouloir supprimer cette opération ? Cette action est irréversible.',
    duplicateSuccess: "L'opération a bien été dupliquée",
    additionalDescription: 'Description additionnelle',
    finishedAt: 'Date de remontée : {{date}}',
    markAllAsCompensated: 'Tout marquer comme compensé',
    markAllAsCompensatedSuccess: 'Toutes les remontées ont été marquées comme compensées.',
    markAsCompensated: 'Marquer comme compensé',
    markAsCompensatedSuccess: 'La remontée a bien été marquée comme compensée.',
    markAllAsCompensatedConfirmModal: {
      title: 'Marquer comme compensées toutes les remontées',
      description: {
        participation_one: 'Voulez-vous marquer comme compensée la remontée ?',
        participation_other: 'Voulez-vous marquer comme compensées les {{count}} remontées ?',
      },
    },
    goBackToValidatedStatus: 'Repasser au statut "Validé"',
    cancelCompensatedSuccess: 'La remontée est bien repassée au status "Validé"',
    cancelRefusal: 'Annuler le refus',
    cancelValidation: 'Annuler la validation',
    cancelValidationSuccess: 'La validation a bien été annulée',
    cancelRefusalSuccess: 'Le refus a bien été annulé',
  },
  groupsSettings: {
    trades: {
      editTags: {
        title: "Gérer mes catégories d'opérations",
        description:
          'Créez des catégories d’opérations pour aider vos adhérents à piloter leurs opérations et pour vous aider à analyser l’activité. Vous pourrez ensuite ajouter une ou plusieurs catégories sur chaque opération.',
        enterTip: "Appuyer sur 'Entrer' pour valider",
        inputPlaceholder: 'Nom de la catégorie',
        addTag: 'Ajouter la catégorie',
        existingTags: 'Mes catégories déjà créées :',
        alreadyExist: 'La catégorie existe déjà',
        deleteModal: {
          title: 'Supprimer la catégorie ?',
          description: 'Cette catégorie sera supprimée de toutes les opérations. Confirmer ?',
        },
      },
    },
  },
  crmSettingForm: {
    edit: 'Modifier les informations d’intégration',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
    instanceUrl: 'Instance URL',
    apiVersion: 'API Version',
    success: 'Les informations ont bien été mises à jour',
    connected: 'Connecté',
    notConnected: 'Non connecté. Éditez vos informations (Client ID & Client Secret) et réessayez.',
  },
  claims: {
    pageTitle: 'Réclamations',
    claim: 'Réclamation',
    table: {
      search: 'Chercher une réclamation',
      claim: 'Réclamation',
      message: 'DERNIER MESSAGE',
      tabs: {
        inProgess: 'En cours',
        closed: 'Clôturées',
      },
    },
    newClaim: 'Nouvelle réclamation',
    form: {
      instructions:
        'Sélectionnez un laboratoire et décrivez votre demande en ajoutant des photos. Vous pourrez ensuite échanger avec le laboratoire',
      logistician: 'Logisticien',
      lab: 'Laboratoire',
      topic: {
        label: 'Motif',
        pharmacistInstructions: 'Consignes du laboratoire',
      },
      reliabilityBanner: {
        reliable: '{{labName}} traite les réclamations dans Faks',
        not_reliable: '{{labName}} traite les réclamations dans Faks mais avec un certain délai',
        not_enough_data:
          '{{labName}} a très peu de réclamations dans Faks, nous manquons de données pour connaître son niveau de fiabilité',
      },
      message: 'Message',
      messageDefaultValue: "Bonjour,\n\n...,\n\nMerci d'avance,\nBonne journée",
      photos: 'Photo(s)',
      photosInfo: 'Ajoutez des photos de facture, bon de livraison, produits, etc',
      addPhoto: 'Ajouter une photo',
      notifyLabUser: 'Suivi de mon délégué',
      picture_banner:
        '<strong>Ajoutez</strong> des photos de votre <strong>bon de livraison ou facture</strong> et de votre <strong>produit.</strong> En effet, une photo de votre BL ou facture permet une clôture <strong>5x plus rapide</strong> de votre réclamation.',
      blocked_banner:
        '<strong>Info : </strong>Pour le moment, ce laboratoire traite vos réclamations par téléphone. Vous pouvez le joindre au numéro affiché ci-dessous :',
      errors: {
        labRequired: 'Vous devez choisir un labo',
        messageRequired: 'Le message ne peut pas être vide',
        messageCannotBeDefault: 'Veuillez changer le message par défaut',
        topicRequired: 'Vous devez choisir un motif pour ce laboratoire',
        photosRequired: 'Vous devez envoyer au moins un photo',
      },
      cantFindLab: "Le laboratoire que vous cherchez n'est pas présent ?",
      contactUs: 'Contactez-nous',
    },
    copyExternalId: "Copier l'identifiant",
    copyExternalIdSuccess: "L'identifiant a bien été copié dans le presse papier",
  },
  claimChat: {
    closeClaim: 'Clôturer la réclamation',
    closeClaimDescription: 'Vous pourrez rouvrir cette réclamation en envoyant un message',
    deleteClaim: 'Supprimer',
    deleteSuccess: 'La réclamation a bien été supprimée',
    labInformation: 'Informations Laboratoire',
    claimInformation: 'Informations Réclamation',
    faksId: 'ID Faks',
    assignedLab: 'Logisticien',
    claimTopic: 'Motif',
    faksNotes: 'Notes Faks',
    claimRelaunchPlan: 'Plan de relance',
    adminMailReminderMessagesCount: 'Nombre de relances effectuées : ',
    snoozeClaim: 'Snooze la réclamation jusqu’au (non inclus)',
    snooze: 'Snooze',
    unsnooze: 'Unsnooze',
  },
  claimStatus: {
    published: 'Publié',
    transmitted: 'Transmis',
    pending: 'En cours',
    closed_by_lab: 'Résolu',
    closed_by_pharmacy: 'Clôturé',
    deleted: 'Supprimé',
  },
  expiredProductsRequestsStatus: {
    ongoing: 'En cours',
    awaiting_signing: 'En attente de signature',
    signed_by_pharmacy: 'Signée par la pharmacie',
    signed_by_point_of_sale: 'Signée par le point de vente',
    signed: 'Signée',
    validated_by_lab: 'Validée',
    closed_by_pharmacy: 'Clôturée',
    refused_by_lab: 'Refusée',
    draft: 'Brouillon',
    to_sign: 'À signer',
    deleted: 'Supprimée',
  },
  pharmacyExpiredProducts: {
    expiredProduct: 'Périmé',
    close: 'Clôturer la demande',
    closeDescription: 'La remontée de périmés va être cloturée.',
    sign: 'Signer la demande',
    rppsLabel: 'RPPS',
    addRpps: 'Ajoutez votre identifiant RPPS',
    addRppsDescription:
      'Avant de générer le certificat de destruction, nous avons besoin de valider votre identifiant RPPS.',
    addRppsWarning:
      'Note: Il est important de renseigner votre numero RPPS exact. Un identifiant RPPS erronné pourra entrainer le refus de la reprise.',
    generatingDestructionCertificate: 'Certificat de destruction en cours de génération',
    rppsErrorValue: "L'identifiant RPPS doit comporter 11 chiffres.",
    errorDestructionRole: 'Seul un administrateur peut générer un certificat de destruction.',
    procedureGenerationFailed:
      'La génération du certificat de destruction a échoué car {{message}}',
    warning_modal: {
      need_admin_pharmacist: {
        title: 'Signature par un administrateur',
        message:
          'Vous devez être pharmacien et administrateur de la pharmacie pour pouvoir signer le bon de destruction. (Votre numero RPPS vous sera demandé)',
        cta: 'Renseigner votre RPPS',
      },
      need_pharmacy_email: {
        title: 'Email requis',
        message:
          'Afin de recevoir et signer le certificat de destruction pour cette remontée de périmés, veuillez renseigner l’email de votre pharmacie.',
        cta: 'Renseigner un email',
      },
      need_user_name: {
        title: 'Nom et prénom requis',
        message:
          'Afin de signer le certificat de destruction pour cette remontée de périmés, veuillez renseigner votre nom et prénom.',
        infobox:
          'Note: Il est important de renseigner votre nom et prénom exact. Une mauvaise information du signataire pourra entrainer le refus de la reprise.',
        cta: 'Renseigner votre nom',
      },
    },
  },
  notFound: {
    noResult: 'Aucun résultat',
    noResultFor: 'Aucun résultat trouvé pour',
    checkTypos: "Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.",
    yourSearch: 'votre recherche',
  },
  page404: {
    pageTitle: '404 Page non trouvée',
    pageSubtitle:
      "Désolé, nous n'avons pas trouvé la page que vous recherchez. Assurez-vous de vérifier l'orthographe de l'URL.",
  },
  page403: {
    pageTitle: 'Accès non autorisé',
    pageSubtitle:
      "La page à laquelle vous essayez d'accéder est restreinte. Veuillez vous référer à votre administrateur système.",
  },
  page498: {
    pageTitle: 'Ce lien est expiré',
    pageSubtitle: "Veuillez contacter l'équipe Faks à l'adresse csm-labo@faks.co",
  },
  onboarding: {
    pageTitle: 'Pas de rôles',
    pageSubtitle:
      "Votre compte ne possède aucun rôle, vous ne pouvez donc pas accéder au site. Veuillez suivre l'onboarding sur l'application mobile pour en créer un.",
    who_are_you: {
      point_of_sale_or_other_establishment: 'Point de vente / Autre établissement',
      pharmacist: 'Pharmacien',
      lab_rep: 'Délégué pharmaceutique',
      title: 'Qui êtes-vous ?',
    },
    unavailable: {
      downloadFaksOnPhone: 'Téléchargez Faks sur votre téléphone',
      message:
        'Merci pour votre interêt.\n\nTéléchargez Faks sur votre téléphone.\nFaks est disponible sur l’apple store et sur google play.',
      iosLink: 'Lien Ios :',
      androidLink: 'Lien Android :',
    },
    createProfile: {
      title: 'Créez votre profil',
      description:
        'Ces informations vous permettront d’échanger avec les laboratoires pharmaceutiques dans Faks.',
      firstName: 'Prénom',
      lastName: 'Nom',
      invalidFirstName: 'Prénom invalide',
      invalidLastName: 'Nom invalide',
    },
    verifyEmail: {
      title: 'Verifiez votre email',
      description:
        'Pour vérifier votre adresse email, veuillez cliquer sur le lien de confirmation dans le mail envoyé à l’adresse <bold>{{email}}</bold>.',
      notRecieved: 'Email non reçu ?',
      openEmailApp: "Ouvrir l'application d'emails",
      descriptionSpam:
        'Pour vérifier votre adresse email, veuillez cliquer sur le lien de confirmation dans le mail envoyé à l’adresse <bold>{{email}}</bold> (pensez à regarder dans vos courriers indésirables).',
      sendEmailBack: 'Renvoyer l’email',
      emailSent: 'Email de confirmation envoyé',
      emailResentDescription:
        'Nous vous avons renvoyé un email de confirmation à l’adresse <bold>{{email}}</bold>. Veuillez cliquer sur le lien de confirmation pour activer votre compte.',
      accountActivation: 'Activation du compte',
      verifyEmailBefore:
        'Pour que votre compte soit activé, vous devrez d’abord vérifier votre adresse email.',
    },
    verifyAccount: {
      title: 'Vérifier votre compte',
      description:
        'Afin de vérifier votre compte, vous pouvez appeler dès maintenant l’équipe Faks (gratuitement).',
      callFaks: 'Appeler Faks',
      callFaksNumber: 'Appeler Faks au {{phoneNumber}}',
      gotVerified: 'J’ai vérifié mon compte',
      accountActivation: 'Activation du compte',
      verificationRequired: 'Merci d’appeler Faks pour vérifier votre compte',
    },
  },
  onboarder: {
    pharmacies: {
      onboarderPharmacies: {
        findAPharmacy: '+33 X XX ..., CIP, Nom, Adresse',
        banner:
          'Attention ! Le numéro de téléphone doit être sous le format suivant: +33 X XX XX XX XX.',
      },
      onboarderPharmacy: {
        name: 'Nom',
        phoneNumber: 'Tel. fixe',
        email: 'Adresse email',
        cip: 'Code CIP',
        addressLine1: 'Adresse',
        addressZipCode: 'Code postal',
        addressCity: 'Ville',
        groupName: 'Nom du groupement',
        groupNameHelperText: 'Groupement déclaré par le pharmacien',
        admin: 'Titulaire',
        stripStatus: 'Statut du compte Stripe',
        stripeAccountStatusOptions: {
          none: 'Aucun',
          can_create_account: 'A configurer',
          ok: 'Validé',
          incomplete: 'Incomplet',
        },
        verified: 'Vérifié',
        comment: 'Commentaire',
        editPharmacy: 'Editer la pharmacie',
        verifiedAt: 'Vérifié le {{date}}',
        users: 'Utilisateurs',
        trades: 'Opérations',
        claims: '{{invoicesCount}} Réclamations',
        from_faks: 'la pharmacie',
        from_lab: 'le laboratoire',
        from_group: 'le groupement',
        createdAt: 'Créé par {{origin}} le {{date}}',
        expiredProductsRequests: '{{eprCount}} Remontées de périmés',
        signature: 'Signataire : {{signature}}',
      },
    },
  },
  tradeList: {
    centralizeCommercialOperations:
      'Centralisez toutes vos opérations commerciales sur Faks. Pour en savoir plus, contactez le support Faks.',
    tradesNotAvailable:
      'Les opérations commerciales ne sont pas disponibles dans votre groupement. Pour en savoir plus, contactez le support Faks.',
    noTradeInvalidSearch: 'Aucune opération ne correspond à vos paramètres de recherche',
    noTradeThisMonth: 'Aucune opération ce mois-ci',
    createSelectedPosters: 'Créer les affiches sélectionnées',
    createMyPosters: 'Créer mes propres affiches',
  },
  labsPharmacies: {
    updateConfirmation: 'Changer le délégué associé à ce pharmacien ?',
    exportAvailableForClients: "Disponible avec l'offre premium",
    createSuccess: 'La pharmacie a bien été enregistrée',
    addNewPharmacy: {
      pharmacyExists: 'Cette pharmacie existe déjà',
    },
    editModal: {
      title: 'Editer la pharmacie {{pharmacyName}}',
      externalMembershipId: 'Identifiant pharmacie',
      codeAlreadyTaken: 'Ce code est déjà utilisé',
    },
    labUserUpdatePhoneError:
      'Le numéro de téléphone est déjà assigné à un utilisateur avec un email différent.',
    labUserUpdateSuccess: 'Votre délégué a bien été mis à jour',
  },
  emailLogin: {
    welcomeOnFaks: 'Bienvenue sur Faks !',
    useLoginDetails:
      'Vous faites partie d’un laboratoire ou d’un groupement ? Vous pouvez utiliser vos identifiants Faks pour vous connecter.',
    noAccount: 'Vous n’avez pas encore de compte ?',
  },
  verifyEmail: {
    yourePharmacist: 'Vous êtes un pharmacien, cliquez ici :',
  },
  invitation: {
    youAre: 'Vous êtes ?',
    userTypeRequired: "Vous devez choisir un type d'utilisateur",
    askForInvitation: 'Demander une invitation',
    askForInvitationDescription:
      'Vous êtes un laboratoire, un grossiste, un logisticien ou un groupement de pharmacies ? Nous vous recontacterons au plus vite pour vous partager vos accès à Faks.',
    invitationSent: 'Votre demande d’invitation a été envoyée.',
    accountExist: 'Un compte existe déjà avec cette adresse email.',
    yourCompany: 'Votre entreprise',
    enterYourCompany: 'Entrez votre entreprise',
    yourPosition: 'Votre rôle/poste',
    enterYourPosition: 'Entrez votre rôle/position',
  },
  adminDashboard: {
    newInvoices: 'Nouvelles demandes',
    pendingInvoices: 'Demandes en cours',
    closedByLabInvoices: 'Demandes résolues',
    closedByPharmacyInvoices: 'Demandes clôturées',
    newInvoicesThisWeek: 'Nouvelles demandes cette semaine',
    verifiedPharmacies: 'Pharmacies vérifiées',
    retention: 'Des pharmacies vérifiées ont publié une demande ces deux dernières semaines',
    labsAvailable: 'Laboratoires référencés',
    olderInvoice: 'DEMANDE LA PLUS ANCIENNE',
    resolvedInvoices: 'Demandes résolues de manière autonome',
    newPharmaciesThisWeek: 'NOUVELLES PHARMACIES CETTE SEMAINE',
    newLabsThisWeek: 'NOUVEAUX LABOS CETTE SEMAINE',
    resolvedAll: 'Résolues (toutes)',
    resolvedByAdmin: "Résolues avec l'aide d'un admin",
    addressedTo: 'Adressée à',
    noClaim: 'Aucune demande',
  },
  guideItems: {
    createPage: 'Ajouter une page',
    pageInfo: 'Votre FAQ sera affichée dans votre page laboratoire.',
    newGuideItem: { pageTitle: 'FAQ - Nouvelle page' },
    editGuideItem: {
      pageTitle: 'FAQ - Éditer la page',
      deleteGuideItem: 'Supprimer cette page de manière définitive ?',
    },
  },
  labClaims: {
    dashboard: {
      transmittedClaims: 'Nouvelles demandes',
      pendingClaims: 'Demandes en cours',
      closedByLabClaims: 'Demandes résolues',
      closedByPharmacyClaims: 'Demandes clôturées',
      search: 'Numéro de téléphone de la pharmacie',
      newRequestsPerWeek: 'Nouvelles demandes cette semaine',
      responseTime: 'Temps de réponse moyen',
      resolvedTime: 'Temps de traitement moyen',
      sponsoredPharmacies: 'Pharmacies sponsorisées',
      olderClaim: 'Demande la plus ancienne',
      averageRating: 'Note moyenne',
      from: 'De',
    },
    tabs: {
      transmitted: 'Nouvelles',
      closed_by_pharmacy: 'Clôturées',
      closed_by_lab: 'Résolues',
      pending: 'En cours',
      all: 'Toutes',
    },
    searchForClaim: 'Trouver une réclamation',
    reassignClaims: 'Réassigner les réclamations',
    exportAvailableForClients: "Disponible avec l'offre premium",
    updateConfirmation: 'Changer le responsable de cette demande ?',
    invoiceClosedByLab: 'Vous pouvez rouvrir cette demande si celle-ci a été clôturée par erreur',
    closeInvoice: 'Marquer cette demande comme résolue pour ne pas recevoir de relance.',
    resolveInvoice: 'Marquer comme résolue',
    reopenInvoice: 'Rouvrir cette demande',
    shareInvoice:
      "Partagez cette réclamation afin de permettre à d'autres personnes de la consulter et de répondre au pharmacien",
    reassignClaimsModal: {
      title: 'Réassigner les réclamations',
      info: 'Vous allez réassigner de manière définitive toutes les réclamations passées et en cours d’un utilisateur vers un autre',
      reassignedFrom: 'Réclamations assignées à',
      reassignedTo: 'Nouveau responsable:',
    },
    shareInvoiceModal: {
      title: "Transmettre à d'autres personnes ou services",
      defaultMessage:
        'Bonjour,\n\nJe vous transmets une demande de {{pharmacyName}}.\n\nCordialement,\n{{userName}}\n{{labName}}\n',
      successMessage: 'E-mail envoyé avec succès.',
    },
    newClaim: {
      verifiedUser: "Pharmacie vérifiée - utilisatrice de l'application",
      photo: 'Pièce jointe',
      saveClaim: 'Enregistrer la réclamation',
      groupName: 'Groupement de la pharmacie',
    },
    updateClaimTopicConfirmation: 'Modifier le motif de la demande ?',
    filters: {
      date: 'Dates de création',
      selectDateRange: 'Choisissez les dates de début et de fin',
      validationDate: 'Dates de validation',
    },
    linkNotValid: "Le code d'accès à la réclamation dans l'URL est manquant ou erronné",
  },
  labEditPage: {
    labName: 'Nom de votre labo',
    labDetails: 'Présentation de votre laboratoire',
    labDetailsInfo: 'Affiché sur votre page laboratoire, en dessous du bouton "Nouvelle demande"',
    supportUser: 'Service client - contact principal',
    reportingUser: 'Rapport hebdomadaire destiné à',
    reportingUserInfo: 'Par défaut envoyé au service client',
    supportPhone: 'Téléphone du service client',
    errors: {
      lab_name_taken: 'Ce nom de laboratoire existe déjà',
    },
  },
  labExpiredProducts: {
    dashboard: {
      newExpiredProducts: 'Nouvelles remontées',
      productsAwaitingSigning: 'En attente de signatures',
      productsSignedByPharmacy: 'Signées',
      paidForProducts: 'Validées',
      resolutionDelay: 'Temps de traitement moyen',
      newExpiredProductsThisWeek: 'Nouvelles remontées de périmés cette semaine',
      numberOfDistinctProducts: 'Nombre de remontées validées sur la période',
      totalExpiredProductsQuntity: 'Nombre de produits périmés remboursés',
      totalAmountPaid: 'Montant total remboursé',
      annualBugdet: 'Enveloppe annuelle',
      topItems: 'Top pharmacies, Délégués, Produits',
    },
    tabs: {
      all: 'Toutes',
      refused_by_lab: 'Refusées',
      signed_by_pharmacy: 'Signées',
      ongoing: 'En cours',
      awaiting_signing: 'À signer',
      validated_by_lab: 'Validées',
      closed_by_pharmacy: 'Cloturées',
    },
    shareProduct:
      "Partager cette réclamation afin de permettre à d'autres personnes de la consulter et de répondre au pharmacien",
    list: {
      exportExpiredProductsRequests: 'Exporter des périmés',
      exportExpiredProductsRequestsModalSubtitle:
        'Vous pouvez filtrer l’export des périmés selon la date de création de la remontée.',
    },
    prevalidateTooltip:
      'Pré-valider la remontée de périmés déclenche la procédure de signature du bon de destruction côté pharmacien',
    notPrevalidableReason: {
      pharmacyNotVerified:
        'La pharmacie doit être vérifiée avant de lancer la procédure de signature',
      notWithinPolicy: 'La pharmacie ne doit pas dépasser son quota annuel de remontées de périmés',
    },
    alreadyDone: 'Action déjà faite',
    validatedBy: 'Validée par {{user}}',
    refusedBy: 'Refusée par {{user}}',
  },
  labRegions: {
    addNewRegion: 'Ajouter une région',
  },
  labNewRegion: {
    newRegion: 'Nouvelle région',
    createdRegionSuccessfully: 'Cette région a bien été créée',
    departments: 'Départements',
  },
  labEditRegion: {
    editRegion: 'Éditer la région',
    deleteRegion: 'Supprimer cette région de manière définitive ?',
  },
  discussionMessages: {
    systemMessage: {
      // LabsPharmacy
      new_lab_pharmacy_discussion: 'Nouvelle discussion',
      // Orders
      order_created: 'Commande créée',
      order_transmitted: 'Commande transmise',
      order_opened: 'Commande ouverte',
      order_responded: 'Commande répondue',
      order_updated: 'Commande mise à jour',
      order_confirmed: 'Commande confirmée',
      order_received_by_lab: 'Commande reçue par le laboratoire',
      order_confirmed_by_lab: 'Commande confirmée par le laboratoire',
      order_cancelled: 'Commande annulée',
      order_transferred: 'Commande transférée',
      order_transferred_to_customer_service: 'Commande transférée au service client',
      order_transmitted_to_email: 'La commande a été transmise à {{email_address}}',
      order_received_by_email: 'La commande a été reçue par {{email_address}}',
      order_opened_by_email: 'La commande a été ouverte par {{email_address}}',
      order_blocked_by_email:
        "Erreur de transmission à {{email_address}}, merci d'indiquer un nouveau destinataire.",
      validated_by_lab: 'La requête a été validée',
      closed_by_pharmacy: 'La requête a été fermée par le pharmacien',
      refused_by_lab: 'La requête a été refusée par le laboratoire',
      draft: 'La requête est en brouillon',
      // Shared
      new_user: '{{user_fullname}} a rejoint la discussion',
      removed_user: '{{user_fullname}} a quitté la discussion',
      created: 'La requête a été créée par {{user_fullname}}',
      ongoing: 'La requête est en cours',
      awaiting_signing: 'En attente de signature par le pharmacien',
      signed_by_pharmacy: 'Certificat signé par le pharmacien',
      order_blocked: 'Mail introuvable - Commande bloquée - Veuillez changer le destinataire.',
      // Claims
      claim_draft: 'Réclamation enregistrée en brouillon',
      claim_seen: 'Réclamation ouverte par le laboratoire',
      claim_published: 'Réclamation publiée',
      claim_transmitted: 'Réclamation transmise au laboratoire',
      claim_closed_by_lab: 'Réclamation marquée comme résolu par le laboratoire',
      claim_closed_by_pharmacy: 'Vous avez clôturé cette réclamation',
      claim_reopened: 'Réclamation rouverte',
      claim_lab_reminder: 'Relance envoyée au laboratoire',
      claim_notify_lab_user_selected: 'Le pharmacien souhaite un suivi de la part du délégué',
      claim_transferred: 'Réclamation transférée à un autre laboratoire',
      claim_admin_mail_reminder: 'Relance envoyée au laboratoire',
      claim_partner_mail_sharing: 'Réclamation partagée par email',
    },
  },
  chat: {
    sendKeys: 'Appuyer sur cmd+entrer (mac) ou ctrl+entrer (windows)',
    objects: {
      Order: {
        title: 'Commande #{{id}}',
        link: 'Voir la commande',
      },
      Invoice: {
        title: 'Réclamation #{{id}}',
        link: 'Voir la réclamation',
      },
      trades_instance: {
        title: 'Opération commerciale #{{id}}',
        link: 'Voir l’opération commerciale',
      },
      ExpiredProductsRequest: {
        title: 'Remontée de périmés #{{id}}',
        link: 'Voir la remontée de périmés',
      },
      default: {
        title: 'Objet inconnu',
        link: 'Voir l’objet',
      },
    },
    deleteMessageConfirmation:
      'Êtes-vous sûr de vouloir effacer ce message ? Cette action est irreversible',
    sendAs: 'Envoyer en tant que ...',
    messageWillBeSentAsAdmin: 'Par défaut, le message sera envoyé en tant qu’admin Faks',
  },
  expiredLabSetting: {
    guidanceMessageLabel: "Message d'aide",
    automaticResponse: 'Réponse automatique',
    automaticResponseEnabledLabel: 'Activée',
    automaticResponseEnabledInfo:
      "l'action provoquera l'envoi du message ci-dessous pour chaque nouvelle remontée de périmées",
    deleteAutomaticResponseFile: 'Supprimer la pièce jointe',
  },
  labTopics: {
    addNewTopic: 'Ajouter un motif',
    newTopic: {
      title: 'Nouveau motif',
      byPhone: 'Traitement par téléphone uniquement',
      specificAssignment: 'Sélectionner une destination spécifique',
      specificAssignmentInfo:
        'si cette option est activée, la réclamation sera automatiquement assignée au service défini ci-après',
      automaticResponse: 'Réponse automatique',
      automaticResponseInfo: "l'action provoquera l'envoi du message ci-dessous",
      autotmaticResponseMessage: 'Message destiné au pharmacien',
      makeTopicVisible: 'Rendre ce motif visible',
    },
    editTopic: {
      title: 'Éditer le motif',
      deleteAutomaticResponseFile: 'Supprimer la pièce jointe',
    },
  },
  selloutPanelistGenerator: {
    instanceId: 'Id de participation',
    expectedResult: 'Résultat attendu',
    update: 'Mettre à jour la participation',
    expectedResultOptions: {
      reset: 'Supprimer la requête et les données panéliste',
      mark_as_done: 'Requête panéliste réussie + création de sellout items',
      mark_as_expired: 'Requête panéliste expirée',
      mark_as_pharmacy_not_panelist_client_first_occurrence:
        '[1ere tentative] Requête panéliste en erreur - pharmacie non cliente du panéliste (Ospharm ou Offisante uniquement)',
      mark_as_pharmacy_not_panelist_client_retry_expired:
        '[Tentatives expirées] Requête panéliste en erreur - pharmacie non cliente du panéliste (Ospharm ou Offisante uniquement)',
      mark_as_missing_sellout_data_sharing_approval_first_occurrence:
        '[1ere tentative] Requête panéliste en erreur - partage de données non accepté (Ospharm ou Offisante uniquement)',
      mark_as_missing_sellout_data_sharing_approval_retry_expired:
        '[Tentatives expirées] Requête panéliste en erreur - partage de données non accepté (Ospharm ou Offisante uniquement)',
      mark_as_missing_approval_or_not_client_first_occurrence:
        '[1ere tentative] Requête panéliste en erreur - pharmacie non cliente ou partage de données non accepté (Santestat uniquement)',
      mark_as_missing_approval_or_not_client_retry_expired:
        '[Tentatives expirées] Requête panéliste en erreur - pharmacie non cliente ou partage de données non accepté (Santestat uniquement)',
    },
    errorMessages: {
      instance_not_found: 'Participation introuvable',
      instance_not_subscribed: "Le statut de la participation n'est pas [subscribed]",
      sellout_panelist_request_already_exists:
        'Une requête panéliste existe déjà pour cette participation',
      trade_lgo_data_upload_disabled: 'Remontées LGO désactivées sur cette opération',
      trade_without_sellout_requirement: 'Opération sans preuve de type sellout',
      trade_without_trades_products: 'Opération sans produit',
      pharmacy_without_lgo_activated: 'Pharmacie sans extracteur activé',
    },
  },
  faksValidationGenerator: {
    instanceId: 'Id de participation',
    expectedResult: 'Résultat attendu',
    update: 'Mettre à jour la participation',
    expectedResultOptions: {
      reset: 'Réinitialiser le process de validation Faks',
      send_to_validation: 'Envoyer la participation en validation chez Faks',
      validate_participation: 'Approuver la participation par Faks',
      refuse_participation: 'Refuser la participation par Faks',
    },
    errorMessages: {
      instance_not_found: 'Participation introuvable',
      instance_not_awaiting_validation:
        "Le statut de la participation n'est pas [awaiting_validation_by_lab]",
      trade_without_trades_products: 'Opération sans produit',
      trade_without_lab_trade: 'Opération non liée à une opération laboratoire',
      faks_validation_demand_already_exists:
        'Une demande de validation existe deja pour cette participation',
      faks_validation_demand_dont_exists:
        "Aucune demande de validation n'existe pour cette participation, veuillez envoyer la participation en validation faks avant d'effectuer cette action",
      lab_not_authorized_for_faks_validation:
        "La labo associé à cette participation n'est pas autorisé à utiliser la validation Faks, veuillez activer ce setting labo et recommencer",
      faks_validation_not_enabled_for_this_trade:
        "La validation par Faks n'est pas activée pour ce trade, veuillez vérifier sa configuration avant de recommencer",
      trade_with_incompatible_requirement:
        "Le type de preuve de ce trade n'est pas compatible avec la validation par Faks pour le moment",
      missing_sellout_pdf:
        "La participation n'as pas de preuve de sellout associé, veuillez ajouter un document PDF puis recommencer",
      missing_mea_validation_brief: "La preuve de type picture n'a pas de brief associé",
    },
  },
  pharmaciesMerge: {
    id: 'Id',
    name: 'Nom',
    cip: 'Cip',
    phoneNumber: 'Numéro de téléphone',
    address: 'Adresse',
    owner: 'Titulaire',
    roles: 'Rôles (utilisateurs)',
    claims: 'Réclamations',
    eprs: 'Remontées de périmés',
    tradesParticipations: 'Participations aux trades',
    orders: 'Commandes',
    groups: 'Groupements',
    maximumYearly: 'Plafonds annuels périmés',
    lists: 'Listes (Ciblage)',
    keepPharmaConfirm: 'Etes vous sur de vouloir garder cette pharmacie ?',
    withCip: 'Avec le cip',
    withPhone: 'Avec le numéro de téléphone',
    withName: 'Avec le nom',
    mergePharmacies: 'Fusionner les pharmacies',
    pharmacyIdToKeep: 'Id pharmacie à garder',
    pharmacyIdToDestroy: 'Id pharmacie à supprimer',
    pharmaciesMergeTool: 'Outil de fusion de doublons de pharmacies',
    labGroupIdsDiffInternalIds: 'Labos/Groupements avec des ids internes différents',
    labIds: 'IDs des labos : ',
    groupId: 'ID du groupement : ',
    mergeRequirements: {
      title: 'Prérequis pour pouvoir fusionner deux pharmacies',
      address: "L'adresse doit être la même sur les deux pharmacies",
      group: 'Le groupement principal doit être le même sur les deux pharmacies',
      owner: 'Aucun titulaire sur la pharmacie à supprimer',
      roles: 'Aucun rôle (utilisateurs) sur la pharmacie à supprimer',
      onlyForMerge:
        "Cet outil doit servir exclusivement à fusionner un doublon d'une même pharmacie.",
      allInfosWillBeMerged:
        'Toutes les informations de la pharmacie supprimée seront mises sur la pharmacie gardée.',
    },
    mergeSuccess:
      "Les modifications seront prises en compte d'ici quelques secondes. Vous recevrez un mail avec un récapitulatif.",
    errors: {
      not_same_address: 'Les adresses ne sont pas les mêmes',
      owner_still_present: 'Le titulaire de la pharmacie à supprimer est toujours présent',
      roles_still_present: 'Les rôles de la pharmacie à supprimer sont toujours présents',
      stripe_client_id_present: 'La pharmacie à supprimer possède un compte Stripe',
      not_same_group: 'Les groupements ne sont pas les mêmes',
      pharmacy_not_open: 'La pharmacie à supprimer est fermée',
      conflicting_external_membership_ids:
        "Fusion impossible: via les identifiants externes, au moins un labo/groupement considère qu'il s'agit de deux pharmacies différentes",
      lgo_connection_present: 'La pharmacie à supprimer a une connexion LGO',
      orders_present: 'La pharmacie à supprimer a des commandes',
    },
    pharmacyToKeep: 'Pharmacie à garder',
    pharmacyToDestroy: 'Pharmacie à supprimer',
    uga: 'UGA',
  },
  fileUploader: {
    typeError: 'Votre fichier doit être de type : {{type}}',
  },
  stripe: {
    wellConnected: 'Votre compte Stripe de paiement et facturation est bien connecté',
    receivePayment: 'Recevez des paiements directement via Faks',
    restricted: 'Votre compte Stripe de paiement et facturation a été mis en pause',
    wellConnectedDescription:
      'Votre compte Faks est bien connecté à votre compte Stripe pour vous permettre de recevoir des paiements automatiquement sur votre compte bancaire à la fin de certaines opérations commerciales.',
    receivePaymentDescription:
      'Pour percevoir des paiements directement sur votre compte bancaire à la fin des opérations commerciales, un responsable de la pharmacie doit compléter les informations de paiement avec notre partenaire Stripe.',
    restrictedDescription:
      'Complétez les informations supplémentaires demandées par notre partenaire Stripe pour recevoir à nouveau des paiements via Faks',
    formTakeTime: 'Ce formulaire vous prendra environ 5min.',
    knowMore: 'En savoir plus sur les paiements via Faks',
    fillInformations: 'Remplir mes informations (5min)',
    toReceiveCompensation: 'Pour percevoir la compensation ',
    fillInfos: ' un responsable de la pharmacie doit compléter les informations de paiement.',
    labCompensateOnFaks: 'Ce laboratoire vous compense directement sur Faks',
    seePaymentsAndInvoices: 'Voir mes paiements et factures',
    accessStripeAccount: 'Accéder à mon compte Stripe',
  },
  stripeForm: {
    customerInfo:
      'En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) Faks et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Vous avez, entre autres, le droit de vous faire rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque. La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.',
    success: 'Votre moyen de paiement a bien été enregistré',
    configureStripeAccount: 'Configurer mon compte Stripe',
    wellSetup: 'Votre compte Stripe de paiement est bien configuré',
    youCanCompensate: 'Vous pouvez compenser les pharmaciens sur les opérations commerciales.',
    toUpdateInfos:
      "Pour mettre à jour vos informations de paiement, contactez l'équipe Faks csm-labo@faks.co",
    notSetup: {
      title: 'Configurez votre compte Stripe',
      subtitle:
        'Renseignez vos informations de paiement afin de compenser les pharmaciens sur les opérations commerciales.',
      failing: {
        title: 'Nous avons détecté une erreur dans votre compte Stripe.',
        subtitle:
          'Veuillez contacter l’équipe Faks à l’adresse csm-labo@faks.co pour résoudre ce problème.',
      },
    },
    paymentInfos: 'Informations de paiement',
    cashBalance: {
      accountBalance: 'Solde du compte : {{amount}} €',
      makeARefund: 'Effectuer un remboursement',
      areYouSureRefund:
        'Êtes-vous sûr de vouloir vous rembourser la totalité de votre Solde Stripe vers votre compte bancaire ?',
      refundSuccess:
        'Votre demande de remboursement a bien été prise en compte. Cela prendra quelques jours.',
      accountBalanceError: 'Solde du compte momentanément indisponible',
    },
  },
  compensateModal: {
    confirmCompensation:
      'Confirmer la compensation de <strong>{{compensationAmount}}</strong> pour les <strong>{{instancesCount}} remontées</strong> de <strong>{{pharmaciesCount}} pharmacies</strong> ?',
    fees: 'Frais de commissions supplémentaires : <strong>{{feesAmount}}</strong>',
    total: 'Total prélevé : <strong>{{totalAmount}}</strong>',
    confirmAndCompensate: 'Confirmer et compenser',
    compensateSuccess: 'Les compensations ont bien été effectuées.',
    notCompensation: "Vous n'avez pas de remontées à compenser",
    compensateAll: 'Payer via Faks',
    needConfiguration: 'Veuillez configurer votre compte Stripe afin de procéder au paiement.',
    confirmCompensationButton: 'Confirmer la compensation',
    compensationImpossible: 'Compensation impossible',
    cashBalanceToLow:
      'Votre solde est insuffisant. Total à prélever: <strong>{{totalAmount}}</strong>. Argent disponible sur votre compte Stripe: <strong>{{cashBalanceAmount}}</strong>. Veuillez contacter votre CSM Faks.',
    contactAdminToCompensate:
      'Veuillez contacter un administrateur du laboratoire pour payer les pharmaciens.',
    errors: {
      accountBalanceError: 'Votre compte Stripe est momentanément indisponible.',
      access_denied_admin_cant_pay_instances:
        'Vous ne pouvez pas effectuer cette action en tant qu’admin Faks impersonnifié.',
      cash_balance_lower_than_payment_amount:
        'Votre solde est insuffisant. Veuillez contacter votre CSM Faks.',
      lab_payment_onboarding_incomplete:
        'Erreur de configuration de votre compte Stripe. Veuillez contacter votre CSM Faks.',
      no_trade_instance_to_pay: 'Vous n’avez pas de remontées à compenser.',
      stripeError:
        'Erreur de Stripe lors de la validation du paiement. Veuillez contacter l’équipe Faks.',
    },
    compensateOutsideFaksSuccess: 'Les remontées ont bien été marquées comme compensé hors Faks.',
  },
  stripeState: {
    success: {
      wellSetup: 'Bravo ! Votre compte Stripe a bien été paramétré.',
      goBackOnWebOrApp:
        'Vous pouvez retourner sur l’application mobile ou le site Faks pour continuer vos opérations commerciales.',
    },
    notFinished: {
      youDidNotFinish: "Vous n'avez pas fini de paramètrer votre compte Stripe.",
      goBackOnWebOrApp: 'Vous pouvez retourner sur l’application mobile ou le site Faks.',
    },
    backOnFaks: 'Retour à Faks',
    canUseStripe: 'Bravo ! Vous pouvez désormais recevoir des paiements via Faks.',
    needMoreInfos:
      'Votre compte Stripe n’a pas été correctement configuré. Certaines informations sont manquantes. Veuillez recommencer.',
  },
  stripePharmacyFormPage: {
    stripePartner:
      '* Stripe est le prestataire de paiement de Faks, 100% gratuit pour les pharmacies, agréé par votre laboratoire partenaire.',
    nextStepStripe: 'Étape suivante (sur Stripe)',
    fillPharmaInfos:
      'Renseignez les informations de votre pharmacie. Celles-ci figureront sur les factures.',
  },
  freemiumBanner: {
    youHaveFreemiumManageClaims:
      'Vous bénéficiez de la version gratuite permettant de répondre et traiter vos réclamations.',
    upgradePremiumClaims:
      'Pilotez et centralisez vos réclamations sur un seul dashboard, améliorez votre taux de réponse et de résolution de chaque ticket pour augmenter la satisfaction de vos clients avec la version Premium des réclamations sur Faks.',
    youHaveFreeFaks: 'Vous bénéficiez de la version gratuite de Faks.',
    withPremiumFaks:
      'Avec la version premium vous pourrez personnaliser et piloter au mieux l’expérience de votre service client et de vos pharmacies sur Faks.',
    youHaveFreemiumClaims: 'Vous bénéficiez de la version gratuite sur les réclamations.',
    upgradePremiumFaq:
      'Passez à la version premium pour avoir accès à la FAQ et pour ajouter des documents accessibles aux pharmaciens depuis l’application Faks.',
    upgradePremiumRegion:
      'Avec la version premium vous pourrez rediriger automatiquement les réclamations à l’agent du service client gérant chaque région',
    upgradePremiumTopics:
      'Avec la version premium vous pourrez proposer des motifs de réclamations aux pharmaciens et générer des automatisations (message, redirection…).',
  },
  pharmacyLabs: {
    goBackLabsList: 'Retour aux laboratoires',
    onlyPartners: 'Partenaires uniquement',
    labPartner: 'Laboratoire partenaire',
    contactCustomerService: 'Contacter le service client',
    myDelegate: 'Mon délégué',
    partnershipInfo:
      'Retrouvez ici toutes les informations concernant le partenariat de {{group}} avec le laboratoire {{lab}} ',
    addPartnerLabButton: 'Ajouter en laboratoire partenaire',
    removePartnerLabButton: 'Laboratoire partenaire',
    newInvoice: 'Nouvelle réclamation',
    editDelegate: 'Modifier le délégué',
    selectDelegate: 'Selectionner un délégué',
    newOrder: 'Nouvelle commande',
    generalTab: 'Général',
    myActions: 'Mes actions',
    myGoals: 'Mes objectifs',
    myConditions: 'Mes conditions commerciales',
    endedActions: 'Actions terminées',
    goalCard: {
      onTime: 'Dans les temps',
      late: 'En retard sur l’objectif',
      done: 'Objectif atteint',
      rfa: 'Réussite = {{rfa}}% de RFA',
    },
    salesRepAlreadyExistsWithNoAccess:
      "Ce délégué existe déjà mais il n'a pas accès au laboratoire.",
  },
  generalError: {
    error: 'Erreur',
    contactIfPersist: 'Si le problème persiste, veuillez contacter le service client.',
  },
  adminEprs: {
    tabs: {
      ongoing: 'En cours',
      awaitingSigning: 'A signer',
      signedByPharmacy: 'Signées',
      validatedByLab: 'Validées (labo)',
      closedByPharmacy: 'Cloturées',
      refusedByLab: 'Refusées',
      deleted: 'Supprimées',
      draft: 'Brouillons',
      all: 'Toutes',
    },
  },
  adminEpr: {
    showPage: {
      title: 'Remontée de périmés {{labName}} #{{eprId}}',
      yousignProcedure: 'Voir la procédure Yousign',
      noAssociatedProcedure: 'Pas de procédure Yousign associée',
      deletedBanner: 'Cette remontée a été supprimée par la pharmacie.',
      regenerateProcedure: '(Re)générer la procédure Yousign (Seulement si nécessaire)',
      regenerateProcedureModal: {
        title: 'Generez un bon de destruction à signer',
        stepOne:
          'Etape 1: verifiez que la pharmacie est bien vérifiée, a le bon email et un titulaire',
        stepTwo: "Etape 2: Selectionnez l'utilisateur (admin) devant signer",
        stepThree: 'Etape 3: Renseignez son Rpps (11 chiffres)',
        rpps: 'rpps',
        editPharmacy: 'Editer la pharmacie',
        success:
          'La procédure a bien été regénérée, il vous faudra peut être recharger la page pour la voir apparaître.',
        errors: {
          rpps: 'Doit être composé de 11 chiffres',
        },
      },
      recalculateTotalPrice: {
        recalculate: 'Recalculer le montant total',
        success: 'Le montant total a bien été mis à jour',
        confirmText:
          'Le montant de la demande de périmé sera recalculé à partir des prix et des abattements à jour des produits. La politique de reprise du laboratoire n’est pas prise en compte (seuil minimum/plafond annuel). Attention cette action est irréversible.',
      },
    },
    editPage: {
      title: 'Éditer la remontée de périmés',
      deleteEpr: 'Supprimer la remontée de périmés',
      deleteEprModal: {
        bannerText:
          '🛑 Danger Zone 🛑\nSupprimer définitivement cette remontée ?\nToutes ces informations seront perdues définitivement :\n\t1. La conversation\n\t2. Les médicaments scannés\n\t3. Les photos\n\t4. Les documents signés',
        deleteConfirmationPlaceholder: 'Pour confirmer la suppression, écrire "DELETE"',
        errors: {
          deleteConfirmation: 'Veuillez écrire "DELETE" pour confirmer la suppression',
        },
      },
    },
  },
  adminClaims: {
    viewRecoveryPlans: 'Vue des plans de relances',
    viewClassic: 'Vue classique',
    manageClaimRelaunchPlans: 'Gestion des plans de relance',
    tabs: {
      deleted: 'Supprimées',
      byEmail: 'Par mail',
      toBeTreated: 'À traiter - ops',
      snoozed: 'Snoozed',
      inRelaunch: 'En relance',
      withLabButWithoutRelaunch: 'Sans relances',
    },
    notifyLab: 'Envoyer le mail au labo',
  },
  adminClaim: {
    viewDiscussion: 'Ouvrir la discussion',
    accountOwner: 'Propriétaire du compte',
    declaredLab: 'Laboratoire déclaré',
    responsibleLab: 'Laboratoire responsable',
    noAssociatedLab: 'Pas de laboratoire associé',
    guarantorLab: 'Laboratoire garant',
    otherLabs: 'Autres laboratoires',
    faksNotes: 'Notes Faks',
    recoveryPlan: 'Plan de relance',
    numberOfRemindersSent: 'Nombre de relances effectuées',
    snoozeClaim: "Snooze la réclamation jusqu'au (non inclus)",
    defineMainContact: 'Définir le contact principal',
    recepients: 'Destinataires',
    snooze: 'Snooze',
    unsnooze: 'Unsnooze',
    deletedClaim: 'Cette demande a été supprimée',
    detailsPage: {
      removedByPharmacy: 'Cette demande a été supprimée par la pharmacie.',
      remind: 'Relancer',
      remindByEmail: 'Relancer par email',
      copyExternalIdTooltip: "L'identifiant {{externalId}} a été copié dans le presse papier",
      reassignPharmacy: 'Réassigner la pharmacie',
      markAsDeleted: 'Marquer comme supprimée',
      markAsDeletedSuccessMessage: 'La réclamation a bien été marquée comme supprimée',
      cancelDelete: 'Annuler la suppression',
      cancelDeleteSuccessMessage: 'La suppression de la réclamation à bien été annulée',
      shareInvoiceModal: {
        title: "Transmettre à d'autres personnes ou services",
        defaultMessage:
          'Bonjour,\n\nJe vous transmets une demande de {{pharmacyName}}.\n\nCordialement,\n{{userName}}\n',
      },
      remindInvoiceModal: {
        title: 'Relancer le service client',
        defaultMessage:
          "Bonjour,\n\nJe me permets de vous faire une relance de la part de la {{pharmacyName}}.\n\nSi la réclamation est résolue, après avoir cliqué sur le lien en bas de ce mail, n’oubliez pas de cliquer sur le bouton vert en bas de la page “Marquer cette demande comme résolue”.\n\nJe reste à votre entière disposition pour tout complément d'information.\n\nCordialement,\n\n{{currentUserFirstName}} de Faks\n{{currentUserEmail}}\n01 76 43 35 46",
        successMessage: 'E-mail envoyé avec succès.',
        warningMessage:
          "Vous ne pouvez pas relancer le service client pour cette réclamation. Cela peut être dû à un des cas suivants :\n\n\t1. La réclamation à été supprimée\n\t2. La pharmacie n'est pas vérifiée\n\t3. Le laboratoire ne souhaite pas être relancé",
      },
      userSelectionModal: {
        title: 'Envoyer en tant que ...',
        sendAsMe: 'Envoyer un message en tant que moi-même',
      },
      reassignModal: {
        reassignPharmacy: 'Réassigner la pharmacie',
        linkedUser: 'Utilisateur assigné à la réclamation',
        success: 'La pharmacie a bien été réassignée',
      },
    },
    editClaim: 'Modifier la réclamation #{{claimId}}',
    reassignModal: {
      reassignLab: 'Réassigner le laboratoire',
      reassignLogistician: 'Réassigner le logisticien',
      reassignTopic: 'Réassigner le motif',
      assignToLabWarning:
        'Vous allez réassigner de manière définitive le laboratoire de cette réclamation',
      assignToLogisticianWarning:
        'Vous allez réassigner de manière définitive le logisticien de cette réclamation',
      assignToTopicWarning:
        'Vous allez réassigner de manière définitive le motif de cette réclamation',
      reopenClaim: 'Réouvrir la réclamation et notifier le nouveau laboratoire',
      newLab: 'Nouveau laboratoire',
      newLogistician: 'Nouveau logisticien',
      newTopic: 'Nouveau motif',
      assignLab: 'Veuillez assigner un laboratoire à cette réclamation',
    },
    assignModal: {
      assignLab: 'Assigner un laboratoire',
    },
  },
  reviewClaimModal: {
    areYouSatisfied: 'Êtes-vous satisfait du traitement de votre réclamation ?',
    thanksInAdvance:
      "Merci d'avance pour votre avis ! Il aidera {{labName}} à améliorer son service.",
    ratingSaved: 'Votre avis a bien été sauvegardé',
    claimClosed: 'Votre réclamation #{{claimId}} a bien été cloturée.',
  },
  filtersContainer: {
    reset: 'Réinitialiser',
    allFilters: 'Tous les filtres',
  },
  pharmacyLgoConnection: {
    notAllowed:
      "Vous n'êtes pas autorisé à connecter votre panéliste à Faks pour activer la remontée automatique des données de ventes sur vos opérations commerciales. Merci de contacter votre groupement pour en savoir plus.",
    chooseYourPanelist: 'Choisissez votre extracteur',
    selectYourPanelist: 'Sélectionnez votre extracteur',
    acceptedDate: "Date d'acceptation",
    textThatMustBeAccepted: {
      start:
        'Je certifie que je suis le titulaire de la pharmacie et j’autorise {{panelist}} à transmettre mes données de vente à Faks à partir du {{applicationDate}}, conformément à la',
      faksPrivacyPolicy: 'Politique de confidentialité de Faks',
      santestatCguStart: 'et selon les',
      santestatCgu: 'Conditions Générales de Santestat',
    },
    faksDataPolicy: 'Faks ne commercialise pas vos données de vente.',
    dataPolicyKnowMore: 'En savoir plus sur les engagements de Faks.',
    yourPanelistConnectionIsOk: 'Votre connexion extracteur est bien paramétrée.',
    type: 'Type',
    selloutData: 'Données de vente',
    noPanelistAccount: "Si vous n'avez pas encore de compte chez cet extracteur, ",
    createPanelistAccount: 'cliquez ici pour créer un compte {{panelist}}',
    askOwnerToCreateOne:
      'Demandez à <bold>votre titulaire</bold> de se connecter sur son compte Faks afin de connecter votre extracteur à Faks en quelques clics.',
    askLogout: 'Êtes-vous sûr de vouloir déconnecter votre extracteur ?',
    lgoDataWontBeUploaded: 'Vos données de vente ne seront plus remontées automatiquement.',
    connectionSuccess: "La connexion avec votre extracteur est en cours d'établissement !",
    logoutSuccess: 'Votre extracteur a été déconnecté avec succès !',
    errors: {
      textMustBeAccepted: 'Oops ! Vous devez accepter les conditions.',
      cip_already_taken:
        'Ce CIP est déjà renseigné pour une autre pharmacie. Veuillez le modifier, ou contacter l’équipe Faks.',
    },
    verifyCip: 'Vérification du CIP',
    checkThatYourCipIsCorrect:
      'Veuillez vérifier le CIP de votre pharmacie afin que la connexion avec votre extracteur soit correctement activée.',
    suggestedPanelist: "{{panelist}} est l'extracteur conseillé par votre groupement",
    connectionEstablished: 'Votre connexion extracteur est <strong>opérationnelle</strong>.',
    connectionEstablishing:
      'Votre connexion extracteur est <strong>en cours de mise en place</strong>. Cela peut prendre quelques jours.',
    connectionNotEstablished:
      "La connexion avec votre extracteur <strong>n’est pas opérationnelle</strong>.<br />La dernière tentative de transmission du <strong>{{selloutRequestDate}}</strong> pour l’opération commerciale <strong>{{tradeTitle}}</strong> est un échec.<br />Détail de l'erreur : <strong>{{errorDetails}}</strong>",
    connectionErrorDetails: {
      missing_sellout_data_sharing_approval:
        'vous n’avez pas autorisé le partage de vos données de vente avec Faks.',
      pharmacy_not_panelist_client: 'vous n’êtes pas client {{panelist}}.',
      missing_approval_or_not_client:
        'vous n’êtes pas client {{panelist}} ou vous n’avez pas autorisé le partage de vos données de vente avec Faks.',
    },
    panelistConnectionSteps: {
      title: 'Pour que la connexion soit opérationnelle :',
      santestat: {
        first: 'Assurez-vous que vous avez bien un contrat avec l’extracteur Santestat.',
        second:
          'Assurez-vous que vous avez autorisé Santestat à transmettre vos données de vente à Faks. Santestat doit vous envoyer un email avec l’objet suivant “SANTESTAT - votre contrat” et contenant un lien web permettant de donner votre accord.',
        third:
          'La connexion entre Faks et Santestat sera opérationnelle quelques jours après la signature de votre accord de partage effectuée auprès de Santestat.',
        last: 'Contacter le support Santestat au <strong>{{phoneNumber}}</strong> si vous ne recevez cet email dans les 3 semaines qui suivent votre demande de connexion ou pour toute autre question sur la mise en place de cette connexion.',
      },
      ospharm: {
        first: 'Assurez-vous que vous avez bien un contrat avec l’extracteur Ospharm.',
        second:
          'Assurez-vous que vous avez autorisé Ospharm à transmettre vos données de vente à Faks.',
        third:
          "La connexion entre Faks et Ospharm sera opérationnelle quelques jours après la signature de votre accord de partage effectuée auprès d'Ospharm.",
        last: 'Contacter le support Ospharm au <strong>{{phoneNumber}}</strong> si vous avez des questions sur la mise en place de cette connexion.',
        clickHereToAuthorize:
          'Cliquer ici pour autoriser Ospharm à transmettre vos données de vente à Faks.',
      },
      offisante: {
        first: 'Assurez-vous que vous avez bien un contrat avec l’extracteur Offisanté.',
        second:
          'Assurez-vous que vous avez autorisé Offisanté à transmettre vos données de vente à Faks.',
        third:
          "La connexion entre Faks et Offisanté sera opérationnelle quelques jours après la signature de votre accord de partage effectuée auprès d'Offisanté.",
        last: 'Contacter le support Offisante au <strong>{{phoneNumber}}</strong> si vous avez des questions sur la mise en place de cette connexion.',
      },
    },
  },
  adminTrades: {
    title: 'Opérations commerciales',
    findATrade: 'Trouver une opération',
    searchLabTrade: 'Rechercher opération laboratoire',
    tabs: {
      over: 'Passées',
      ongoing: 'En cours',
      upcoming: 'À venir',
      all: 'Toutes',
    },
    noLabTrade: 'Pas de trade laboratoire',
    createNewTrade: 'Nouveau trade',
    exportLabs: 'Obtenir les liens des laboratoires',
    labTrades: 'Opération Laboratoire',
    status: {
      closed: 'Terminée',
      upcoming: 'À venir',
      ongoing: 'En cours',
      over: 'Passée',
    },
  },
  adminTrade: {
    title: 'Opération {{tradeTitle}}',
    duration: 'Du <strong>{{startDate}}</strong> au <strong>{{endDate}}</strong>',
    transmission: 'Transmission: ',
    selloutRequested: 'Sellout demandé: ',
    visibleToPharmacist: 'Visible pour le pharmacien: ',
    targetedPharmacies: 'Pharmacies ciblées:',
    targetedPharmacyList: 'Nom de la liste de pharmacies: ',
    duplicateToGroup: 'Dupliquer et migrer vers un groupement',
    duplicateModal: {
      title: 'Dupliquer et migrer vers un groupement',
      groupSelectLabel: 'Choisir le groupement vers lequel dupliquer le trade: ',
    },
    deleteConfirmDescription:
      'Êtes-vous sûr de vouloir supprimer cette opération ? Cela va supprimer les {{tradeInstancesCount}} participations rattachées. Cette action est irréversible.',
    duplicateTradeInstancesButtonModal: {
      chooseTrade:
        'Choisissez la Trade cible vers laquelle vous souhaitez dupliquer les inscriptions.\nCela ne va pas supprimer les inscriptions de la Trade source. ',
      reminder:
        'Rappels : \n- Ne pas oublier de supprimer la Trade source à la fin du process\n- Cette action est irréversible, choisissez bien la Trade cible sans vous tromper.',
      duplicateInstances: 'Dupliquer les inscriptions vers une autre Trade',
      success: "L'action a bien été prise en compte. Un récapitulatif vous sera envoyé par email.",
    },
    moveTradeInstancesMenuItemModal: {
      chooseTrade:
        'Choisissez la Trade cible vers laquelle vous souhaitez déplacer les inscriptions.\n',
      reminder:
        'Rappels : \n- Les participations seront déplacées avec toutes leurs preuves.\n- Ne pas oublier de supprimer la Trade source à la fin du process.\n- Cette action est irréversible, choisissez bien la Trade cible sans vous tromper.',
      moveInstances: 'Déplacer les inscriptions vers une autre Trade',
      success: "L'action a bien été prise en compte. Un récapitulatif vous sera envoyé par email.",
    },
  },
  adminTradesInstances: {
    searchPlaceholder: 'Trouver une participation',
    instances: 'Participations',
    accesses: 'Ciblage',
    searchInstance: 'Chercher une participation',
  },
  tradesKind: {
    direct: 'Direct Labo',
    platform: 'Sur l’espace pro',
    group_owned: 'Par le groupement',
    generic: 'Generic',
    order: 'Commande',
    prevalidate: 'Pré-validation groupement avant transmission au labo dans Faks',
  },
  adminTradeInstance: {
    selloutItems: 'Données de ventes',
    selloutTotalItemQuantities: 'Quantité de produits vendus : {{count}}',
    selloutTotalItemSalesAmount: "Chiffre d'affaires réalisé (€ TTC) : {{count}}",
    deleteButton: {
      title: 'Supprimer la remontée',
      description:
        'Êtes-vous sûr de vouloir supprimer cette participation ? Cette action est irréversible.',
      descriptionWithProof:
        'Êtes-vous sûr de vouloir supprimer cette participation ? Cette action est irréversible. \n Il y a une ou plusieurs preuves ajoutées, elles vont également être supprimées irréversiblement.',
      tooltip: 'Vous ne pouvez pas supprimer une remontée au statut validé ou supérieur',
    },
    compensation: {
      compensation: 'Compensation',
      noInfo: 'Aucune information sur la compensation disponible',
      compensatedAt: 'Compensé le',
      compensatedBy: 'Compensé par',
      compensationValidatedByLab: 'Compensation validée par le labo',
      compensationFromPharmacist: 'Compensation renseignée par le pharmacien',
      calculatedCompensation: 'Compensation calculée',
      finalCompensation: 'Compensation finale',
      countUg: '{{count}} UG',
    },
    generalInformations: {
      generalInformations: 'Informations générales',
      createdAt: 'Créé le',
      createdBy: 'Créé par',
      updatedAt: 'Modifié le',
      finishedAt: 'Fini le',
      refusedAt: 'Refusé le',
      validatedAt: 'Validaté le',
      refusedBy: 'Refusé par',
      validatedBy: 'Validé par',
      groupRefusedReason: 'Raison de refus groupement',
      labRefusedReason: 'Raison de refus labo',
      pharmacyRefusedReason: 'Raison de refus pharmacien',
      pharmacistComment: 'Commentaire pharmacien',
    },
    stripe: {
      stripePaiement: 'Paiement Stripe',
      noInfo: 'Aucune information sur la compensation Stripe disponible',
      compensationPaid: 'Compensation payée: {{amount}} €',
      paymentStatus: '- Statut du paiement : {{status}}',
      paymentId: '- ID du paiement : {{paymentId}}',
      paymentMadeOn: '- Paiement effectué le : {{createdAt}}',
      paymentStripeId: '- Stripe invoice ID : {{stripeId}}',
    },
    trade: {
      tradeKind: "Type d'opération",
      labTrade: 'Opération laboratoire',
    },
    requirements: {
      tradeRequirements: 'Listes des pièces justificative à remonter :',
      selloutTotalItemQuantities: 'Quantité de produits vendus : {{count}}',
      selloutTotalItemSalesAmount: "Chiffre d'affaires réalisé (€ TTC) : {{count}}",
    },
  },
  message_notifications: {
    order_created: 'Commande créée',
    order_transmitted: 'Commande transmise',
    order_opened: 'Commande ouverte',
    order_confirmed: 'Commande confirmée',
    order_confirmed_by_lab: 'Commande confirmée par le laboratoire',
    order_cancelled: 'Commande annulée',
    order_transferred: 'Commande transférée',
    order_transferred_to_customer_service: 'Commande transférée au service commande',
    refused_by_lab: 'Commande refusée par le laboratoire',
    order_blocked: 'Commande bloquée',
  },
  labPharmacyLists: {
    createList: 'Créer une liste',
    importList: 'Importer une liste',
  },
  pharmacyListForm: {
    fileExemple: 'Fichier exemple',
    fileExempleDescription:
      "Vous pouvez télécharger le fichier exemple et l'utiliser comme point de départ pour votre fichier.",
    fileExempleWarning:
      "Important : Le fichier doit être au format csv, avec une ligne d'en-tête. Les colonnes doivent être séparées par des points virgules.",
    addPharmaciesModal: {
      table: {
        pharmacyName: 'Nom',
        cip: 'CIP',
        internalId: 'ID interne',
        groupName: 'Groupement',
        phone: 'Téléphone',
        address: 'Adresse',
        added: 'Ajoutée',
        add: 'Ajouter',
      },
    },
    deletePharmacyListConfirmation:
      'Êtes vous sûr(e) de vouloir supprimer cette liste ? Cette action est irreversible.',
  },
  adminTradesSelloutItems: {
    title: 'Remontées sellout par produit',
    goBackLinkTitle: "Retour à l'édition de la participation",
    createNewSelloutItem: 'Créer une donnée de vente',
  },
  adminTradesSelloutItemForm: {
    editOrCreateSelloutData: "{{mode}} des données de ventes d'un produit",
    itemTitle: 'Produit #{{id}} / EAN : {{ean}}',
    ean: 'EAN',
    quantity: 'Quantité',
    editing: 'Édition',
    creation: 'Création',
    turnoverIncludingTax: "CA TTC en centimes d'€",
    turnoverExcludingTax: "CA HT en centimes d'€",
    source: 'Source de données',
    sourceOptions: {
      faks: 'Faks',
      highcodata: 'HighCo Data',
      ospharm: 'Ospharm',
      offisante: 'Offisanté',
      santestat: 'Santestat',
      group: 'Groupement',
      pharmacy: 'Pharmacie',
    },
    requiredFields: {
      quantity: 'La quantité doit être supérieure ou égal à 0',
      turnoverIncludingTaxInCents: "Le chiffre d'affaires doit être supérieur ou égal à 0",
    },
  },
  adminNewTradesSelloutItem: {
    title: 'Créer des données de ventes',
    successMessage: 'La remontée pour le produit {{ean}} a bien été créée',
    errorMessages: {
      tri_ean_unicity_index: '⛔️ La remontée pour le produit {{ean}} éxiste déjà',
    },
  },
  adminEditTradesSelloutItem: {
    title: 'Éditer les données de ventes',
    goBackTitle: 'Remontées Sellout',
    deleteSuccessMessage: 'La remontée a bien été supprimée !',
  },
  adminLabTrades: {
    title: 'Trades laboratoire',
    findALabTrade: 'Trouver un trade laboratoire',
    columnHeaders: {
      sellout: 'SELLOUT',
      lab: 'LABO',
    },
    markParticipationsAsCompensatedOutsideFaks:
      'Marquer les participations comme compensées hors Faks',
    compensationModal: {
      title: 'Compenser les participations hors Faks',
      description: {
        count_zero: 'Il n’y a aucune participation validée à compenser.',
        count_one:
          'Il y a une participation validée que vous pouvez compenser hors Faks. Voulez-vous la compenser ?',
        count_other:
          'Il y a {{count}} participations validées que vous pouvez compenser hors Faks. Voulez-vous toutes les compenser ?',
      },
      success: {
        count_one: 'La participation a bien été marquée comme compensée hors Faks.',
        count_other:
          'Les {{count}} participations ont bien été marquées comme compensée hors Faks.',
      },
    },
    updateValidationBrief: 'Créer ou mettre à jour le brief de validation',
    validationBriefModal: {
      title: 'Brief de validation',
      labBrief: 'Brief du labo',
      errors: {
        faks_validation_not_enabled:
          "La validation Faks n'est pas encore activée pour ce trade, vous pouvez quand même configurer le brief, il sera pris en compte lors de l'activation de la validation Faks.",
        no_mea_brief_from_lab:
          "Le Labo n'a pas encore rempli son brief pour cette opération, vous pouvez tout de même poursuivre cette opération pour l'envoi en validation.",
        products_location_required: "L'emplacement des produits est un champ obligatoire",
        brand_and_range_required: 'Le nom de la marque et la gamme de produit sont obligatoires',
      },
      fields: {
        products_location: 'Emplacement des produits',
        brand_and_range: 'Marque et gamme de produit',
        pos_advertising: 'Présence et type de PLV',
        exclusivity: 'Exclusivité',
        specific_conditions: 'Conditions spécifiques',
        intercounter_quantity_check: 'Quantité Inter-comptoir',
        end_display_quantity_check: 'Quantité tête de gondole',
        sale_box_quantity_check: 'Quantité bac soldeur',
        basin_quantity_check: 'Quantité vasque',
        shelf_space_quantity_check: 'Quantité linéaire',
        floor_furniture_quantity_check: 'Quantité meuble au sol',
      },
    },
  },
  adminPharmacies: {
    title: 'Pharmacies',
    newPharmacy: 'Nouvelle pharmacie',
    findAPharmacy: 'Trouver une pharmacie',
    tabs: {
      all: 'Toutes',
      awaitingVerification: 'À vérifier',
      awaitingOnboarding: 'À onboarder',
      verified: 'Vérifiées',
      strategic: 'Stratégiques',
      inTest: 'En test',
      noInvoice: 'Aucune demande',
      oldInvoice: 'Aucune demande récente',
    },
    viewClaims: 'Voir les demandes',
  },
  adminPharmacy: {
    viewEprs: 'Voir les remontées de périmés',
    viewPharmacyRecap: 'Affichier le recap de la pharmacie',
  },
  adminNewPharmacy: {
    title: 'Créer une pharmacie',
  },
  pharmacyKinds: {
    pharmacy: 'Pharmacie de ville',
    hospital_pharmacy: 'Pharmacie hospitalière',
    parapharmacy: 'Parapharmacie',
    medical_equipement_store: 'Magasin de matériel médical',
    sports_store: 'Magasin de sport',
    organic_store: 'Magasin bio',
    wholesaler: 'Grossiste',
    central_purchasing_office: 'Centrale d’achat',
    physiotherapist: 'Kinésithérapeute',
    retirement_home: 'Ehpad',
    e_commerce_website: 'Site e-commerce',
    specialized_store: 'Magasin spécialisé',
    gym: 'Salle de sport',
    beauty_sport_institute: 'Institut Beauté Sport',
    warehouse: 'Entrepôt',
    medical_profession_and_similar: 'corps médical & assimilés',
    other: 'Autre',
  },
  adminPharmacyForm: {
    generalSettings: 'Réglages Généraux',
    labSettings: 'Réglages Laboratoire',
    billingInformation: 'Informations pour la facturation',
    stripeSettings: 'Réglages Stripe',
    features: 'Features',
    team: 'Équipe',
    name: 'Nom',
    phoneNumber: 'Tel. fixe',
    email: 'Adresse mail',
    cip: 'Code CIP',
    pharmacyKind: "Type de l'établissement",
    isRppsRequired: 'RPPS obligatoire',
    address: 'Adresse',
    postalCode: 'Code postal',
    city: 'Ville',
    uga: 'UGA',
    pharmacyOpen: 'Pharmacie ouverte',
    oldPhoneNumber: 'Ancien numéro de téléphone',
    oldCip: 'Ancien CIP',
    sponsoredBy: 'Sponsorisée par',
    sponsoredByInfo: 'Le labo qui offre les comptes utilisateur',
    demoForLabId: 'Pharmacie affiliée à',
    demoForLabIdInfo:
      'À utiliser si cette pharmacie est une fausse pharmacie, utilisée par le laboratoire pour tester sa page',
    businessAddressLine1: 'Adresse du siège social',
    businessAddressLine2: "Suite de l'adresse du siège social",
    businessAddressZipCode: 'Code postal du siège social',
    businessAddressCity: 'Ville du siège social',
    businessAddressCountry: 'Pays du siège social',
    businessName: 'Nom de la société',
    businessSiret: 'SIRET',
    businessLegalForm: 'Forme sociale',
    businessShareCapital: 'Capital social',
    businessRcs: 'Numéro RCS',
    businessVatNumber: 'Numéro de TVA',
    isBusinessAddressDifferentThanFiscalAddress:
      'Adresse de facturation différente de celle du siège social',
    fiscalAddress: 'Adresse de facturation',
    stripeAccountStatus: 'Etat du compte Stripe',
    stripeAccountStatusOptions: {
      none: 'Aucun',
      can_create_account: 'Peut se créer un compte',
      ok: 'Validé',
      incomplete: 'Incomplet',
      restricted: 'Restreint',
    },
    stripeError: 'Erreur lors de la création du compte :',
    verified: 'Vérifiée',
    isATest: 'Test, démo ou erreur',
    isAmbassador: 'Pharmacie ambassadrice',
    isOrderEnabled: 'Commandes activées',
    isLabChatEnabled: 'Chat délégué activé',
    role: 'Role',
    owner: 'Titulaire',
    addNewUser: 'Ajouter un utilisateur',
    errors: {
      role_mismatch: 'Le rôle ne correspond pas à celui de l’utilisateur',
      pharmacy_cip_already_taken: 'Le CIP est déjà utilisé par une autre pharmacie',
    },
    setting: {
      otherSettings: 'Autres Réglages',
      lgoConnectionEnabled: 'Connexion LGO activée',
      compensationsFollowUpEnabled: 'Suivi de compensations activé',
      createCompensations: "Créer des compensations pour l'historique",
      createCompensationsModal: {
        createCompensations: "Créer des compensations pour l'historique",
        success: 'Les compensations ont bien été créées',
        from: 'A partir du...',
        createCompensationsFor:
          "Créer automatiquement les compensations des périmés, réclamations et opérations éligibles à partir d'une certaine date.",
      },
    },
    groupSettings: 'Réglages Groupement',
    groupSettingsPlaceholder: 'Ajouter le Groupement principal',
  },
  adminEditPharmacy: {
    generalInformation: {
      kind: 'Type de pharmacie',
      rpps: 'RPPS obligatoire',
      zone: 'UGA',
      open: 'Pharmacie ouverte',
      oldPhoneNumber: 'Ancien numéro de téléphone',
      oldCip: 'Ancien CIP',
    },
  },
  adminGroups: {
    title: 'Groupements',
    newGroup: 'Nouveau groupement',
    findAGroup: 'Trouver un groupement',
  },
  adminNewGroup: {
    title: 'Nouveau groupement',
    errors: {
      group_name_taken: 'Ce nom de groupement existe déjà',
    },
  },
  adminGroupForm: {
    name: 'Nom du groupement',
    generalSettings: 'Réglages Généraux',
    team: 'Équipe',
    email: "Email de l'administrateur",
    visible: 'Groupement visible par le laboratoire, il peut éditer le partenariat',
    isClient: "Groupement client, il est responsable d'ajouter lui-même ses adhérents",
    crmIntegrationEnabled: 'Intégration CRM',
    isNewsfeedEnabled: 'Newsfeed Activé',
    toolUsage: 'Outil trade utilisé',
    toolUsageOptions: {
      none: 'Aucun',
      faks: 'Faks',
      other: 'Autre',
    },
    isPharmacyTradeEnabled: "Création d'opérations par les pharmacies activée",
    orderTradesEnabled: 'Création d\'Opérations de type "commande" activées',
    toggleOrderPharmacies: 'Activer le module Commande pour les pharmacies du groupement',
    panelist: 'Panéliste suggéré',
    isClaimsClient: 'Laboratoire client sur les réclamations',
    isClaimsClientOptions: {
      no: 'Non',
      blocked: 'Bloqué',
      yes: 'Oui',
    },
    posterFeatureActivationScope: 'Module Affiches',
    posterFeatureActivationScopeOptions: {
      none: 'Désactivé',
      pharmacy_by_pharmacy: 'Pharmacie par pharmacie',
      all_pharmacies: 'Tout le groupement',
    },
    claimsContractStartDate: 'Date du contrat sur les réclamations',
    tradesContractStartDate: 'Date du contrat sur les trades',
    newsfeedContractStartDate: 'Date du contrat sur le newsfeed',
    isAllowedToRefuseLabTrades:
      'Groupement a le droit d’exclure les opérations labos du calendrier des pharmaciens',
    visibilityValidationUser: 'Destinataire pour valider les opérations',
    errors: {
      group_name_taken: 'Ce nom de groupement existe déjà',
    },
  },
  adminGroup: {
    contractsSettings: 'Contrats',
    tradesSettings: 'Opérations',
  },
  adminLabs: {
    title: 'Laboratoires',
    newLab: 'Nouveau laboratoire',
    findALab: 'Trouver un laboratoire',
  },
  adminNewLab: {
    title: 'Nouveau laboratoire',
  },
  adminLabForm: {
    name: 'Nom du laboratoire',
    supportPhone: 'Téléphone du service client',
    supportEmail: 'Email du service client',
    claimLabSettings: 'Plan de relance',
    errors: {
      transmission_incompatible_with_the_plan:
        'Réglages opérations: Transmission incompatible avec le plan',
      overwritten_routing_user_empty:
        "Vous devez définir un destinataire des remontées si vous voulez écraser l'assignation par défault",
      notifications_recipient_missing:
        "Réglages opérations: Le destinataire des emails de remontées d'opérations doit être renseigné pour les laboratoires avec un plan trade premium",
    },
    labName: 'Nom du labo',
    details: 'Présentation du laboratoire',
    detailsInfo: 'Affiché sur la page laboratoire, en dessous du bouton "Nouvelle demande"',
    generalSettings: 'Réglages Généraux',
    public: "Laboratoire visible dans l'App mobile",
    mainUser: 'Utilisateur principal',
    managedBy: 'Géré par',
    managedByHint: 'Le réseau de vendeurs où se trouvent les délégués',
    groupHint:
      'Définit un lab comme appartenant à un groupe (il ne sera alors visible que par ses membres)',
    otherNames: 'Autres noms',
    otherNamesHint:
      'Ces autres noms servent à faciliter la recherche pour les pharmaciens (bien les séparer par une virgule)',
    certified: 'Laboratoire certifié',
    certifiedHint: '- affiche le badge certifié dans la liste des labos & page labo',
    featureLimitedAccess: 'Accès limité',
    featureLimitedAccessHint: "- Les utilisateurs non admin n'ont accès qu'aux demandes",
    verifiedSalesReps: 'Délégués vérifiés',
    verifiedSalesRepsHint: '- Les délégués de ce laboratoire ont été ingérés et donc vérifiés',
    featureGroupPages: 'Pages groupement',
    handlingAllPharmaciesKind: 'Gère les établissements hors pharmacies',
    claimsSettings: 'Réglages Réclamations',
    cooperationLevel: 'Niveau de coopération des réclamations',
    cooperationLevelOptions: {
      ok: 'Ok',
      mail: 'Par email uniquement',
      none: 'Aucune',
      sales_rep_mail: 'Mails aux DPs',
      lab_mail: 'Mails au labo',
    },
    topicUsage: 'Motifs de demande',
    topicUsageOptions: {
      none: 'Aucun',
      optional: 'Optionnel',
      mandatory: 'Obligatoire',
    },
    resellerWording: 'Nomenclature revendeur',
    resellerWordingType: {
      label: 'Wording pour désigner les pharmacies et points de vente',
      values: {
        pharmacy: 'Pharmacie',
        point_of_sale: 'Point de vente',
      },
    },
    visibleForGroups: 'Afficher les réclamations aux groupements',
    claimRelaunchPlan: 'Plan de relance',
    customerServiceInformation: 'Information service client',
    defaultSupportUser: 'Service client - contact principal',
    claimSupportUser: 'Contact de relance',
    labIsClient: 'Laboratoire premium sur les réclamations',
    labIsClientHint:
      '- affiche le badge éclair + affiché en haut de la liste des labos + peut voir les notes + peut télécharger la liste des pharmacies et des réclamations en Excel',
    provider: 'Logisticien',
    providerHint: '- lors de sa demande le pharmacien devra préciser le nom du labo',
    reportingUser: 'Rapport hebdomadaire destiné à',
    reportingUserHint: 'Par défaut envoyé au service client',
    includeDetailsInMails: 'Afficher les détails de la demande dans les mails',
    includeDetailsInMailsHint: '- contact pharmacien, messages et pièces-jointes',
    notifiableLabUsers: 'App délégué',
    notifiableLabUsersHint:
      '- le pharmacien peut ajouter son délégué et demander un suivi de sa part',
    autoCalcRating: 'Calcul auto de la note moyenne',
    rating: 'Note moyenne /5',
    autoCalcResolvedDelay: 'Calcul auto du temps de résolution',
    invoiceResolvedDelay: 'Temps en secondes',
    crmIntegrationEnabled: 'Intégration CRM',
    displayResolutionTime: 'Afficher le temps de réponse et résolution aux pharmacies',
    expiredLabSettings: 'Réglages Périmés',
    featureEnabled: 'Fonctionnalité activée',
    computeTotalPrice: 'Ajouter la valeur totale de la remontée de périmées dans la requête',
    minimumRequestValueInEuros: 'Valeur minimale par remontée (en euros)',
    acceptedTimeBeforeExpiryInMonth:
      'Nombre de mois autorisés pour les reprises de périmés anticipées',
    acceptedTimeAfterExpiryInMonth:
      'Nombre de mois autorisés pour les reprises de périmés après la date de péremption',
    automaticValidationAmount:
      'Validation automatique des demandes en dessous d’un seuil (en euros)',
    destructionCertificateRequired: 'Utiliser le bon de destruction Faks pour les périmés',
    automaticPrevalidation: 'Prévalidation automatique des demandes de remontées de périmés',
    salesRepCanValidate: 'Autoriser les délégués à valider les remontées',
    pharmacistAbilityToCreateRequests: 'Autoriser les pharmaciens à créer/modifier les remontées',
    pharmacistAbilityToCreateRequestsOptions: {
      always: 'Toujours',
      by_cs: 'Autorisé par le Service Client',
      by_lab_rep: 'Autorisé par le Délégué',
      by_cs_and_lab_rep: 'Autorisé par le Service Client et le Délégué',
      never: 'Jamais',
    },
    canManuallyCreateProducts: 'Autoriser la saisie manuelle des produits périmés',
    forceDatamatrixUsage: "Forcer l'utilisation des datamatrix",
    yearlyMaximumRefund: 'Limite annuelle de remboursement de périmés',
    hideYearlyMaximumRefundFromSalesRepAndPharmacy:
      'Cacher la limite annuelle aux pharmaciens et délégués',
    queryAssignment: 'Assignation des requêtes',
    overwriteDefaultRouting: "Écraser l'assignation par défaut",
    overwrittenRoutingUser: 'Assigner à',
    dataExportToCompensationEnabled: 'Export des données vers le service de compensation activé',
    dataExportToCRMEnabled: 'Export des données vers le CRM activé',
    dataExportInfo:
      "Un export automatique quotidien des remontés vers le Système d'information du laboratoire est en place. Activable seulement par l'équipe tech",
    dataExportOtherLabs: "Autres laboratoires concernés par l'export",
    dataExportBucketName: "Nom du bucket pour l'export de donnée",
    tradesLabSetting: 'Réglages Opérations',
    plan: 'Plan Trades',
    planOptions: {
      none: 'Aucun',
      freemium: 'Freemium',
      premium: 'Premium',
    },
    shouldShowCompensationToPharmacist: 'Afficher le montant de compensation aux pharmaciens',
    allowLgoDataUpload: 'Activer les remontées LGO par défaut',
    isSalesRepAccessEnabled: 'Accès délégué (complet)',
    isSalesRepAccessEnabledInfo:
      "Ce paramètre donne accès aux délégués au module Opérations principal dans l'appli mobile",
    isSalesRepNewTradeShowEnabled: 'Utiliser le nouvel écran trade',
    isSalesRepNewTradeShowEnabledInfo:
      "Ce paramètre active le nouvel écran participation dans l'espace délégué de l'app mobile",
    enableSalesRepParticipation: 'Accès délégué (via page pharmacie)',
    enableSalesRepParticipationInfo:
      "Ce paramètre donne accès aux délégués aux opérations d'une pharmacie",
    allowLabToActivateFaksValidation:
      'Autoriser le laboratoire à activer la validation des remontées d’opérations par Faks (via Highcodata) par opération',
    allowLabToActivateFaksValidationCard:
      "Autoriser l'activation de la validation via Faks par opération",
    paymentAndBillingType: 'Paiement et Facturation',
    paymentAndBillingTypeOptions: {
      external: 'Hors Faks',
      stripe: 'Avec Stripe',
      internal: 'Interne',
    },
    directEmailTransmissionType: 'transmission des participations aux opérations',
    directEmailTransmissionTypeHint:
      'complète: email avec les pièces jointes, avec lien: email avec le lien vers la webapp trades labo, aucune: pas de transmission',
    directEmailTransmissionTypeOptions: {
      complete: 'Complète',
      with_link: 'Avec lien',
      none: 'Aucune',
    },
    tradesNotificationsRecipient: "Emails de remontées d'opérations envoyés à",
    customTradeMessage: 'Message personnalisé pour les trades',
    getSecureLink: 'Obtenir le lien sécurisé des trades freemium',
    orderLabSettings: 'Réglages Commandes',
    orderCooperationLevel: 'Niveau de coopération des commandes',
    orderCooperationLevelOptions: {
      ok: 'Ok',
      none: 'Aucune',
      sales_rep_mail: 'Mails aux DPs',
      lab_mail: 'Mails au labo',
    },
    orderFeatureEnabled: 'Fonctionnalité activée côté délégué',
    orderSupportUser: 'Service client des Commandes - contact principal',
    isOrdersManagementEnabled: 'Gestion des commandes sur Faks activée',
    offisanteLabName: 'Nom du laboratoire sur Vigirupture',
    stockInfo: 'Stock',
    discountInfo: 'Promotions',
    status: 'Status',
    clientId: 'Client ID',
    paymentMethodId: 'Payment Method ID',
    faksFeesPercentage: 'Commission Faks en %',
    team: 'Équipe',
    secureLinkTitle: 'Ce lien sécurisé expire dans 2 mois',
    stripeLabSettings: {
      title: 'Réglages Stripe',
      clientID: 'Client ID',
      paymentMethod: 'Methode de paiement',
      invoicesRecipient: 'Destinataire des factures',
      paymentMethodOptions: {
        sepa_debit: 'Prélèvement SEPA',
        customer_balance: 'Virement sur solde Stripe',
      },
      invoicesRecipientOptions: {
        lab: 'Le laboratoire',
        faks: 'Faks',
      },
      errors: {
        payment_method_doesnt_exist:
          "Le compte stripe avec la méthode de paiement choisie n'a pas été trouvé",
        payment_client_id_taken: 'Ce compte stripe est déjà utilisé par un autre laboratoire',
      },
    },
    expiredSettings: {
      automaticValidationConfirmDialog: {
        title: 'Confirmation',
        description:
          'Êtes-vous sûr de vouloir activer la validation automatique des demandes de remontées de périmés en dessous de {{value}}€ ?<br />Cela va valider automatiquement l’historique des remontées de périmés qui sont actuellement en dessous de ce seuil.',
      },
    },
    allowGroupToCreateTrades:
      'Autoriser les groupements à continuer de créer des Trades Direct Labo et Centralisé Groupement',
    visibilityStatusNotificationRecipient:
      'Destinataire pour informer les opérations inclues ou exclues par un groupement',
  },
  masterTrades: {
    createNewLabTrade: 'Créer une nouvelle opération',
    createNewTrade: 'Créer une nouvelle opération',
    pictureRequirementCount: '{{count}} Mise en avant',
    selloutRequirementCount: '{{count}} Sellout',
    pictureRequirement: 'Mise en avant',
    selloutRequirement: 'Sellout',
    tradeDates: "Dates de l'opération",
    ongoing: 'En cours',
    upcoming: 'À venir',
    over: 'Passées',
    conditions: 'Conditions et options',
    requirements: 'Justificatifs',
    noRequirements: 'Aucun justificatif',
    targeting: 'Ciblage',
    files: 'Pièces jointes',
    dpValidation: 'Validation DP',
    faksValidation: 'Validation via Faks',
    additionalValidation: 'Validation additionnelle [visible par admin Faks uniquement]',
    compensation: 'Compensation',
    compensations: 'Compensations',
    faksPayment: 'Paiement via Faks',
    invoice: 'Facture',
    list: 'Liste',
    partnerGroups: 'Groupements partenaires de Faks',
    noPartnerGroups: 'Pas de groupements partenaires',
    group: 'Groupement',
    adjustment: 'Ajustement ciblage',
    tradeTitle: "Titre de l'opération",
    description: 'Description',
    tradeDate: "Dates de l'opération",
    tradeDateDialog: "Choisissez la date de début et de fin de l'opération",
    noProducts: 'Aucun produit',
    compensationType: 'Mécanique de compensation',
    compensationMethod: 'Moyen de compensation',
    compensatedBy: 'Compensé par',
    compensationNotAvailable:
      'Veuillez ajouter des preuves à cette opération afin de pouvoir paramétrer des compensations.',
    salesRepVisibilityEnabled: 'Le DP peut rendre l’opération visible par pharmacie',
    products: {
      editProductsInfo:
        'Les produits sont modifiables jusqu’à la date de fin de de l’opération. Une fois que l’opération est terminée, les produits ne sont plus modifiables.',
      updateProducts: 'Modifier les produits',
      seeAllProducts: 'Voir tous les produits ({{count}})',
    },
    requirementCompensation: {
      briUnit: 'BRI {{briValue}}€',
      percentageUnit: 'RI {{percentage}}%',
      ugPercentageUnit: 'UG {{percentage}}%/CA',
      ugUnit: '{{threshold}} + {{freeQuantity}} UG',
      eurosFixedUnit: 'COOP {{eurosValue}}€ fixe',
      compensationTypes: {
        percentage: 'RI (% du CA TTC non remisé)',
        bri: 'BRI (€ par unités vendues)',
        ug: 'UG par palier (exemple 2+1)',
        ug_percentage: 'UG en % des ventes',
        euros_fixed: 'COOP (Montant fixe en €)',
      },
      compensationMethods: {
        credit_note: 'Avoir',
        bank_transfer: 'Virement bancaire',
        gift_voucher: 'Chèque cadeau',
        free_units: 'UG',
        faks_transfer: 'Virement Faks',
      },
      compensatedBy: {
        lab: 'Laboratoire',
        group: 'Groupement',
        sales_rep: 'Délégué',
      },
      compensationMethod: 'Moyen',
    },
    editConfirmation: {
      confirmationText:
        'Les modifications effectuées prendront effet <strong>pour tout votre ciblage</strong>.<br/>Si vous aviez effectué des ajustements de votre opération pour certains groupements, ces <strong>ajustements par groupement seront écrasés</strong> par votre modification en cours.<br/>Cette action est <strong>irréversible</strong>.<br/>Êtes-vous sûr de vouloir poursuivre ?',
      confirmation: 'Confirmation',
      saveUpdates: 'Enregistrer les modifications',
    },
    conditionsOrOptions: {
      editConditionsOrOptions: 'Modifier les conditions et option',
    },
    compensationModal: {
      title: 'Modifier une compensation',
    },
    conditionsInfos: {
      requirementEditInfo:
        'Les justificatifs sont modifiables jusqu’au début de l’opération. Une fois que l’opération a démarré, l’ajout et la suppression de justificatifs n’est plus possible.',
      compensationInfo:
        'La mécanique de compensation est modifiable tant qu’aucune participation n’a été validée. Si au moins une participation a été validée, la mécanique de compensation n’est plus modifiable.',
      paymentInfo:
        'Les options de paiement et facturation sont modifiables tant qu’aucune participation n’a été compensée. Si au moins une participation a été compensée, les options de paiement et facturation se sont plus modifiables.',
    },
    editPharmacyList: {
      title: 'Modifier la liste',
      confirmationText:
        'Attention : les pharmacies ayant déjà démarré leur participation à l’opération ne peuvent pas être retirée du ciblage.<br/>Cette action est <strong>irréversible</strong>.<br/>Êtes-vous sûr de vouloir poursuivre ?',
    },
    disabledMessages: {
      date: "La date de début de l'opération est modifiable avant le début de l'opération. Une fois que l'opération a démarré, la date de début n'est plus modifiable. </br>La date de fin est modifiable avant la fin de l'opération. Une fois l'opération terminée, la date de fin n'est plus modifiable.",
      contract:
        "Le contrat de mandat est modifable tant qu'aucune inscription n'a eu lieu. Si au moins une inscription a été effectuée, le contrat de mandat n'est plus modifiable.",
    },
    errorMessages: {
      titleLength: 'Le titre ne peut pas dépasser 70 caractères',
    },
    cantDestroy:
      'Vous ne pouvez pas supprimer une opération sur laquelle des pharmaciens ont déjà remonté des preuves.',
    draftPublishDisabled: 'Ciblage des pharmacies manquant pour publication',
    groupTradeForm: {
      edit: "Édition de l'opération groupement",
      create: "Création de l'opération groupement",
      saveCreation: "Enregistrer l'opération groupement",
    },
    mainInfos: {
      updateMainInfos: 'Modifier les informations générales',
    },
    filters: {
      selectDateRange: 'Choisissez les dates de début et de fin des opérations',
    },
    meaBrief: 'Critères de validation de la preuve de Mise en Avant',
  },
  adminResourceUserPicker: {
    multiPlaceholder: '{{count}} utilisateur(s) sélectionné(s)',
  },
  adminMarketingTools: {
    title: 'Outil Marketing (Historique)',
    newMarketingCampaign: 'Nouvelle campagne marketing',
  },
  adminNewMarketingTool: {
    title: 'Nouvelle campagne Marketing',
    goBack: 'Historique des Campagnes Marketing',
    userIds: 'IDs utilisateurs (séparés par des virgules)',
    mode: 'Mode',
    modeOptions: {
      notification: 'Notification',
      sms: 'SMS (10 cts/user)',
    },
    toolTitle: 'Titre (uniquement pour les notifications)',
    body: 'Message',
    redirectUrl: 'Url (uniquement pour les notifications)',
    errors: {
      userIdsFormat: 'Format invalide',
    },
  },
  adminMarketingTool: {
    title: 'Marketing Tool {{id}}',
    info: 'Informations sur la campagne marketing',
    id: 'Id',
    body: 'Body',
    failedUsers: 'Utilisateurs avec erreurs',
    mode: 'Mode',
    redirectUrl: 'Url de redirection',
    userIds: 'Utilisateurs concernés en texte',
    toolUsers: 'Utilisateurs concernés individuellement :',
  },
  newMasterTrade: {
    title: 'Nouvelle opération commerciale',
    step1: {
      pageSubtitle: 'Etape 1 : Informations générales',
      stepperLabel: 'Infos',
    },
    step2: {
      pageSubtitle: 'Etape 2 : Conditions et compensation',
      stepperLabel: 'Conditions',
      compensationType: 'Type de compensation',
      ugSummary:
        'La compensation sélectionnée correspond à du <strong>{{ threshold }} + {{ quantity }} UG</strong>',
      ugSummaryPopover:
        'Attention : Faks n’est pas en mesure de s’assurer que la pharmacie a véritablement offert des produits gratuitement à des consommateurs. Cette mécanique s’appliquera au global sur les ventes totales de la pharmacie.',
      autoGenerateInvoice: 'Générer une facture automatiquement pour les paiements des pharmacies',
      errorMessages: {
        integer: 'Le nombre doit être un entier',
        percentageMin: 'Le pourcentage ne peut pas être inférieur à 0',
        percentageMax: 'Le pourcentage ne peut pas être supérieur à 100',
        amountMin: 'Le montant ne peut pas être inférieur à 0 euros',
        positive: 'Le montant doit être positif',
        mustBeNumber: 'Doit être un nombre',
      },
      activateStripePayment: 'Activer les paiements via Faks',
      paymentAndBillingType: 'Paiement et Facturation',
      validation: 'Validation',
      faksValidation: 'Activer la validation via Faks',
      faksValidationTooltip:
        "Faks validera ou refusera les participations de vos pharmacies en fonction des critères communiqués en amont de l'opération. En activant la validation via Faks, aucune validation ne pourra être effectuée par les délégués.",
      autorizeValidation: 'Autoriser la validation des remontées par les délégués',
      autorizeValidationTooltip:
        'Vos délégués auront l’option sur leur application mobile de pouvoir valider et refuser les remontées trades. Vous aurez toujours la possibilité de valider et refuser les remontées sur votre backoffice.',
      needsAdditionalValidation: 'Validation additionnelle [visible par admin Faks uniquement]',
      needsAdditionalValidationTooltip: 'Un opérateur Faks confirmera la validation HighcoData.',
      visibility: 'Visibilité',
      salesRepDecidesAccess: 'Permettre aux délégués de rendre l’opération visible par pharmacie',
      salesRepDecidesAccessTooltip:
        'La pharmacie ne verra pas l’opération sur son interface tant que son délégué ne l’a pas rendue visible.',
      meaBrief: 'Critères de validation de la preuve de Mise en Avant',
      meaBriefHelper:
        'Ce brief est uniquement visible par les équipes de validation de Faks, il n’est pas visible par les pharmacies. Veuillez compléter le champ “Description” pour afficher vos critères de validation aux pharmacies.',
    },
    step3: {
      pageSubtitle: 'Etape 3 : Ciblage',
      stepperLabel: 'Ciblage',
      chooseList: 'Sélectionnez une liste de pharmacies',
      adjustmentsNumber: 'Ajustements groupements',
      targeting: 'Ciblage',
    },
  },
  adminNewOrder: {
    title: 'Nouvelle commande',
    orderDetails: 'Détails de la commande',
    origin: "D'où vient la commande ?",
    errors: {
      pharmacy_must_have_a_user: 'La pharmacie doit avoir un titulaire pour créer une commande',
    },
  },
  adminLabsPicker: {
    multiPlaceholder: '{{count}} laboratoire(s) sélectionné(s)',
  },
  adminClaimRelaunchPlans: {
    title: 'Plans de relance',
    newClaimRelaunchPlan: 'Nouveau plan de relance',
  },
  adminNewClaimRelaunchPlan: {
    title: 'Titre',
    claimRelaunchPlanParameters: 'Paramètres du plan de relance',
    maximumNumberOfMails: 'Nombre de mails maximum',
    lastMessageByPharmacyTitle: 'Dernier message du pharmacien',
    lastMessageByLabTitle: 'Dernier message du laboratoire',
    lastMessageByHelper: 'Relance X jours ouvrés après le dernier message',
    addNew: 'Ajouter une relance',
    toBeTreatedBy: 'Jours ouvrés avant de passer dans à traiter ops depuis le dernier message',
  },
  adminEditClaimRelaunchPlan: {
    deletePlan: 'Supprimer le plan de relance',
    deletePlanDescription: 'Supprimer définitivement ce plan de relance ?',
    labsToBeAdded: 'Ids des laboratoires à ajouter :',
  },
  unsupportedBrowser:
    "Vous utilisez une version d'un navigateur qui n'est pas supporté. Certaines fonctionnalités pourraient ne pas fonctionner.",
  adminNewsfeedPosts: {
    title: 'Posts Groupements',
    newPost: 'Nouveau post',
    searchForPost: 'Trouver un post',
  },
  adminNewsfeedPost: {
    title: 'Post n°{{id}}',
    tabs: {
      details: 'Détails',
      views: 'Vues',
      likes: "J'aimes",
      clicks: 'Clics',
      comments: 'Commentaires',
    },
    details: {
      postInfo: 'Informations sur le post',
      content: 'Texte',
      likes: "Button j'aime ",
      comments: 'Commentaires',
      document: 'Document',
      openDocument: 'Ouvrir le document',
    },
  },
  pharmacy: {
    trades: {
      new: {
        labTradesInfo_one:
          '{{lab}} vous propose <strong>{{count}} opération</strong> non priorisée auxquelle vous ne participez pas encore.',
        labTradesInfo_other:
          '{{lab}} vous propose <strong>{{count}} opérations</strong> non priorisées auxquelles vous ne participez pas encore.',
        labTradesInfoAction: 'Voir les opérations {{lab}}',
      },
      pharmacyTrade: {
        boostConsentModal: {
          printWithBoost: 'Imprimer vos affiches via notre partenaire Boost',
          faksSendInfoToBoost:
            'Afin d’imprimer vos affiches, Faks souhaite transmettre à Boost vos informations suivantes :',
          boostNotAuthorizedCommercial:
            'Cela n’autorise pas Boost à vous contacter sous un motif commercial.',
          accept:
            'J’accepte que mes données ci-dessus soient transférées à Boost afin d’utiliser le module de génération d’affiches et étiquettes.',
          boostCgu: 'Voir les conditions générales d’utilisation de Boost.',
          continueToBoost: 'Continuer vers Boost',
          acceptConditions: 'Vous devez accepter les conditions',
        },
      },
      printPosterButtonWithConsent: {
        createPostersTooltip:
          'Vous pouvez créer vos propres affiches, par exemple pour vos opérations locales. Vous pouvez également sélectionner une ou plusieurs opérations pour créer des affiches avec les informations de ces opération.',
      },
      printPosterModal: {
        title: 'Chargement de vos opérations commerciales',
        loadingDescription:
          'Selon le nombre de produits le chargement peut prendre plusieurs secondes.',
        loadingSuccess: 'Chargement terminé',
        nextStep: 'Passer à l’étape suivante',
      },
      out_of_group_agreement: 'Hors accord groupement',
      requirements: {
        externalSource:
          "Le laboratoire nous indique qu'il a déjà récupéré vos justificatifs via un autre canal que Faks.",
      },
    },
  },
  commonErrors: {
    participation_already_exists_for_trade: 'Une participation existe déjà pour cette opération',
    pharmacy_can_have_one_trade_instance_max_per_master_trade:
      'Une participation existe déjà pour cette opération laboratoire',
    requirement_instance_already_exists: 'Une remontée a déjà été effectuée',
    invalid_status_to_compensate_outside_faks:
      'Le statut de la participation ne permet pas de la marquer comme compensée',
    group_trade_already_exist_on_master_trade:
      'Le groupement a déjà une opération existante reliée au trade laboratoire',
    trade_instance_already_validated_by_lab:
      "Certaines remontées d'opérations ont déjà été validées.",
    trade_instance_already_refused_by_lab: "La remontée d'opération a déjà été refusée.",
    trade_instance_is_not_editable: "La remontée d'opération ne peut pas être éditée.",
    trade_instance_already_awaiting_validation_by_lab:
      "La validation de cette remontée d'opération a déjà été annulée.",
    cip_already_taken: 'Le cip appartient à une autre pharmacie',
    cant_add_admin_user_to_pharmacy: 'Vous ne pouvez pas ajouter cet utilisateur à une pharmacie',
    pharmacy_cip_already_taken: 'Le CIP est déjà utilisé par une autre pharmacie',
    pharmacy_phone_number_already_taken:
      'Le numéro de téléphone est déjà utilisé par une autre pharmacie',
    pharmacy_invalid_zip_code: "Le format du code postal n'est pas valide",
    requirement_instance_must_have_picture: 'Vous devez rajouter un justificatif',
    invalid_phone_number:
      'Le numéro de téléphone est invalide. Veuillez vérifier le numéro ainsi que le pays sélectionné.',
    export_limited_to_ten_thousand_instances:
      "Vous avez essayé de télécharger {{number_of_requested_instances}} remontées d'opérations. La limite est de 10 000.",
    cant_create_other_than_platform_trade_on_premium_lab:
      '⛔️ Ce laboratoire gère son plan trade directement sur Faks.',
    user_with_same_email_or_phone_already_exist:
      'Un utilisateur avec cet email ou numéro de téléphone existe déjà',
    lab_should_have_at_least_two_active_expired_authorized_drugs:
      'Le laboratoire doit avoir au moins 2 produits actifs pour les remontées de périmés',
    trade_instances_with_updated_compensations:
      "{{validated_instances_count}} remontées ont été validées. {{instances_with_recalculated_compensations_count}} compensations ont été recalculées et n'ont pas pu être validées. Veuillez vérifier les données et revalider les remontées.",
    empty_file_not_allowed: 'Erreur de chargement: les fichiers vides ne sont pas acceptés',
    large_file_not_allowed:
      'Erreur de chargement: le fichier est trop volumineux (> {{maxSizeInMB}} MB)',
    file_content_type_not_allowed: 'Format de fichier non accepté',
    cant_delete_trade_with_master_trade:
      'Cette opération est gérée par le laboratoire, vous ne pouvez pas la supprimer. Veuillez contacter votre CSM à l’adresse csm-groupements@faks.co si vous souhaitez en savoir plus.',
    cant_delete_trade_with_requirement_instances:
      'Il y a déjà des participations avec remontées sur cette opération. Veuillez contacter votre CSM à l’adresse csm-groupements@faks.co si vous souhaitez supprimer cette opération.',
    requirement_end_date_exceeded: 'La date de fin de la remontée de la preuve est dépassée',
    cant_duplicate_trade_on_premium_lab:
      'Le laboratoire gère son plan trade directement sur Faks. Vous pouvez contacter le laboratoire ou l’équipe Faks pour modifier ou mettre en place une opération avec ce laboratoire.',
    email_doesnt_exist: 'Aucun compte n\'est lié à l\'adresse email : "{{email}}".',
    visibility_status_notifications_recipient_missing:
      'Réglages opérations: Le destinataire pour informer les opérations inclues ou exclues par un groupement doit être renseigné pour les laboratoires avec un plan trade premium',
    invalid_transition: 'Action impossible',
    lab_should_have_authorized_drugs_to_enable_expired_feature:
      "Vous devez d'abord ingérer les produits périmés repris du laboratoire avant de pouvoir activer la fonctionnalité périmés.",
    epr_already_assigned_on_the_pharmacy: 'La remontée est déjà assignée à cette pharmacie.',
    invalid_pdf_file: "Le fichier PDF n'est pas valide !",
    trade_already_has_participations:
      "Vous ne pouvez pas changer le mode de transmission d'une opération qui a déjà des participations.",
    cant_create_order_trade_without_product:
      'Une opération de type commande doit avoir au moins un produit !',
    cant_add_faks_admin_on_resource:
      'Vous ne pouvez pas ajouter un utilisateur admin Faks comme utilisateur principal.',
    lab_name_taken: 'Ce nom de laboratoire existe déjà',
    trade_title_too_long: "La longueur du nom de l'opération ne doit pas dépasser 70 caractères",
    trade_requirement_name_taken:
      "Les pièces justificatives d'une opération ne doivent pas avoir le même nom",
    trades_products_missing: 'Une opération de type commande doit avoir au moins un produit !',
    not_13_characters_in_ean: "L'EAN doit comporter 13 chiffres !",
    ean_already_in_trade: 'Un produit avec le même EAN existe déjà pour ce trade !',
    feature_not_available_on_current_environment:
      'Fonctionnalité non disponible sur cet environnement',
    phone_number_not_mobile: 'Veuillez utiliser un numéro de téléphone portable',
    user_not_sales_rep_on_this_lab:
      "Cet utilisateur n'appartient pas au laboratoire ou n'est pas délégué",
    invalid_postal_code_with_city:
      'Ce code postal n’est pas valide ou ne correspond pas à la ville renseignée.',
    country_not_supported_by_payment_partner:
      "Notre partenaire de paiement ne permet pas la création d'un compte avec un numéro de téléphone de ce pays.",
    invalid_external_link: 'Le lien externe n’est pas valide',
    only_one_requirement_when_faks_validation_enabled:
      "Il n'est pas possible d'activer la validation Faks avec cette configuration de justificatifs pour le moment",
    default: 'Une erreur est survenue',
    cant_find_pharmacies: "Une des pharmacies (ou les deux) n'existe plus.",
    already_in_another_group:
      'Cette pharmacie appartient déjà à un autre groupement. Pour l’ajouter à votre groupement, vous pouvez contacter la pharmacie pour lui proposer de quitter son groupement actuel sur Faks',
    already_in_group: 'La pharmacie est déjà dans votre groupement',
    trade_must_have_one_picture_requirement:
      "L'opération sélectionnée ne demande pas une unique preuve de type MEA.",
    cannot_verify_pharmacy_without_verfied_roles:
      'Vous ne pouvez pas vérifier une pharmacie sans rôle vérifié',
    requirements_compensation_types_not_aligned:
      'Les types de compensations des justificatifs ne sont pas alignés (tout UG ou tout €)',
  },
  exports: {
    exportWillBeSentByEmail:
      "Votre demande d’export sera envoyée à l'adresse email {{email}} dans les prochaines minutes.",
  },
  acceptCguPage: {
    acceptCgu:
      "En cochant cette case, j'accepte les conditions générales d'utilisation de FAKS accessibles",
    personalData: 'et je reconnais que mes données personnelles seront traitées conformément à la',
    privacyPolicy: 'politique de confidentialité',
    ofFaks: 'de FAKS',
    cgu: "Conditions générales d'utilisations",
  },
  pharmacyCompensations: {
    compensations: 'Compensations',
    addCompensation: 'Ajouter une compensation',
    followUp: 'Suivi des compensations',
    performances: 'Performances',
    howDoesItWorks: 'Comment ça marche ?',
    status: {
      pending: 'En attente',
      received: 'Reçue',
    },
    kind: {
      Invoice: 'Réclamation',
      TradesInstance: 'Opération',
      ExpiredProductsRequest: 'Périmé',
    },
    list: {
      headers: {
        compensation: 'Compensation',
        kind: 'Type',
        expectedDate: 'Date prévue',
        expectedDateInfo:
          "Date prévue de réception de la compensation.\nCette date est indicative et vous sert à savoir quand aller vérifier si la compensation a bien été reçue.\nN'hésitez pas à la changer!",
        amount: 'Montant',
        status: 'Status',
        action: 'Action',
        paidAt: 'Payé le',
        documents: 'Documents',
        paymentKind: 'Moyen',
      },
    },
    item: {
      daysLate: '{{count}}j de retard',
      vsAmount: 'vs {{amount}}',
      eprTitle: 'Périmé #{{id}}',
      claimTitle: 'Réclamation #{{id}}',
    },
    filters: {
      expectedDate: 'Date prévue',
      paidAt: 'Payé le',
    },
    newModal: {
      newCompensation: 'Nouvelle compensation',
      createCompensation: 'Créer une compensation',
      compensationCreated: 'La compensation a bien été créé',
    },
    editModal: {
      updateCompensations: 'Modifier la compensation',
      markAsReceived: 'Marquer comme reçue',
      save: 'Enregistrer',
      cancelEdit: "Abandonner l'édition de la compensation",
      closeConfirmation:
        "Êtes vous sure de vouloir abandonner l'édition de la compensation ? Toutes les modification seront perdues.",
      destroy: {
        success: 'La compensation a bien été supprimée',
        sureToDestroy: 'Êtes-vous sûr de vouloir supprimer cette compensation ?',
      },
      markAsPending: {
        success: 'La compensation a bien été rouverte',
        backToPending: 'Repasser en attente',
        areYouSure:
          'Êtes-vous sur de vouloir repasser cette compensation à ‘En attente’ ? La preuve de paiement et la compensation reçue seront perdues',
      },
    },
    forms: {
      label: {
        addFileOrLink: 'Ajoutez la preuve comptable ou le lien correspondant :',
        faksWontUse:
          "Soyez serein(e), Faks n'accedera pas à cette information. Elle est seulement pour vous pour garder une trace de votre compensation.",
        fileOrLink: 'Preuve comptable ou lien correspondant :',
      },
      placeholder: {
        title: 'Titre',
        expectedAmount: 'Montant attendu en euros',
        compensationValue: 'Valeur de la compensation (€)',
        expectedDate: 'Date attendue',
        externalLink: 'Lien externe',
        comment: 'Commentaire',
        receivedCompensation: 'Compensation reçue en euros',
        paidAt: 'Date de paiement',
        addProof: 'Ajouter une preuve comptable',
        paymentKind: 'Moyen',
        numberOfFreeUnits: "Nombre d'UG reçues",
        paymentProofLink: 'Lien vers la preuve comptable',
        manualTargetType: 'Type',
      },
    },
    markAsReceivedModal: {
      completeCompensation: 'Compléter la compensation reçue',
      successMarkAsReceived: 'La compensation a bien été marquée comme reçue',
      markAsReceived: 'Marquer comme reçue',
    },
    paymentKind: {
      credit_note: 'Avoir',
      bank_transfer: 'Virement bancaire',
      faks_transfer: 'Virement Faks',
      free_units: 'UG',
      gift_voucher: 'Chèque cadeau',
      other: 'Autre',
      ug: 'UG',
      refund: 'Remboursement',
      credit: 'Crédit',
      unknown: 'Non défini',
    },
    manualTargetType: {
      claim: 'Réclamation',
      trade: 'Opération',
      expired_products_request: 'Périmé',
      rfa: 'RFA',
      other: 'Autre',
    },
    statistics: {
      pending: 'En attente',
      paid: 'Payées',
      compensationsFor: '{{count}} compensations pour {{amount}}€ ({{ratePercent}}%)',
      receivedCompensations: '{{count}} compensations reçues',
      pendingCompensations: '{{count}} compensations en attente',
      perMonth: 'Par mois',
    },
  },
  selloutPanelistValidationBanner: {
    dataReceivedAtDate:
      'Ces données de vente ont été récupérées de votre extracteur le {{date}}. Veuillez les valider ou les modifier si elles sont incorrectes.',
    dataToValidate: 'Données de vente à valider',
    updatedAndValidatedBy:
      'Ces données de vente ont été modifiées et validées par {{userName}} le {{date}}',
    validatedBy:
      'Ces données de vente récupérées de votre extracteur le {{panelistDate}} ont été validées par {{userName}} le {{date}}',
    updatedAndValidated: 'Données de vente modifiées et validées',
    validated: 'Données de vente validées',
    cantEdit:
      'Vous ne pouvez pas modifier vos données de vente car les dates limites sont dépassées ou car vos preuves ont été validées.',
  },
  editSelloutItemsModal: {
    updateData: 'Modifier mes données de ventes',
    addFileWarning:
      'En modifiant vos données de ventes, le laboratoire a besoin d’un justificatif de vos données de ventes pour vérifier les données déclarées.',
    addFileLabel: 'Ajouter un justificatif de vos données de ventes',
    success: 'Les données de vente ont bien été modifiées et validées',
  },
  tradesInstance: {
    source: {
      faks: 'Faks',
      elsie_intranet: 'Elsie-Intranet',
      opeaz: 'Opeaz',
      apri_trade: 'ApriTrade',
      my_trade_leadersante: 'MyTrade-Leadersanté',
      stimdata: 'Stimdata',
      mediprix: 'Mediprix',
      totum_intranet: 'Intranet Totum',
      other: 'Autre',
    },
  },
  tradeForm: {
    requirements: {
      addJustification: 'Ajouter un justificatif',
      updateRequirement: 'Modifier un justificatif',
      addRequirement: 'Ajouter un justificatif',
      tableHead: {
        kind: 'TYPE',
        title: 'TITRE',
        limitDates: 'DATES LIMITES',
        compensationType: 'MECANIQUE',
        additionalInformation: 'INFORMATIONS ADDITIONNELLES',
      },
      fields: {
        promote: 'Mise en avant',
        sellout: 'Sellout',
        order: 'Commande',
        promoteDescription:
          'Pour les preuves de type “Mise en avant”, les pharmaciens doivent remonter des <underline>photos</underline>.',
        selloutDescription:
          'Pour les preuves de type “Sell-out”, les pharmaciens doivent remonter des <underline>données directement extraites de leur LGO</underline>, ou des <underline>fichiers au format PDF</underline>.',
        startDate: 'Date de début des remontées',
        endDate: 'Date de fin des remontées',
        datesWarning:
          'En renseignant des dates minimum ou maximum, les pharmaciens ne pourront pas ajouter des preuves en dehors de ces dates limites.',
        askManualSellout:
          'Sellout manuel demandé aux pharmaciens en plus du PDF (si le pharmacien refuse la remontée LGO)',
      },
      errors: {
        nameRequired: 'Le titre ne peut pas être vide',
        nameAlreadyExists: 'Le titre de ce justificatif existe déjà',
        startDateRequired: 'La date de début des remontées est obligatoire',
        endDateRequired: 'La date de fin des remontées est obligatoire',
      },
      tooltips: {
        alreadyAddedMea: 'Vous avez déjà ajouté une preuve de type MEA dans cette opération.',
        alreadyAddedSellout:
          'Vous avez déjà ajouté une preuve de type Sellout dans cette opération.',
        cantAddMoreThanTwoReq: 'Vous ne pouvez pas ajouter plus de 2 preuves dans une opération.',
      },
    },
  },
};

export default fr;
